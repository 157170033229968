import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Grid, Button, Typography } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import user_background from '../../images/user_background.png'

export default function NormalUserSuccessEntry(props) {

  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = (event) => {
    if (location.state.from === "form") {
      let formProps = location.state.formProps
      navigate(`/form/${props.belong}/${formProps.formProject}/${formProps.formTitleID}/${formProps.readOnly}`, {replace: true})
    } else {
      navigate(`/menu/${props.belong}`, {replace: true})
    }
  }

  return (
    <div className="success-entry" align="center">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="column" rowSpacing="3%" wrap="nowrap">
          <Grid item>
            <div align="center">
              <Box component="img" sx={{width: "100%"}} alt="user_background" src={user_background}/>
            </div>
          </Grid>
          <Grid item>
            <Box>
              <div align="center">
                <Typography component="div" sx={{color: "#5AA7A7", fontWeight: "bold", fontSize: "40px"}}>Welcome</Typography>
                <Typography variant="h7" component="div">通過身份驗證，綁定成功！</Typography>
                <Button className="success-button" variant="outlined" onClick={handleClick} sx={{marginTop: "130px"}}><span style={{fontSize: 15, fontWeight: "bold", marginTop: "1px"}}>START</span><ArrowForward sx={{ fontSize: 22, fontWeight: "bold", marginLeft: "5px"}} /></Button>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}