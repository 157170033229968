import './GeneralDashboard.css'
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { TextField, Autocomplete, Chip, Button, createFilterOptions } from '@mui/material'
import { styled } from '@mui/material/styles'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { AuthContext } from '../../AppRoutes'
import { DashboardContext } from '../../routes/Reports'
import { ReportContext } from './ReportDashboard'
import { inspectGroupItemDict, inspectItemGroupDict, inspectItemsFullDict, inspectItemsReverseDict, categoryCnDict, columnsDictLatest, columnsDict2019 } from '../common/RelatedVariables'
import * as GeneralDashboardAPI from '../../api/GeneralDashboardAPI'

const CustomizedTextField = styled(TextField)({
  '& label': {
    color: '#5AA7A7',
  },
  '& label.Mui-focused': {
    color: '#5AA7A7',
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid #5AA7A7',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid #5AA7A7',
  }
})

export default function ReportFilter(props) {

  const authContext = useContext(AuthContext)
  const dashboardContext = useContext(DashboardContext)
  const reportContext = useContext(ReportContext)

  const [selectOptions, setSelectOptions] = useState(props.label === "檢查項目" ? [props.items[0]] : props.label === "異常細項" ? [] : props.items)

  const fixedOptions = (props.label === "檢查項目") ? [props.items[0]] : []

  const navigate = useNavigate()

  useEffect(() => {

    let initDataGridColumns = [{field: "action", headerName: "動作", sortable: false, filterable: false, width: "180", renderCell: (params) => {return <div><Button className="action-button" onClick={handleActionClick(params, "message")} disabled={authContext.role === "doctor"}>發送訊息</Button>|<Button className="action-button" onClick={handleActionClick(params, "report")}>查看報告</Button></div>}}]
    let initDataGridColumnGroup = [{groupId: "employee-info", headerName: "個人資料", headerClassName: "column-group", children: [{field: "inspect_date"}, {field: "employee_number"}, {field: "username"}, {field: "gender"}, {field: "company"}, {field: "department"}, {field: "unit"}, {field: "position"}]}]

    Object.keys(columnsDictLatest["patient"]).forEach(item => {
      let tmp_dict = {}
      tmp_dict["field"] = columnsDictLatest["patient"][item]
      tmp_dict["headerName"] = item

      if (item !== "工號" && item !== "姓名" && item !== "廠別") {
        tmp_dict["filterable"] = false
      } else {
        tmp_dict["filterOperators"] = getGridStringOperators().filter((operator) => operator.value === 'isAnyOf')
      }

      initDataGridColumns.push(tmp_dict)
    })

    if (reportContext.selectedInspectGroups.length === 1 && reportContext.selectedInspectGroups[0] === "patient") {
      reportContext.setDataGridColumns([])
      reportContext.setDataGridColumns((preDataGridColumns) => [...preDataGridColumns, ...initDataGridColumns])

      reportContext.setDataGridColumnGroup([])
      reportContext.setDataGridColumnGroup((preDataGridColumnGroup) => [...preDataGridColumnGroup, ...initDataGridColumnGroup])
    }

    if (props.label === "部門") {
      setSelectOptions(props.items)
      reportContext.setSelectedDepartments([])
      reportContext.setSelectedDepartments((preSelectedDepartments) => [...preSelectedDepartments, ...props.items])
    }

  }, [props.items])

  useEffect(() => {
    if (props.label === "異常細項") {
      let selected_abnormal_items = reportContext.selectedAbnormalItems.filter((selectedAbnormalItem) => reportContext.selectedInspectGroups.includes(categoryCnDict[inspectItemGroupDict[selectedAbnormalItem]]))
      reportContext.setSelectedAbnormalItems([])
      reportContext.setSelectedAbnormalItems((prevSelectedAbnormalItems) => [...prevSelectedAbnormalItems, ...selected_abnormal_items])

      setSelectOptions([...new Set(selected_abnormal_items.map(function(selected_abnormal_item) {return inspectItemsFullDict[selected_abnormal_item]}))])
    }
  }, [reportContext.selectedInspectGroups])

  const handleOnChange = (event, values) => {
    if (props.label === "部門") {

      if (values.includes("全選")) {
        values = [...new Set(props.items)]
      }

      setSelectOptions(values)

      reportContext.setSelectedDepartments([])
      reportContext.setSelectedDepartments((prevSelectedDepartments) => [...prevSelectedDepartments, ...values])

    } else if (props.label === '檢查項目') {

      if (values.includes("全選")) {
        values = [...new Set(props.items)]
      }

      setSelectOptions(values)

      if (!values.includes("個人資料")) {
        values = [...["個人資料"], ...values]
        setSelectOptions(values)
      }

      let inspect_groups = []
      let inspect_items = []

      values.forEach(value => inspect_groups.push(categoryCnDict[value]))

      inspect_groups.forEach(function(inspect_group) {
        if (inspect_group !== "patient") {
          inspect_items.push(...inspectGroupItemDict[inspect_group])
        }
      })

      reportContext.setSelectedInspectGroups([])
      reportContext.setSelectedInspectGroups((prevSelectedInspectGroups) => [...prevSelectedInspectGroups, ...inspect_groups])

      reportContext.setInspectItemOptions([])
      reportContext.setInspectItemOptions((prevInspectItemOptions) => [...prevInspectItemOptions, ...inspect_items.map(function(inspect_item) {return inspectItemsFullDict[inspect_item]})])

      let initDataGridColumns = [{field: "action", headerName: "動作", sortable: false, filterable: false, width: "180", renderCell: (params) => {return <div><Button className="action-button" onClick={handleActionClick(params, "message")} disabled={authContext.role === "doctor"}>發送訊息</Button>|<Button className="action-button" onClick={handleActionClick(params, "report")}>查看報告</Button></div>}}]
      let initDataGridColumnGroup = []
      let columnsDict = dashboardContext.year === "2019" ? columnsDict2019 : columnsDictLatest
      
      values.forEach(value => {

        let tmp_group_dict = {}
        tmp_group_dict["groupId"] = categoryCnDict[value]
        tmp_group_dict["headerName"] = value
        tmp_group_dict["headerClassName"] = "column-group"
        tmp_group_dict["children"] = []

        Object.keys(columnsDict[categoryCnDict[value]]).forEach(item => {
          let tmp_dict = {}
          tmp_dict["field"] = columnsDict[categoryCnDict[value]][item]
          tmp_dict["headerName"] = item

          if (item !== "工號" && item !== "姓名" && item !== "廠別") {
            tmp_dict["filterable"] = false
          } else {
            tmp_dict["filterOperators"] = getGridStringOperators().filter((operator) => operator.value === 'isAnyOf')
          }

          if (value === "一般檢查") {
            tmp_dict["width"] = "120"
          } else if (value === "眼科及耳鼻喉科檢查") {
            tmp_dict["width"] = "120"
          } else if (value !== "個人資料") {
            tmp_dict["width"] = "200"
          }

          initDataGridColumns.push(tmp_dict)

          tmp_group_dict["children"].push({field: columnsDict[categoryCnDict[value]][item]})
        })
        initDataGridColumnGroup.push(tmp_group_dict)
      })

      reportContext.setDataGridColumns([])
      reportContext.setDataGridColumns((preDataGridColumns) => [...preDataGridColumns, ...initDataGridColumns])

      reportContext.setDataGridColumnGroup([])
      reportContext.setDataGridColumnGroup((preDataGridColumnGroup) => [...preDataGridColumnGroup, ...initDataGridColumnGroup])
    } else {

      if (values.includes("全選")) {

        let abnormal_items = []

        reportContext.selectedInspectGroups.forEach(function(inspect_group) {
          if (inspect_group !== "patient") {
            abnormal_items.push(...inspectGroupItemDict[inspect_group])
          }
        })

        values = [...new Set(abnormal_items.map(function(abnormal_item) {return inspectItemsFullDict[abnormal_item]}))]
      }

      setSelectOptions(values)

      reportContext.setSelectedAbnormalItems([])
      reportContext.setSelectedAbnormalItems((prevSelectedAbnormalItems) => [...prevSelectedAbnormalItems, ...values.map(function(value) {return inspectItemsReverseDict[value]})])
      
    }
  }

  const handleActionClick = (params, action) => async (event) => {
    try {
      var response = await GeneralDashboardAPI.getUserInfo(authContext.userName, "report", action, btoa(params.row.id_number), params.row.email)
      if (action === "message") {

        sessionStorage.setItem('external_id', atob(response.external_id))
        dashboardContext.setMessageType("independent")
        dashboardContext.setMessageText("")
        dashboardContext.setUserInfo({})
        dashboardContext.setUserInfo((prevUserInfo) => ({...prevUserInfo, ...{username: params.row.username, email: params.row.email}}))

      } else {

        sessionStorage.setItem('inspect_date', params.row.inspect_date)
        sessionStorage.removeItem('external_id')
        dashboardContext.setIsUserAbnormalSwitch(false)
        dashboardContext.setUserInfo({})
        dashboardContext.setUserInfo((prevUserInfo) => ({...prevUserInfo, ...response.patient_info}))

      }

      dashboardContext.setAction(action)
      dashboardContext.setOpenModal(true)

    } catch (error) {
      if (error.response.status === 401) {
        navigate("/login", { replace: true})
      } else if (error.response.status === 400) {
        alert("此用戶無提供身分證字號，\n請重新操作一次，或請聯絡客服人員")
      } else if (error.response.status === 404) {
        alert("此人員尚未綁定LINE OA，且無提供E-Mail")
      } else {
        alert("執行此動作失敗，\n請重新操作一次，或請聯絡客服人員")
      }
    }
  }

  const filter = createFilterOptions()

  return (
    <div align="center">
      <Autocomplete
        sx={{ width: "90%" }}
        multiple
        limitTags={6}
        id="reports-filter"
        options={props.label === "異常細項" ? reportContext.inspectItemOptions : [...new Set(props.items)]}
        getOptionLabel={(option) => option}
        value={selectOptions}
        renderTags={(tagValue, getTagProps) => {
          if (tagValue.includes("全選")) {
            tagValue = [...new Set(props.items)]
          }
          return tagValue.map((option, index) => (
            <Chip
              label={option}
              {...getTagProps({ index })}
              disabled={fixedOptions.indexOf(option) !== -1}
              sx={{backgroundColor: "#A7D676"}}
            />
          ))
        }}
        renderInput={(params) => (
          <CustomizedTextField
            {...params}
            variant="standard"
            label={props.label}
            placeholder={"選擇欲檢視"+props.label}
          />
        )}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return [...["全選"], ...filtered];
        }}
        onChange={handleOnChange}
        disabled={reportContext.isFilterReadOnly}
      />
    </div>
  );
}
