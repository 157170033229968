import axios from 'axios'
import * as AccountAPI from './AccountAPI'

const axiosInstance = axios.create({
  baseURL: 'https://askin-corp-api.dermai.com.tw/api/forms/',
  headers: {
    Accept: 'application/json'
  }
})

axiosInstance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${AccountAPI.getExternalID()}`
  return config
})

export const getFormTitle = (belong, titleID) => {
  return axiosInstance.get(`title/?belong=${belong}&titleID=${titleID}`).then(res => res.data)
}

export const getForm = (isDashboard, belong, project, titleID, readOnly) => {
  return axiosInstance.get(`?isDashboard=${isDashboard}&belong=${belong}&project=${project}&titleID=${titleID}&read=${readOnly}`).then(res => res.data)
}

export const getAnswer = (belong, project, titleID) => {
  return axiosInstance.get(`answer/?belong=${belong}&project=${project}&titleID=${titleID}`).then(res => res.data)
}

export const submitAnswers = (body) => {
  return axiosInstance.post("answer/", body).then(res => res.data)
}