import "./App.css"
import { useLocation } from 'react-router-dom'
import Reports from "./routes/Reports"
import NationalForms from "./routes/NationalForms"

function App() {
  const location = useLocation()
  let target = location.state.target

  return (
    <div>
      { target === "reports" ?
        <Reports /> :
        target === "forms" ?
        <NationalForms /> :
        <Reports />
      }
    </div>
  )
}

export default App;
