import axios from 'axios'
import * as AccountAPI from './AccountAPI'

const axiosInstance = axios.create({
  baseURL: 'https://askin-corp-api.dermai.com.tw/api/dashboard/message/',
  headers: {
    Accept: 'application/json'
  }
})

axiosInstance.interceptors.request.use(config => {
  config.headers.Authorization = `JWT ${AccountAPI.getAccessToken()}`
  return config
})

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config

    if (error.response.status === 401 && originalRequest.url === 'http://127.0.0.1:8000/api/accounts/token/refresh') {
      return Promise.reject(error);
    }

    if (error.response.data.code === "token_not_valid" && error.response.status === 401 && error.response.statusText === "Unauthorized") {
      const refresh_token = AccountAPI.getRefreshToken()

      if (refresh_token){
        const tokenParts = JSON.parse(atob(refresh_token.split('.')[1]))
        const now = Math.ceil(Date.now() / 1000)

        if (tokenParts.exp > now) {
          let tokens = await AccountAPI.refreshToken({refresh: refresh_token})

          sessionStorage.setItem('access_token', tokens.access)
          sessionStorage.setItem('refresh_token', tokens.refresh)

          return axiosInstance(originalRequest)

        } else {
            console.log("Refresh Token Is Expired", tokenParts.exp, now);
          }
      } else {
          console.log("Refresh Token Not Available")
        }
    }

    return Promise.reject(error)
  }
)

export const getMessageTemplate = (name, belong, is_retrieve) => {
  return axiosInstance.get(`templates/?name=${name}&belong=${belong}&is_retrieve=${is_retrieve}`).then(res => res.data)
}

export const addMessageTemplate = (body) => {
  return axiosInstance.post("templates/", body).then(res => res.data)
}

export const updateMessageTemplate = (body) => {
  return axiosInstance.put("templates/", body).then(res => res.data)
}

export const deleteMessageTemplate = (name, belong) => {
  return axiosInstance.delete("templates/", {data: {name: name, belong: belong}}).then(res => res.data)
}

export const sendMessage = (body) => {
  return axiosInstance.post("dispatch/", body).then(res => res.data)
}