import { useState, useEffect } from "react"
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Grid, TextField, Button, Typography, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ArrowForward } from '@mui/icons-material'
import user_background from '../../images/user_background.png'
import * as AccountAPI from '../../api/AccountAPI'

const CustomizedTextField = styled(TextField)({
  '& label': {
    color: '#5AA7A7',
  },
  '& label.Mui-focused': {
    color: '#5AA7A7',
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid #5AA7A7',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid #5AA7A7',
  }
})

export default function NormalUserVerification(props) {

  const [isLoading, setIsLoading] = useState(false)
  const [userInfo, setUserInfo] = useState({idNumber: "", userName: ""})

  const location = useLocation()

  const navigate = useNavigate()

  useEffect(() => {

    async function checkBinded(belong) {

      try {
        await AccountAPI.checkUserBinded(belong, "verification")
        alert("用戶已綁定")
        window.close()
      } catch (error) {
        if (error.response.status === 401) {
          return
        } else if (error.response.status === 404) {
          alert("用戶不存在，\n請重新操作一次，或請聯絡客服人員")
          return
        } else {
          alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
          return
        }
      }
    }

    checkBinded(props.belong)

  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      let response = await AccountAPI.verifyUser(props.belong, userInfo)
      let email = response.email
      navigate("/bind", {state: {belong: props.belong, from: location.state ? location.state.from : "menu", formProps: location.state ? location.state.formProps : {}, userInfo: userInfo, email: email, bindWay: "mail"}, replace: true})

    } catch (error) {
      if (error.response.status === 404) {
        let message = error.response.data.message

        if (message === "User Not Exist !"){
          alert("用戶資料不存在，\n請重新加入LINE好友，或請聯絡客服人員")
        } else if (message === "E-mail Not Exist !") {
          navigate("/bind", {state: {belong: props.belong, from: location.state ? location.state.from : "menu", formProps: location.state ? location.state.formProps : {}, userInfo: userInfo, bindWay: "mobile"}, replace: true})
        } 
        else {
          alert("用戶資料不存在，\n請檢查輸入資料是否正確")
        }
      } else if (error.response.status === 409) {
        alert("此用戶已被綁定，\n請重新輸入資料，或請聯絡客服人員")
      }
      else {
        alert("發送驗證信失敗，\n請重新綁定，或請聯絡客服人員")
      }
    }

    setIsLoading(false)

  }

  return (
    <div className="verification-form" align="align">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="column" rowSpacing="3%" wrap="nowrap">
          <Grid item>
            <div align="center">
              <Box component="img" sx={{width: "100%"}} alt="user_background" src={user_background}/>
            </div>
          </Grid>
          <Grid item>
            <Box component="form" onSubmit={handleSubmit}>
              <div align="center">
                <Typography component="div" align="left" sx={{color: "#5AA7A7", fontWeight: "bold", fontSize: "40px",marginLeft: "12%"}}>Sign Up</Typography>
                  <CustomizedTextField className="verification-input" label="身分證字號" variant="standard" margin="normal" sx={{width: "75%"}} onChange={(event) => setUserInfo({...userInfo, idNumber: event.target.value})} required />
                  <br/>
                  <CustomizedTextField label="姓名" variant="standard" margin="normal" sx={{width: "75%"}} onChange={(event) => setUserInfo({...userInfo, userName: event.target.value})} required />
              </div>
              <br/><br/>
              { isLoading ? <div align="center"><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7"}}>進入驗證頁面中...</span></div>
                :
                <div align="center">
                  <Button className="verification-button" type="submit" variant="outlined" disabled={userInfo["idNumber"].length === 0 || userInfo["userName"].length === 0}><span style={{fontSize: 15, fontWeight: "bold", marginTop: "1px"}}>SIGN UP</span><ArrowForward sx={{ fontSize: 22, fontWeight: "bold", marginLeft: "5px"}} /></Button>
                </div>
              }
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}