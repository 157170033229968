import './GeneralDashboard.css'
import { useState, useEffect, useLayoutEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { TextField, Autocomplete, Chip, createFilterOptions } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AuthContext } from '../../AppRoutes'
import { DashboardContext } from '../../routes/NationalForms'
import { FormContext } from './FormDashboard';
import * as FormDashboardAPI from '../../api/FormDashboardAPI'

const CustomizedTextField = styled(TextField)({
  '& label': {
    color: '#5AA7A7',
  },
  '& label.Mui-focused': {
    color: '#5AA7A7',
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid #5AA7A7',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid #5AA7A7',
  }
})

export default function FormFilter(props) {

  const authContext = useContext(AuthContext)
  const dashboardContext = useContext(DashboardContext)
  const formContext = useContext(FormContext)

  const [selectOptions, setSelectOptions] = useState(props.label === "部門" ? formContext.selectedDepartments : props.items)

  const fixedOptions = []

  const navigate = useNavigate()

  useLayoutEffect(() => {

    if (props.label === "公司") {
      setSelectOptions(props.items)
      formContext.setSelectedCompanies([])
      formContext.setSelectedCompanies((preSelectedCompanies) => [...preSelectedCompanies, ...props.items])
    }

  }, [props.items])

  useEffect(() => {

    async function getDepartments() {
      try {
        var departmentList = await FormDashboardAPI.getDepartment(authContext.userName, dashboardContext.project, formContext.selectedCompanies)

      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login", { replace: true})
        } else {
          alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
        }
      }

      setSelectOptions(departmentList)
      formContext.setDepartmentOptions([])
      formContext.setDepartmentOptions((prevDepartmentOptions) => [...prevDepartmentOptions, ...departmentList])
      formContext.setSelectedDepartments([])
      formContext.setSelectedDepartments((prevSelectedDepartments) => [...prevSelectedDepartments, ...departmentList])
    }

    if (props.label === "部門") {
      if (formContext.selectedCompanies.length === 0) {
        setSelectOptions([])
        formContext.setDepartmentOptions([])
        formContext.setSelectedDepartments([])
      } else {
        getDepartments()
      }
    }

  }, [formContext.selectedCompanies])

  const handleOnChange = (event, values) => {
    if (props.label === "公司") {

      if (values.includes("全選")) {
        values = [...new Set(props.items)]
      }

      setSelectOptions(values)

      formContext.setSelectedCompanies([])
      formContext.setSelectedCompanies((prevSelectedCompanies) => [...prevSelectedCompanies, ...values])

    } else if (props.label === "部門") {

      if (values.includes("全選")) {
        values = formContext.departmentOptions
      }

      setSelectOptions(values)

      formContext.setSelectedDepartments([])
      formContext.setSelectedDepartments((prevSelectedDepartments) => [...prevSelectedDepartments, ...values])
    }
  }

  const filter = createFilterOptions()

  return (
    <div align="center">
      <Autocomplete
        sx={{ width: "90%" }}
        multiple
        limitTags={6}
        id="forms-filter"
        options={props.label === "部門" ? formContext.departmentOptions : [...new Set(props.items)]}
        getOptionLabel={(option) => option}
        value={selectOptions}
        renderTags={(tagValue, getTagProps) => {
          if (tagValue.includes("全選")) {
            tagValue = [...new Set(props.items)]
          }
          return tagValue.map((option, index) => (
            <Chip
              label={option}
              {...getTagProps({ index })}
              disabled={fixedOptions.indexOf(option) !== -1}
              sx={{backgroundColor: "#A7D676"}}
            />
          ))
        }}
        renderInput={(params) => (
          <CustomizedTextField
            {...params}
            variant="standard"
            label={props.label}
            placeholder={"選擇欲檢視"+props.label}
          />
        )}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return [...["全選"], ...filtered];
        }}
        onChange={handleOnChange}
        disabled={formContext.isFilterReadOnly}
      />
    </div>
  );
}
