import { useState } from "react"
import { useNavigate } from 'react-router-dom'
import { Box, Grid, TextField, Button, Typography, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ArrowForward } from '@mui/icons-material'
import user_background from '../../images/user_background.png'
import * as AccountAPI from '../../api/AccountAPI'

const CustomizedTextField = styled(TextField)({
  '& label': {
    color: '#5AA7A7',
  },
  '& label.Mui-focused': {
    color: '#5AA7A7',
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid #5AA7A7',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid #5AA7A7',
  }
})

export default function NormalUserMailBind(props) {

  const [isLoading, setIsLoading] = useState(false)
  const [loadingMsg, setLoadingMsg] = useState("")
  const [bindInfo, setBindInfo] = useState({belong: props.belong, idNumber: props.userInfo.idNumber, verificationCode: ""})

  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoadingMsg("驗證中...")
    setIsLoading(true)

    try {
      await AccountAPI.bindUser(bindInfo)
      navigate(`/success/${props.belong}`, {state: {from: props.from, formProps: props.formProps}, replace: true})

    } catch (error) {
      if (error.response.status === 400) {
        alert("驗證失敗，\n請檢查驗證碼是否正確")
      } else {
        alert("驗證失敗，\n請檢查驗證碼是否正確，或請聯絡客服人員")
      }
    }

    setIsLoading(false)

  }

  const handleReSendClick = async (event) => {

    setLoadingMsg("發送中...")
    setIsLoading(true)

    try {
      await AccountAPI.verifyUser(props.belong, props.userInfo)
      alert(`已重新寄送驗證碼至\n${props.email}`)

    } catch (error) {
      if (error.response.status === 400) {
        let message = error.response.data.message

        if (message === "User Not Exist !"){
          alert("用戶資料不存在，\n請重新加入LINE好友，或請聯絡客服人員")
        } else {
          alert("用戶資料不存在，\n請檢查輸入資料是否正確")
        }
      } else {
        alert("發送驗證信失敗，\n請重新綁定，或請聯絡客服人員")
      }
    }

    setIsLoading(false)

  }

  const handleChangeWayClick = (event) => {
    navigate("/bind", {state: {belong: props.belong, from: props.from, formProps: props.formProps, userInfo: props.userInfo, bindWay: "mobile"}, replace: true})
  }

  return (
    <div className="bind-form" align="center">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="column" rowSpacing="3%" wrap="nowrap">
          <Grid item>
            <div align="center">
              <Box component="img" sx={{width: "100%"}} alt="user_background" src={user_background}/>
            </div>
          </Grid>
          <Grid item>
            <Box component="form" onSubmit={handleSubmit}>
              <div align="center">
                <Typography component="div" align="left" sx={{color: "#5AA7A7", fontWeight: "bold", fontSize: "40px", marginLeft: "12%"}}>Verify</Typography>
                <Typography variant="h7" component="div">已寄送6位數驗證碼至 {props.email}</Typography>
                <CustomizedTextField label="驗證碼" variant="standard" margin="normal" sx={{width: "75%"}} onChange={(event) => setBindInfo({...bindInfo, verificationCode: event.target.value})} required />
                <Button className="retry-button" onClick={handleReSendClick} sx={{marginLeft: "50%"}}>重新發送驗證碼</Button>
              </div>
              <br/><br/>
              { isLoading ? <div align="center"><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7"}}>{loadingMsg}</span></div>
                :
                <div align="center">
                  <Button className="bind-button" type="submit" variant="outlined" disabled={bindInfo["idNumber"].length === 0 || bindInfo["verificationCode"].length === 0}><span style={{fontSize: 15, fontWeight: "bold", marginTop: "1px"}}>VERIFY</span><ArrowForward sx={{ fontSize: 22, fontWeight: "bold", marginLeft: "5px"}} /></Button>
                  <Button onClick={handleChangeWayClick} sx={{marginTop: "10px", color: "#5AA7A7"}}>使用手機號碼進行綁定</Button>
                </div>
              }
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}