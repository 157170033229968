import { createContext, useState, useContext } from "react"
import { useParams } from 'react-router-dom'
import { Container } from '@mui/material'
import { LiffContext } from './LiffUsage'
import Flag from "../components/common/Flag"
import NormalUserReport from "../components/employee/NormalUserReport"

export const UserReportContext = createContext()

const UserReportProvider = ({children}) => {
  let [isAbnormalSwitch, setIsAbnormalSwitch] = useState(false)

  let value = { isAbnormalSwitch, setIsAbnormalSwitch }

  return (
    <UserReportContext.Provider value={value}>{children}</UserReportContext.Provider>
  )
}

export default function UserReport() {

  const urlParams = useParams()
  const liffContext = useContext(LiffContext)

  return (
    <Container maxWidth="100%" disableGutters>
      { liffContext.isLogin ? 
        <UserReportProvider><NormalUserReport belong={urlParams.belong} isDashboard={false} /></UserReportProvider> :
        <div align="center"><Flag message="載入失敗，請勿直接參訪此頁面" /></div>
      }
    </Container>
  )
}