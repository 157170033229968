import { useState, useEffect, useContext } from "react"
import { useNavigate } from 'react-router-dom'
import { Box, Stack, Dialog, Button, Typography, CircularProgress, Divider} from '@mui/material'
import { styled } from '@mui/material/styles'
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro'
import { AuthContext } from '../../AppRoutes'
import { DashboardContext } from '../../routes/NationalForms'
import { zHTWLocale } from '../common/RelatedVariables'
import NormalUserNationalForm from "../employee/NormalUserNationalForm"
import * as FormDashboardAPI from '../../api/FormDashboardAPI'

const CustomizedDataGrid = styled(DataGridPro)({
  '&.MuiDataGrid-root': {
    border: 'none'
  }, 
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    outline: 'none'
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
    outline: 'none'
  },
  '.MuiDataGrid-footerContainer': {
    border: 'none'
  }
})

const modalStyle = {
    bgcolor: "#FFFFFF",
    borderRadius: "0.5rem",
  }

function ExportToolbar() {
  return (
    <div align="right">
      <GridToolbarContainer>
        <GridToolbarExport sx={{color: "#5AA7A7"}} csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    </div>
  )
}

export default function FormRecordTable(props) {

  const authContext = useContext(AuthContext)
  const dashboardContext = useContext(DashboardContext)

  const [isLoading, setIsLoading] = useState(false)
  const [records, setRecords] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [project, setProject] = useState("")
  const [formID, setFormID] = useState("")

  const navigate = useNavigate()

  useEffect(() => {

    async function getRecords() {
      try {
        var recordResponse = await FormDashboardAPI.getRecord(authContext.userName)
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login", { replace: true})
        } else {
          alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
          setIsLoading(false)
        }
      }

      setRecords([])
      setRecords((prevRecords) => [...prevRecords, ...recordResponse])
      setIsLoading(false)
    }

    setIsLoading(true)
    getRecords()

  }, [])

  const handleDetailClick = (params) => async (event) => {
    setProject(params.row.project)
    setFormID(params.row.form_id)
    setOpenModal(true)
  }

  const columns = [{field: "project", headerName: "專案", filterable: false, width: "150"},
                   {field: "form", headerName: "問卷", filterable: false, width: "350"},
                   {field: "detail", headerName: "填寫內容", filterable: false, width: "120", renderCell: (params) => {return <Button className="action-button" onClick={handleDetailClick(params)}>查看</Button>}},
                   {field: "fill_date", headerName: "填寫日期", filterable: false, width: "120"},
                   {field: "is_finish", headerName: "案件狀態", filterable: false, width: "120"},
                   {field: "finish_date", headerName: "結案日期", filterable: false, width: "120"}]

  return (
    <Box>
      <Typography variant="h5" sx={{fontWeight: "bold", color: "#474747", marginLeft: "2%"}}>歷史填寫紀錄</Typography>
      <Divider variant="middle" />
      <br/>
      <Box sx={{ width: "95%", marginLeft: "2.5%", boxShadow: 1, borderRadius: "10px"}}>
        <Stack spacing={1} sx={{fontWeight: "bold", color: "#474747", marginLeft: "2%", paddingTop: "10px", paddingBottom: "10px"}}>
          <span>姓名 : {dashboardContext.userInfo["name"]}</span>
          <span>工號 : {dashboardContext.userInfo["employeeNumber"]}</span>
          <span>公司 : {dashboardContext.userInfo["company"]}</span>
          <span>部門 : {dashboardContext.userInfo["department"]}</span>
        </Stack>
      </Box>
      <br/>
      <Divider variant="middle" />
      { isLoading ?
        <div align="center">
          <br/>
          <CircularProgress className="loading-spinner"/>
          <br/><span style={{color: "#5AA7A7"}}>載入中...</span>
        </div> :
        records.length === 0 ? 
        <Typography component="div" align="center" sx={{fontWeight: "bold", color: "#474747"}}>無</Typography>
        :
        <div align="center">
          <Box sx={{ height: "400px", width: "95%", marginTop: "3%", boxShadow: 1, borderRadius: "10px"}}>
            <CustomizedDataGrid
              columns={columns}
              rows={records}
              components={{
                Toolbar: ExportToolbar,
              }}
              localeText={zHTWLocale}
              disableSelectionOnClick
            />
            <Dialog 
              open={openModal} 
              onClose={() => {setOpenModal(false)}} 
              fullWidth 
              maxWidth="md" 
              componentsProps={{backdrop: {style: {backgroundColor: "rgba(0, 0, 0, 0.2)"}}}}
            >
              <Box sx={modalStyle}>
                <Button className="close-button" sx={{fontSize: "20px", marginLeft: "90%"}} onClick={() => {setOpenModal(false)}}>X</Button>
                <NormalUserNationalForm isDashboard={true} belong={authContext.userName} formProject={project} formTitleID={formID} readOnly={true} />
              </Box> 
            </Dialog>
          </Box>
        </div>
      }
    </Box>
  )
}