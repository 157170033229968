import { useEffect, useContext } from "react"
import { useParams } from 'react-router-dom'
import { Container, CircularProgress } from '@mui/material'
import { LiffContext } from './LiffUsage'
import NormalUserMenu from "../components/employee/NormalUserMenu"

export default function UserMenu() {

  const urlParams = useParams()
  const liffContext = useContext(LiffContext)

  useEffect(() => {

    if (liffContext.isLogin) {
      return
    } else {
      liffContext.initLIFF(process.env[`REACT_APP_${urlParams.belong.toUpperCase()}_LIFF_MENU`])
    }

  }, [])

  return (
    <Container maxWidth="100%" disableGutters>
      { liffContext.isLogin && liffContext.isFriend ? 
        <NormalUserMenu belong={urlParams.belong} userName={liffContext.userName}/> :
        <div align="center"><br/><CircularProgress className="loading-spinner" /></div>
      }
    </Container>
  )
}