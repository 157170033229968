import { useState, useEffect } from "react"
import { Modal, Box, Accordion, AccordionSummary, AccordionDetails, Typography, Button, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ExpandMore } from '@mui/icons-material/'
import NormalUserTaskRecordTable from './NormalUserTaskRecordTable'
import * as TaskAPI from '../../api/TaskAPI'

const RecordButton = styled(Button)({
  backgroundColor: '#5AA7A7',
  color: '#FFFFFF',
  borderColor: '#5AA7A7',
  margin: '3px',
  '&:hover': {
    backgroundColor: '#5AA7A7',
    color: '#FFFFFF',
    borderColor: '#5AA7A7',
    margin: '3px',
  },
})

const JoinButton = styled(Button)({
  backgroundColor: '#A7D676',
  color: '#FFFFFF',
  borderColor: '#A7D676',
  margin: '3px',
  '&:hover': {
    backgroundColor: '#A7D676',
    color: '#FFFFFF',
    borderColor: '#A7D676',
    margin: '3px',
  },
})

const QuitButton = styled(Button)({
  backgroundColor: '#EB5757',
  color: '#FFFFFF',
  borderColor: '#EB5757',
  margin: '3px',
  '&:hover': {
    backgroundColor: '#EB5757',
    color: '#FFFFFF',
    borderColor: '#EB5757',
    margin: '3px',
  },
})

const modalStyle = {
    position: "absolute",
    width: "98%",
    height: "80%",
    bgcolor: "#FFFFFF",
    marginTop: "20%",
    marginLeft: "1%",
    borderRadius: "0.5rem",
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto"
  }

export default function NormalUserTaskAccordion(props) {

  const [task, setTask] = useState("")
  const [tasks, setTasks] = useState([])
  const [notes, setNotes] = useState([])
  const [records, setRecords] = useState([])
  const [actionStatus, setActionStatus] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    async function getTasks() {
      try {
        let taskResponse = await TaskAPI.getTasks(props.belong)

        let statusDict = {}

        taskResponse.forEach(task => {
          statusDict[task.title] = task.is_join
        })

        setTasks([])
        setTasks((preTasks) => [...preTasks, ...taskResponse])
        setActionStatus((preStatus) => ({...preStatus, ...statusDict}))

      } catch (error) {
        alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
        setIsLoading(false)
      }
      setIsLoading(false)
    }

    setIsLoading(true)
    setActionStatus({})
    getTasks()

  }, [])

  const handleJoinClick = (task) => async (event) => {
    if (window.confirm(`確認要加入 ${task} 嗎 ?`)) {
      try {
        await TaskAPI.executeAction({belong: props.belong, task: task, action: "join"})

        setActionStatus((preStatus) => ({...preStatus, [task]: true}))
        alert(`加入 ${task} 成功!`)

      } catch (error) {
        if (error.response.status !== 201) {
          alert("加入失敗，\n請重新操作一次，或請聯絡客服人員")
          return
        }
      }
    }
  }

  const handleQuitClick = (task) => async (event) => {
    if (window.confirm(`確認要退出 ${task} 嗎 ?`)) {
      try {
        await TaskAPI.executeAction({belong: props.belong, task: task, action: "quit"})

        setActionStatus((preStatus) => ({...preStatus, [task]: false}))
        alert(`退出 ${task} 成功!`)

      } catch (error) {
        if (error.response.status !== 201) {
          alert("退出失敗，\n請重新操作一次，或請聯絡客服人員")
          return
        }
      }
    }
  }

  const handleRecordClick = (task) => async (event) => {
    async function getRecords(belong, task) {
      try {
        let noteResponse = await TaskAPI.getNotes(belong, task)
        let recordResponse = await TaskAPI.getRecords(belong, task)

        setNotes([])
        setNotes((preNotes) => [...preNotes, ...noteResponse])

        setRecords([])
        setRecords((preRecords) => [...preRecords, ...recordResponse])

        setIsModalLoading(false)

      } catch (error) {
        if (error.response.status !== 200) {
          alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
          setIsModalLoading(false)
          return
        }
      }
    }

    setTask(task)
    setIsModalLoading(true)
    setOpenModal(true)
    getRecords(props.belong, task)

  }

  const handleModalClose = async (event) => {
    setOpenModal(false)
  }

  return (
    <div className="tasks-accordion" align="center" style={{margin: "10px"}}>
      { isLoading ? 
        <div align="center">
          <br/>
          <CircularProgress className="loading-spinner"/>
          <br/><span style={{color: "#5AA7A7"}}>載入中...</span>
        </div> :
        tasks.map((task, index) => (
          <Accordion key={index} sx={{marginBottom: "10px"}}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{backgroundColor: actionStatus[task.title] ? "#A8DEE0" : "#D9D9D9"}}
            >
             {task.title}
            </AccordionSummary>
            <AccordionDetails>
              { task.detail.split("\\n").map((line, index) => (
                <Typography key={index} align="left">{line}</Typography>
              ))}
              <div align="right">
                <RecordButton variant="outlined" onClick={handleRecordClick(task.title)}>記錄</RecordButton>
                { actionStatus[task.title] ?
                  <QuitButton variant="outlined" onClick={handleQuitClick(task.title)}>退出</QuitButton> :
                  <JoinButton variant="outlined" onClick={handleJoinClick(task.title)}>加入</JoinButton>
                }
              </div>
            </AccordionDetails>
          </Accordion>
        ))
      }
      <Modal open={openModal} onClose={handleModalClose} disableAutoFocus componentsProps={{backdrop: {style: {backgroundColor: "rgba(0, 0, 0, 0.2)"}}}}>
        <Box sx={modalStyle}>
          <Button className="close-button" sx={{fontSize: "20px", marginLeft: "85%"}} onClick={handleModalClose}>X</Button>
          <Typography variant="h5" component="div" align="left" sx={{color: "#474747", fontWeight: "bold", marginLeft: "2%"}}>{task}</Typography>
          <Typography variant="h7" component="div" align="left" sx={{color: "#7A7A7A", marginTop: "2%", marginLeft: "2%"}}>問卷回答記錄</Typography>
          { notes.map((note, index) => (
            <Typography key={index} sx={{color: "#7A7A7A", fontSize: "0.9rem", marginTop: "2%", marginLeft: "2%"}}>{note}</Typography>
          ))}
          { !isModalLoading ?
            <NormalUserTaskRecordTable records={records} /> :
            <div align="center">
              <br/>
              <CircularProgress className="loading-spinner"/>
              <br/><span style={{color: "#5AA7A7"}}>載入中...</span>
            </div>
          }
        </Box>
      </Modal>
    </div>
  )
}