import { useState, createContext } from 'react'
import { useParams, Outlet } from 'react-router-dom'
import * as AccountAPI from '../api/AccountAPI'
import liff from '@line/liff'

export const LiffContext = createContext()

const LiffProvider = ({children}) => {

  const urlParams = useParams()

  let [isLogin, setIsLogin] = useState(false)
  let [isFriend, setIsFriend] = useState(false)
  let [userName, setUserName] = useState("")

  async function initLIFF(liffId) {

    await liff.init({
    liffId: liffId
    })

    if (!liff.isLoggedIn()) {
      await liff.login({redirectUri: window.location.href})
    } else {
      await liff.getProfile()
      .then(async (profile) => {
        sessionStorage.setItem('external_id', profile.userId)
        setUserName(profile.displayName)

        try {
          await AccountAPI.initUser({belong: urlParams.belong, external_id: profile.userId, display_name: profile.displayName, picture_url: profile.pictureUrl, language: profile.language})
        } catch (error) {
          if (error.response.status !== 201) {
            alert("創建用戶失敗，\n請重新操作一次，或請聯絡客服人員")
            liff.closeWindow()
          }
        }

      })
    }

    await liff.getFriendship().then(res=>{
      if (!res.friendFlag) {
        alert("請加入好友並重新操作一次")
        window.location.replace(`https://line.me/R/ti/p/@${process.env[`REACT_APP_${urlParams.belong.toUpperCase()}_LINE_ID`]}`)
      } else {
        setIsFriend(true)
      }
    })

    setIsLogin(true)

  }

  let value = { isLogin, isFriend, userName, initLIFF }

  return (
    <LiffContext.Provider value={value}>{children}</LiffContext.Provider>
  )
}

export default function LiffUsage() {
  return (
    <LiffProvider>
      <Outlet />
    </LiffProvider>
  )
}