import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: 'https://askin-corp-api.dermai.com.tw/api/accounts/',
  headers: {
    Accept: 'application/json'
  }
})

export const getAccessToken = () => {
  return sessionStorage.getItem("access_token")
}

export const getRefreshToken = () => {
  return sessionStorage.getItem("refresh_token")
}

export const getExternalID = () => {
  return sessionStorage.getItem("external_id")
}

export const refreshToken = (body) => {
  return axiosInstance.post("token/refresh", body).then(res => res.data)
}

export const login = (body) => {
  return axiosInstance.post("login", body).then(res => res.data)
}

export const logout = () => {
  return axiosInstance.post("logout", {refresh: `${getRefreshToken()}`})
}

export const checkExpired = (account) => {
  return axiosInstance.get(`expired/?account=${account}`).then(res => res.data)
}

export const initUser = (body) => {
  return axiosInstance.post("user", body).then(res => res.data)
}

export const verifyUser = (belong, body) => {
  return axiosInstance.post("verification", {...body, belong: belong}, {
  	headers: {Authorization: `Bearer ${sessionStorage.getItem("external_id")}`}
  }).then(res => res.data)
}

export const bindUser = (body) => {
  return axiosInstance.post("bind/", body, {
    headers: {Authorization: `Bearer ${sessionStorage.getItem("external_id")}`}
  }).then(res => res.data)
}

export const checkUserBinded = (belong, from) => {
  return axiosInstance.get(`bind/?belong=${belong}&from=${from}`, {
  	headers: {Authorization: `Bearer ${sessionStorage.getItem("external_id")}`}
  }).then(res => res.data)
}