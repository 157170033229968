import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell} from '@mui/material'
import { inspectItemsDict } from '../common/RelatedVariables'

export default function NormalUserReportRawTable(props) {

  const report = props.report

  return (

    <TableContainer sx={{ maxHeight: 500 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>  
          <TableRow>
            <TableCell align="center" sx={{color: "#5AA7A7"}}>
              項目
            </TableCell>
            <TableCell align="center" sx={{color: "#5AA7A7"}}>
              數值
            </TableCell>
            <TableCell align="center" sx={{color: "#5AA7A7"}}>
              標準值
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(report).map((inspectItem, index) => (
            <TableRow key={index} selected>
              <TableCell align="center" sx={{backgroundColor: "#FFFFFF"}}>{inspectItemsDict[inspectItem]}</TableCell>
                {report[inspectItem][2] === 1 ? 
                  <TableCell className="abnormal-cell" align="center" sx={{backgroundColor: "#FFFFFF"}}>{report[inspectItem][0]}</TableCell>
                  : <TableCell align="center" sx={{backgroundColor: "#FFFFFF"}}>{report[inspectItem][0]}</TableCell>
                }
              <TableCell align="center" sx={{backgroundColor: "#FFFFFF"}}>{report[inspectItem][1]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  )
}