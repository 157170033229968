import { useState, useEffect } from "react"
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import FormCreator from '../common/FormCreator'
import * as AccountAPI from '../../api/AccountAPI'

export default function NormalUserNationalForm(props) {

  const navigate = useNavigate()

  const [showForm, setShowForm] = useState(false)

  useEffect(() => {

    async function checkBinded(belong) {
      setShowForm(false)

      try {
        await AccountAPI.checkUserBinded(belong, "form")
      } catch (error) {
        if (error.response.status === 401) {
          navigate(`/verification/${belong}`, {state: {from: "form", formProps: props}, replace: true})
        } else if (error.response.status === 404) {
          alert("用戶不存在，\n請重新操作一次，或請聯絡客服人員")
          return
        } else {
          alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
          return
        }
      }
      setShowForm(true)
    }

    checkBinded(props.belong)

  }, [])

  return (
    <div>
      { !showForm ? <div align="center"><CircularProgress className="loading-spinner" /></div> :
        <FormCreator isDashboard={props.isDashboard} belong={props.belong} formProject={props.formProject} formTitleID={props.formTitleID} readOnly={props.readOnly} />
      }
    </div>
  )
}