import { Container } from '@mui/material'
import FormProjectUpload from "../components/dashboard/FormProjectUpload"
import Footer from "../components/common/Footer"

export default function UploadFormProject() {
  return (
  	<Container maxWidth="100%" disableGutters sx={{marginTop: "1%"}}>
      <FormProjectUpload />
      <Footer width="60%" topMargin="1%" rightMargin="-75%" logoTopMargin="10px" />
    </Container>
  )
}