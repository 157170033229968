import axios from 'axios'
import * as AccountAPI from './AccountAPI'

const axiosInstance = axios.create({
  baseURL: 'https://askin-corp-api.dermai.com.tw/api/tasks/',
  headers: {
    Accept: 'application/json'
  }
})

axiosInstance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${AccountAPI.getExternalID()}`
  return config
})

export const getTasks = (belong) => {
  return axiosInstance.get(`?belong=${belong}`).then(res => res.data)
}

export const executeAction = (body) => {
  return axiosInstance.post("action/", body).then(res => res.data)
}

export const getRecords = (belong, task) => {
  return axiosInstance.get(`record/?belong=${belong}&task=${task}`).then(res => res.data)
}

export const getNotes = (belong, task) => {
  return axiosInstance.get(`note/?belong=${belong}&task=${task}`).then(res => res.data)
}