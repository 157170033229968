import axios from 'axios'
import * as AccountAPI from './AccountAPI'

const axiosInstance = axios.create({
  baseURL: 'https://askin-corp-api.dermai.com.tw/api/dashboard/report/',
  headers: {
    Accept: 'application/json'
  }
})

axiosInstance.interceptors.request.use(config => {
  config.headers.Authorization = `JWT ${AccountAPI.getAccessToken()}`
  return config
})

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config

    if (error.response.status === 401 && originalRequest.url === 'http://127.0.0.1:8000/api/accounts/token/refresh') {
      return Promise.reject(error);
    }

    if (error.response.data.code === "token_not_valid" && error.response.status === 401 && error.response.statusText === "Unauthorized") {
      const refresh_token = AccountAPI.getRefreshToken()

      if (refresh_token){
        const tokenParts = JSON.parse(atob(refresh_token.split('.')[1]))
        const now = Math.ceil(Date.now() / 1000)

        if (tokenParts.exp > now) {
          let tokens = await AccountAPI.refreshToken({refresh: refresh_token})

          sessionStorage.setItem('access_token', tokens.access)
          sessionStorage.setItem('refresh_token', tokens.refresh)

          return axiosInstance(originalRequest)

        } else {
            console.log("Refresh Token Is Expired", tokenParts.exp, now);
          }
      } else {
          console.log("Refresh Token Not Available")
        }
    }

    return Promise.reject(error)
  }
)

export const getYear = (belong) => {
  return axiosInstance.get(`years/?belong=${belong}`).then(res => res.data)
}

export const getReport = (belong, inspect_year, departments, inspect_groups, abnormal_items, is_intersection, filter_items, sort_item, sort_type, limit, offset) => {
  return axiosInstance.get(`reports/?belong=${belong}&inspect_year=${inspect_year}&departments=${departments}&inspect_groups=${inspect_groups}&abnormal_items=${abnormal_items}&is_intersection=${is_intersection}&filter_items=${encodeURIComponent(JSON.stringify(filter_items))}&sort_item=${sort_item}&sort_type=${sort_type}&limit=${limit}&offset=${offset}`).then(res => res.data)
}

export const getAbnormalReport = (belong, inspect_year, departments, inspect_groups) => {
  return axiosInstance.get(`abnormal/?belong=${belong}&inspect_year=${inspect_year}&departments=${departments}&inspect_groups=${inspect_groups}`).then(res => res.data)
}

export const getDepartment = (belong, inspect_year) => {
  return axiosInstance.get(`departments/?belong=${belong}&inspect_year=${inspect_year}`).then(res => res.data)
}

export const updateReport = (body, config) => {
  return axiosInstance.put("reports/", body, config)
}