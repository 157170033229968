import { Container } from '@mui/material'
import ReportUpload from "../components/dashboard/ReportUpload"
import Footer from "../components/common/Footer"

export default function UploadReports() {
  return (
  	<Container maxWidth="100%" disableGutters sx={{marginTop: "15%"}}>
      <ReportUpload />
      <Footer width="60%" topMargin="10%" rightMargin="-75%" logoTopMargin="10px" />
    </Container>
  )
}