import './Account.css'
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Grid, TextField, Button, Typography, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ArrowForward } from '@mui/icons-material'
import { AuthContext } from '../../AppRoutes'
import dashboard_background from '../../images/dashboard_background.png'
import * as AccountAPI from '../../api/AccountAPI'

const CustomizedTextField = styled(TextField)({
  '& label': {
    color: '#5AA7A7',
  },
  '& label.Mui-focused': {
    color: '#5AA7A7',
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid #5AA7A7',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid #5AA7A7',
  }
})


export default function AccountLogin() {

  const [isLoading, setIsLoading] = useState(false)
  const [userInfo, setUserInfo] = useState({username: "", password: ""})

  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    document.body.style.background = `url(${dashboard_background}) no-repeat fixed`
    document.body.style.backgroundSize = "50%"
    document.body.style.backgroundPositionX = "left"
    document.body.style.backgroundPositionY = "center"
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      await authContext.login(userInfo)

      let expired_response = await AccountAPI.checkExpired(userInfo.username)
      let expired_status = expired_response.expired_status
      let expired_date = expired_response.expired_date

      if (expired_status === "notified") {
        alert(`您的合約到期日為 : ${expired_date}，\n請聯絡客服人員了解續約事項`)
        navigate("/", { state: { from: location }, replace: true })
      } else if (expired_status === "expired") {
        alert(`您的合約已到期 (${expired_date})，\n請聯絡客服人員了解續約事項`)
        setIsLoading(false)
      } else {
        navigate("/", { state: { from: location }, replace: true })
      }
    } catch (error) {
      if (error.response.status === 400) {
        alert("登入失敗，請檢查帳號密碼是否正確")
      } else {
        alert("登入失敗，\n請重新操作一次，或請聯絡客服人員")
      }
      setIsLoading(false)
      return
    }
  }

  return (
    <div className="login-form" align="center">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item md={6} />
          <Grid item md={6}>
            <Box component="form" onSubmit={handleSubmit}>
              <Typography component="div" sx={{color: "#5AA7A7", fontWeight: "bold", fontSize: "65px"}}>Welcome</Typography>
              <Typography component="div" sx={{marginRight: "3px", color: "#474747", fontSize: "19px"}}>歡迎使用 Askin 企業健康管理系統</Typography>
              <Typography component="div" sx={{marginRight: "175px", color: "#474747", fontSize: "19px"}} gutterBottom>請登入以繼續</Typography>
              <br/>
              <CustomizedTextField label="帳號" variant="standard" margin="normal" sx={{width: "290px"}} onChange={(event) => setUserInfo({...userInfo, username: event.target.value})} required />
              <br/>
              <CustomizedTextField label="密碼" type="password" variant="standard" margin="normal" sx={{width: "290px"}} autoComplete="current-password" onChange={(event) => setUserInfo({...userInfo, password: event.target.value})} required />
              <br/><br/>
              { isLoading ? <div align="center"><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7"}}>登入中...</span></div>
                :
                <div style={{marginLeft: "190px"}}>
                  <Button className="login-button" type="submit" variant="outlined" disabled={userInfo["username"].length === 0 || userInfo["password"].length === 0}><span style={{fontSize: 15, fontWeight: "bold", marginTop: "1px", marginLeft: "5px"}}>LOGIN</span><ArrowForward sx={{ fontSize: 22, fontWeight: "bold", marginLeft: "5px"}} /></Button>
                </div>
              }
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}