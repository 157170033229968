import './GeneralDashboard.css'
import { useState, useEffect, useContext, createContext } from "react"
import { useNavigate } from 'react-router-dom'
import { Autocomplete, Typography, Modal, Box, Grid, Button, TextField, CircularProgress} from '@mui/material'
import { styled } from '@mui/material/styles'
import { PersonAdd, Search, FilterAlt, ContentPaste } from '@mui/icons-material'
import { AuthContext } from '../../AppRoutes'
import { DashboardContext } from '../../routes/Reports'
import logo from '../../images/logo.png'
import ReportFilter from './ReportFilter'
import ReportRawDataTable from './ReportRawDataTable'
import ReportAbnormalTable from './ReportAbnormalTable'
import MessageEditorModal from './MessageEditorModal'
import NormalUserReport from "../employee/NormalUserReport"
import SwitchWithLabel from '../common/SwitchWithLabel'
import { categoryCnDict } from '../common/RelatedVariables'
import * as ReportDashboardAPI from '../../api/ReportDashboardAPI'

export const ReportContext = createContext()

const ReportProvider = ({children}) => {
  let [selectedDepartments, setSelectedDepartments] = useState([])
  let [selectedInspectGroups, setSelectedInspectGroups] = useState(["patient"])
  let [selectedAbnormalItems, setSelectedAbnormalItems] = useState([])
  let [inspectItemOptions, setInspectItemOptions] = useState([])
  let [dataGridColumns, setDataGridColumns] = useState([])
  let [dataGridColumnGroup, setDataGridColumnGroup] = useState([])
  let [isFilterReadOnly, setIsFilterReadOnly] = useState(false)

  let value = { selectedDepartments, setSelectedDepartments, selectedInspectGroups, setSelectedInspectGroups, dataGridColumnGroup, setDataGridColumnGroup,
                selectedAbnormalItems, setSelectedAbnormalItems, inspectItemOptions, setInspectItemOptions, dataGridColumns, 
                setDataGridColumns, isFilterReadOnly, setIsFilterReadOnly }

  return (
    <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
  )
}

const CustomizedTextField = styled(TextField)({
  '& label': {
    color: '#5AA7A7',
  },
  '& label.Mui-focused': {
    color: '#5AA7A7',
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid #5AA7A7',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid #5AA7A7',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #5AA7A7'
    },
    '&:hover fieldset': {
      border: '1px solid #5AA7A7'
    },
    '&.Mui-focused fieldset' : {
      border: '1px solid #5AA7A7'
    }
  }
})

const reportModalStyle = {
    position: "absolute",
    width: "50%",
    height: "100%",
    bgcolor: "#FFFFFF",
    marginLeft: "25%",
    borderRadius: "0.5rem",
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto"
  }

export default function ReportDashboard() {

  const authContext = useContext(AuthContext)
  const dashboardContext = useContext(DashboardContext)

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [showTable, setshowTable] = useState(false)
  const [remind, setRemind] = useState("")
  const [year, setYear] = useState("")
  const [years, setYears] = useState([])
  const [departments, setDepartments] = useState([])

  useEffect(() => {
    document.body.style.background = "unset"

    async function getYears() {
      try {
        var yearResponse = await ReportDashboardAPI.getYear(authContext.userName)
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login", {replace: true})
        } else {
          alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
          setIsLoading(false)
        }
      }

      setYears([])
      setYears((prevYears) => [...prevYears, ...yearResponse, ...["+匯入年度健檢報告"]])

      if (yearResponse.length > 0) {
        setYear(yearResponse[yearResponse.length-1])
      } else {
        setIsLoading(false)
        setRemind("目前尚無資料，請上傳健檢報告")
      }
    }

    setIsLoading(true)
    getYears()

  }, [])

  useEffect(() => {

    async function getDepartments(inspectYear) {

      setIsLoading(true)
      setshowTable(false)
      setDepartments([])

      try {
        var departmentList = await ReportDashboardAPI.getDepartment(authContext.userName, inspectYear)

      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login", { replace: true})
        } else {
          alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
          setIsLoading(false)
        }
      }

      dashboardContext.setIsAbnormalSwitch(false)
      setDepartments((prevDepartments) => [...prevDepartments, ...departmentList])
      setIsLoading(false)
      setshowTable(true)

    }

    if (year.length !== 0) {
      const inspectYear = year.slice(0, 4)
      dashboardContext.setYear(inspectYear)
      getDepartments(inspectYear)
    }

  }, [year])

  const handleOnYearChange = (event, values) => {
    if (values === "+匯入年度健檢報告") {
      if (authContext.role === "doctor") {
        alert("您無權限執行此動作")
      } else {
        navigate("/reports", {replace: true})
      }
    } else {
      setYear(values)
    }
  }

  return (
    <div className="dashboard" align="left" style={{marginTop: "1.5%", marginRight: "1%"}}>
      <Box component="div" sx={{display: "flex"}}>
        <Box
          component="img"
          sx={{
            height: "40px",
            width: "120px",
            marginRight: "2%",
            marginTop: "-4px",
          }}
          alt="logo"
          src={logo}
        />
        <Typography component="div" sx={{color: "#474747", fontWeight: "bold", fontSize: "35px", marginTop: "-3px"}}>Health Report</Typography>
        <Autocomplete
          sx={{ width: "25%", marginLeft: "25px", marginTop: "-12px"}}
          id="year-selector"
          options={years}
          value={year}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <CustomizedTextField
              {...params}
              variant="standard"
              label="請選擇或新增健檢報告"
            />
          )}
          onChange={handleOnYearChange}
          isOptionEqualToValue={(option, value) => (option.value === value.value) || (value.value === "")}
          disableClearable
        />
      </Box>
      { isLoading ? 
        <div align="center">
          <br/>
          <CircularProgress className="loading-spinner"/>
          <br/><span style={{color: "#5AA7A7"}}>載入中...</span>
        </div> : <div></div> 
      }
      { showTable ?
        <div>
          <ReportProvider>
            <Box sx={{ boxShadow: 1, borderRadius: "10px"}}>
              <br/>
              <Typography component="div" sx={{marginLeft: "45px", fontWeight: "bold", color: "#474747"}}><PersonAdd sx={{marginBottom: "5px", marginRight: "5px"}}/>部門 Department</Typography>
              <ReportFilter label="部門" items={departments} />
              <br/>
              <Typography component="div" sx={{marginLeft: "45px", fontWeight: "bold", color: "#474747"}}><Search sx={{marginBottom: "5px", marginRight: "5px"}}/>檢查項目 Items</Typography>
              <ReportFilter label="檢查項目" items={Object.keys(categoryCnDict)} />
              <br/>
              {!dashboardContext.isAbnormalSwitch ?
                <div>
                  <Grid container>
                    <Grid item lg={4} md={4}>
                      <Typography component="div" sx={{marginLeft: "45px", fontWeight: "bold", color: "#474747"}}><FilterAlt sx={{marginBottom: "5px", marginRight: "5px"}}/>異常細項 Abnormal Items</Typography>
                    </Grid>
                    <Grid item lg={8} md={8}>
                      <SwitchWithLabel isDashboard={true} from="dashboard_filter" label="僅顯示異常交集"/>
                    </Grid>
                  </Grid>
                  <ReportFilter label="異常細項" />
                  <br/> 
                </div>: 
                <div></div>
              }
            </Box>
            <br/>
            <Box sx={{ boxShadow: 1, borderRadius: "10px"}}>
              { dashboardContext.action === "message" ?
                <MessageEditorModal target="report" /> :
                <Modal open={dashboardContext.openModal} onClose={() => {dashboardContext.setOpenModal(false)}} disableAutoFocus componentsProps={{backdrop: {style: {backgroundColor: "rgba(0, 0, 0, 0.2)"}}}}>
                  <Box sx={reportModalStyle}>
                    <Button className="close-button" sx={{fontSize: "20px", marginLeft: "90%"}} onClick={() => {dashboardContext.setOpenModal(false)}}>X</Button>
                    <NormalUserReport belong={authContext.userName} date={sessionStorage.getItem("inspect_date")} patient={dashboardContext.userInfo} isDashboard={true}/>
                  </Box>
                </Modal>
              }
              <Box sx={{ flexGrow: 1, marginTop: "10px"}}>
                <Grid container>
                  <Grid item lg={8} md={10}>
                    <Typography component="div" sx={{marginLeft: "45px", fontWeight: "bold", color: "#474747"}}><ContentPaste sx={{marginBottom: "5px", marginRight: "5px"}}/>報告 Report</Typography>
                  </Grid>
                  <Grid item lg={4} md={2}>
                    <SwitchWithLabel isDashboard={true} from="dashboard_report" label="僅顯示異常資料"/>
                  </Grid>
                </Grid>
              </Box>
              { dashboardContext.isAbnormalSwitch ?
                <ReportAbnormalTable />
                : <ReportRawDataTable />
              }
            </Box>
          </ReportProvider>
        </div> : 
        <div align="center">
          <Typography variant="h5" sx={{color: "#474747", fontWeight: "bold", marginTop: "20%"}}>{remind}</Typography>
        </div>
      }
    </div>
  )
}