import { useContext } from "react"
import { Box, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import { DashboardContext } from '../../routes/Reports'
import { UserReportContext } from '../../routes/UserReport'

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: "#A7D676",
    '&:hover': {
      backgroundColor: alpha("#A7D676", theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: "#A7D676",
  },
}))

export default function SwitchWithLabel(props) {

  const dashboardContext = useContext(DashboardContext)
  const userReportContext = useContext(UserReportContext)

  const handleChange = (event) => {
    if (props.from === "user") {
      if (props.isDashboard) {
        dashboardContext.setIsUserAbnormalSwitch((preIsUserAbnormalSwitch) => !preIsUserAbnormalSwitch)
      } else {
        userReportContext.setIsAbnormalSwitch((preIsAbnormalSwitch) => !preIsAbnormalSwitch)
      }
    } else if (props.from === "dashboard_report"){
      dashboardContext.setIsAbnormalSwitch((preIsAbnormalSwitch) => !preIsAbnormalSwitch)
    } else {
      dashboardContext.setIsIntersectionSwitch((preIsIntersectionSwitch) => !preIsIntersectionSwitch)
    }
  }

  return (
    <Box>
      <FormGroup sx={{marginTop: "-5px"}}>
        {props.label === "僅顯示異常交集" ?
          <FormControlLabel control={<GreenSwitch />} label={props.label} checked={dashboardContext.isIntersectionSwitch} onChange={handleChange} style={{marginLeft: "0px", marginRight: props.from === "user" ? "0px" : "15px", alignSelf: "flex-end"}} />
          : <FormControlLabel control={<GreenSwitch />} label={props.label} onChange={handleChange} style={{marginLeft: "0px", marginRight: props.from === "user" ? "0px" : "15px", alignSelf: "flex-end"}} />
        }
      </FormGroup>
   </Box>
  )
}