import { useState, useEffect, useContext } from "react"
import { Autocomplete, Typography, Box, Grid, Modal, Button, Divider, TextField, CircularProgress} from '@mui/material'
import { styled } from '@mui/material/styles'
import { AuthContext } from '../../AppRoutes'
import { DashboardContext as ReportDashboardContext } from '../../routes/Reports'
import { DashboardContext as FormDashboardContext } from '../../routes/NationalForms'
import ResultTable from './ResultTable'
import { messageColumns } from '../common/RelatedVariables'
import * as MessageAPI from '../../api/MessageAPI'

const MessageTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #5AA7A7'
    },
    '&:hover fieldset': {
      border: '1px solid #5AA7A7'
    },
    '&.Mui-focused fieldset' : {
      border: '1px solid #5AA7A7'
    }
  }
})

const CustomizedTextField = styled(TextField)({
  '& label': {
    color: '#5AA7A7',
  },
  '& label.Mui-focused': {
    color: '#5AA7A7',
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid #5AA7A7',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid #5AA7A7',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #5AA7A7'
    },
    '&:hover fieldset': {
      border: '1px solid #5AA7A7'
    },
    '&.Mui-focused fieldset' : {
      border: '1px solid #5AA7A7'
    }
  }
})

const messageModalStyle = {
    position: "absolute",
    width: "70%",
    height: "70%",
    bgcolor: "#FFFFFF",
    marginLeft: "15%",
    marginTop: "10%",
    borderRadius: "0.5rem",
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto"
  }

const childModalStyle = {
    position: "absolute",
    width: "60%",
    height: "100%",
    bgcolor: "#FFFFFF",
    marginLeft: "25%",
    borderRadius: "0.5rem",
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto"
  }

export default function MessageEditorModal(props) {

  const authContext = useContext(AuthContext)

  const reportDashboardContext = useContext(ReportDashboardContext)
  const formDashboardContext = useContext(FormDashboardContext)

  if (props.target === "report") {
    var dashboardContext = reportDashboardContext
  } else if (props.target === "form") {
    var dashboardContext = formDashboardContext
  }

  const [isModalLoading, setIsModalLoading] = useState(false)
  const [modalLoadingText, setModalLoadingText] = useState("")
  const [openChildModal, setOpenChildModal] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [messageTemplates, setMessageTemplates] = useState([])
  const [isMessageTemplateEdit, setIsMessageTemplateEdit] = useState(false)
  const [successList, setSuccessList] = useState([])
  const [failedList, setFailedList] = useState([])

  useEffect(() => {

    async function getMessageTemplates() {
      try {
        var messageTemplatesResponse = await MessageAPI.getMessageTemplate(null, authContext.userName, false)
        setMessageTemplates([])
        setMessageTemplates((prevMessageTemplates) => [...prevMessageTemplates, ...messageTemplatesResponse.templates_name, ...["+新增訊息模板"]])
      } catch (error) {
        alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
        return
      }
    }

    getMessageTemplates()

  }, [])

  const handleOnTemplateChange = async (event, values) => {
    setIsSubmit(false)
    setModalLoadingText("載入中...")
    setIsModalLoading(true)
    dashboardContext.setMessageTitle("")
    dashboardContext.setMessageTitle(values)
    try {
      if (values === "+新增訊息模板") {
        setIsMessageTemplateEdit(true)
        dashboardContext.setMessageText("")
      } else {
        setIsMessageTemplateEdit(false)
        dashboardContext.setMessageText("")
        let messageTemplateResponse = await MessageAPI.getMessageTemplate(values, authContext.userName, true)
        dashboardContext.setMessageText("")
        dashboardContext.setMessageText(messageTemplateResponse.template)
      }
    } catch (error) {
      if (error.response.status !== 200) {
        alert("獲取訊息模板失敗，\n請重新操作一次，或請聯絡客服人員")
        setIsModalLoading(false)
        return
      }
    }
    setIsModalLoading(false)
  }

  const handleModalClose = async (event) => {
    dashboardContext.setOpenModal(false)
    dashboardContext.setMessageTitle("")
    setIsMessageTemplateEdit(false)
  }

  const handleChildModalClose = async (event) => {
    setOpenChildModal(false)
  }

  const handleInsertClick = async (text) => {
    dashboardContext.setMessageText(dashboardContext.messageText + text)
  }

  const handleUpdateClick = async (event) => {
    if (window.confirm(`是否更新目前內容至訊息模板 : ${dashboardContext.messageTitle} ?`)) {
      setIsSubmit(false)
      setModalLoadingText("更新中...")
      setIsModalLoading(true)
      try {
        await MessageAPI.updateMessageTemplate({name: dashboardContext.messageTitle, belong: authContext.userName, content: dashboardContext.messageText})
        let messageTemplatesResponse = await MessageAPI.getMessageTemplate(null, authContext.userName, false)
        setMessageTemplates([])
        setMessageTemplates((prevMessageTemplates) => [...prevMessageTemplates, ...messageTemplatesResponse.templates_name, ...["+新增訊息模板"]])
        alert("更新訊息模板成功!")
      } catch (error) {
        if (error.response.status !== 204) {
          alert("更新訊息模板失敗，\n請重新操作一次，或請聯絡客服人員")
          setIsModalLoading(false)
          return
        }
      }
      setIsModalLoading(false)
    }
  }

  const handleDeleteClick = async (event) => {
    if (window.confirm(`是否確認刪除訊息模板 : ${dashboardContext.messageTitle} ?`)) {
      setIsSubmit(false)
      setModalLoadingText("刪除中...")
      setIsModalLoading(true)
      try {
        await MessageAPI.deleteMessageTemplate(dashboardContext.messageTitle, authContext.userName)
        let messageTemplatesResponse = await MessageAPI.getMessageTemplate(null, authContext.userName, false)
        setMessageTemplates([])
        setMessageTemplates((prevMessageTemplates) => [...prevMessageTemplates, ...messageTemplatesResponse.templates_name, ...["+新增訊息模板"]])
        dashboardContext.setMessageTitle("")
        dashboardContext.setMessageText("")
        alert("刪除訊息模板成功!")
      } catch (error) {
        if (error.response.status !== 204) {
          alert("刪除訊息模板失敗，\n請重新操作一次，或請聯絡客服人員")
          setIsModalLoading(false)
          return
        }
      }
      setIsModalLoading(false)
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsModalLoading(true)
    setIsSubmit(true)

    if (isMessageTemplateEdit) {
      setModalLoadingText("新增中...")
      try {
        await MessageAPI.addMessageTemplate({name: dashboardContext.messageTitle, belong: authContext.userName, content: dashboardContext.messageText})
        let messageTemplatesResponse = await MessageAPI.getMessageTemplate(null, authContext.userName, false)
        setMessageTemplates([])
        setMessageTemplates((prevMessageTemplates) => [...prevMessageTemplates, ...messageTemplatesResponse.templates_name, ...["+新增訊息模板"]])
        alert("新增訊息模板成功!")
      } catch (error) {
        if (error.response.status === 409) {
          alert("訊息模板名稱已存在，\n請更改訊息模板名稱")
          setIsModalLoading(false)
          return
        }
        else if (error.response.status !== 201) {
          alert("新增訊息模板失敗，\n請重新操作一次，或請聯絡客服人員")
          setIsModalLoading(false)
          return
        }
      }
    } else {
      setModalLoadingText("發送中...")
      try {
        if (dashboardContext.messageType === "independent") {
          await MessageAPI.sendMessage({belong: authContext.userName,
                                        type: dashboardContext.messageType, 
                                        external_id: sessionStorage.getItem("external_id"), 
                                        username: dashboardContext.userInfo["username"], 
                                        email: dashboardContext.userInfo["email"], 
                                        text: dashboardContext.messageText})
          alert("發送訊息成功!")
        } else {
          let messageResponse = await MessageAPI.sendMessage({belong: authContext.userName, 
                                                              type: dashboardContext.messageType, 
                                                              usersInfo: dashboardContext.userInfo["usersInfo"], 
                                                              text: dashboardContext.messageText})
          setSuccessList([])
          setFailedList([])
          setSuccessList((prevSuccessList) => [...prevSuccessList, ...messageResponse.success_list])
          setFailedList((prevFailedList) => [...prevFailedList, ...messageResponse.failed_list])
          setOpenChildModal(true)
        }

      } catch (error) {
        if (error.response.status === 429) {
          alert("此LINE官方帳號發送訊息額度已滿，\n請聯絡客服人員")
        } else {
          alert("發送訊息失敗，\n請重新操作一次，或請聯絡客服人員")
        }
        setIsModalLoading(false)
        return
      }
    }

    setIsModalLoading(false)

  }

  return (
    <Box sx={{ flexGrow: 1}}>
      <Modal open={dashboardContext.openModal} onClose={handleModalClose} disableAutoFocus componentsProps={{backdrop: {style: {backgroundColor: "rgba(0, 0, 0, 0.2)"}}}}>
        <Box component="form" onSubmit={handleSubmit} sx={messageModalStyle}>
          <Button className="close-button" sx={{fontSize: "20px", marginLeft: "93%"}} onClick={handleModalClose}>X</Button>
          {isMessageTemplateEdit ? 
            <Typography variant="h6" component="div" align="left" sx={{color: "#474747", fontWeight: "bold", marginTop: "-2%", marginLeft: "5%"}}>請輸入欲新增訊息模板</Typography>
            :
            <Typography variant="h6" component="div" align="left" sx={{color: "#474747", fontWeight: "bold", marginTop: "-2%", marginLeft: "5%"}}>請輸入欲發送訊息內容</Typography>
          }
          <Divider variant="middle" />
          <div align="left">
            {isMessageTemplateEdit ? 
              <CustomizedTextField label="訊息模板名稱" variant="outlined" margin="normal" sx={{width: "25%", marginLeft: "5%"}} onChange={(event) => dashboardContext.setMessageTitle(event.target.value)} required />
              : <div></div>
            }
          </div>
          <div align="center">
            <MessageTextField required variant="outlined" margin="normal" value={dashboardContext.messageText} helperText="限5000字以內" rows={6} sx={{width: "90%"}} onChange={(event) => dashboardContext.setMessageText(event.target.value)} fullWidth multiline />
          </div>
          <div align="left">
            <Grid container>
              <Grid item lg={8} md={10}>
                <Autocomplete
                  sx={{ width: "40%", marginLeft: "8%"}}
                  id="template-selector"
                  options={messageTemplates}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <CustomizedTextField
                      {...params}
                      variant="standard"
                      label="請選擇或新增訊息模板"
                    />
                  )}
                  onChange={handleOnTemplateChange}
                  isOptionEqualToValue={(option, value) => (option.value === value.value) || (value.value === "")}
                  disableClearable
                />
              </Grid>
              <Grid item lg={4} md={2} sx={{alignSelf: "end", textAlign: "center"}}>
                <Button className="insert-button" variant="outlined" onClick={() => handleInsertClick("{員工姓名}")}>插入員工姓名</Button>
                { props.target === "form" ? <Button className="insert-button" variant="outlined" sx={{marginLeft: "5px"}} onClick={() => handleInsertClick(dashboardContext.formLinks)}>插入問卷連結</Button> :
                  <div></div>
                }
              </Grid>
            </Grid>
          </div>
          <Grid container>
            <Grid item lg={8} md={10}>
              { isModalLoading && !isSubmit ?
                <div style={{marginTop: "5%", marginLeft: "23%"}}><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7", fontSize: "15px", marginLeft: "-1%"}}>{modalLoadingText}</span></div>
                : !isMessageTemplateEdit ?
                  <div><Button className="update-button" variant="outlined" sx={{marginLeft: "8%"}} onClick={handleUpdateClick} disabled={dashboardContext.messageTitle.length === 0}>更新訊息模板</Button><Button className="delete-button" variant="outlined" onClick={handleDeleteClick} disabled={dashboardContext.messageTitle.length === 0}>刪除訊息模板</Button></div>
                  : <div></div>
              }
            </Grid>
            <Grid item lg={4} md={2} sx={{alignSelf: "end", textAlign: "center"}}>
              { isModalLoading && isSubmit ?
                <div style={{marginTop: "5%"}}><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7", fontSize: "15px"}}>{modalLoadingText}</span></div>
                : isMessageTemplateEdit ?
                    <Button className="submit-button" type="submit" variant="outlined" sx={{marginTop: "3% !important"}} disabled={dashboardContext.messageText.length === 0}>新增</Button>
                  : <Button className="submit-button" type="submit" variant="outlined" sx={{marginTop: "-20% !important"}} disabled={dashboardContext.messageText.length === 0}>發送</Button>
              }
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={openChildModal} onClose={handleChildModalClose} disableAutoFocus componentsProps={{backdrop: {style: {backgroundColor: "rgba(0, 0, 0, 0.2)"}}}}>
        <Box sx={childModalStyle}>
          <Button className="close-button" sx={{fontSize: "20px", marginLeft: "93%"}} onClick={handleChildModalClose}>X</Button>
          <Typography variant="h6" component="div" align="left" sx={{color: "#474747", fontWeight: "bold", marginTop: "-2%", marginLeft: "2%"}}>發送結果</Typography>
          <Divider variant="middle" />
          <br/>
          <ResultTable title="發送成功列表" columns={messageColumns} result_list={successList} />
          <br/>
          <ResultTable title="發送失敗列表" columns={messageColumns} result_list={failedList} />
        </Box>
      </Modal>
    </Box>
  )
}