import { useState, useContext } from "react"
import { useParams } from 'react-router-dom'
import { Container } from '@mui/material'
import { LiffContext } from './LiffUsage'
import Flag from "../components/common/Flag"
import NormalUserTask from "../components/employee/NormalUserTask"

export default function UserTask() {

  const urlParams = useParams()
  const liffContext = useContext(LiffContext)

  return (
    <Container maxWidth="100%" disableGutters>
      { liffContext.isLogin ? 
        <NormalUserTask belong={urlParams.belong} /> :
        <div align="center"><Flag message="載入失敗，請勿直接參訪此頁面" /></div>
      }
    </Container>
  )
}