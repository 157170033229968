import './GeneralUpload.css'
import { useState, useEffect, useContext } from "react"
import { useNavigate } from 'react-router-dom'
import { Box, Paper, Grid, Stack, Modal, Divider, Fab, Input, Typography, Button, CircularProgress, LinearProgress, linearProgressClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Add, Upload, Download, ArrowBack } from '@mui/icons-material'
import PropTypes from 'prop-types'
import dashboard_background from '../../images/dashboard_background.png'
import { AuthContext } from '../../AppRoutes'
import ResultTable from './ResultTable'
import { reportUploadColumns } from '../common/RelatedVariables'
import * as ReportDashboardAPI from '../../api/ReportDashboardAPI'

function LinearProgressWithLabel(props) {
  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
      { props.value === 100 ? <div align="center"><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7"}}>數據後處理中，請稍候...</span></div>
        : <div></div>
      }
    </div>
  )
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
}

const BorderLinearProgress = styled(LinearProgressWithLabel)(({ theme }) => ({
  height: "10px",
  borderRadius: "5px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#A8DEE0"
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "5px",
    backgroundColor: "#5AA7A7",
  },
}))

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary
}));

const modalStyle = {
    position: "absolute",
    width: "60%",
    height: "100%",
    bgcolor: "#FFFFFF",
    marginLeft: "25%",
    borderRadius: "0.5rem",
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto"
  }

export default function ReportUpload() {

  const authContext = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(false)
  const [selectedFileName, setSelectedFileName] = useState("選擇檔案")
  const [submitData, setSubmitData] = useState({belong: authContext.userName, file: null})
  const [uploadProgress, setUploadProgress] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [failedList, setFailedList] = useState([])
  const [normalList, setNormalList] = useState([])

  const navigate = useNavigate()
  
  useEffect(() => {
    document.body.style.background = `url(${dashboard_background}) no-repeat fixed`
    document.body.style.backgroundSize = "50%"
    document.body.style.backgroundPositionX = "left"
    document.body.style.backgroundPositionY = "center"
  }, [])

  const handleChange = (event) => {
    setSelectedFileName(event.target.files[0].name)
    setSubmitData({...submitData, file: event.target.files[0]})
  }

  const handleModalClose = async (event) => {
    setOpenModal(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const config = {
      onUploadProgress: function(progressEvent) {
        let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setUploadProgress(progress)
      }
    }

    setIsLoading(true)

    const formData = new FormData()

    Object.entries(submitData).forEach(([key, value]) => {
      formData.append(key, value)
    })

    try {
      await ReportDashboardAPI.updateReport(formData, config)
      navigate("/", {state: {target: "reports"}, replace: true })
    } catch (error) {
      setUploadProgress(0)
      if (error.response.status === 401) {
        navigate("/login", {replace: true})
      }  else if (error.response.status === 422) {
        let error_data = error.response.data
        setFailedList([])
        setNormalList([])
        setFailedList((prevFailedList) => [...prevFailedList, ...error_data.failed_list])
        setNormalList((prevNormalList) => [...prevNormalList, ...error_data.normal_list])
        setOpenModal(true)
      } else {
        alert("上傳失敗，\n請重新再操作一次，或請聯絡客服人員")
      }
    }
    
    setIsLoading(false)
    setUploadProgress(0)

  }

  const handleClick = (event) => {
    navigate("/", {state: {target: "reports"}, replace: true })
  }

  const handleDownload = async (event) => {
    window.open("https://di2bu62gtprjr.cloudfront.net/askin_business_example_report.xlsx")
    alert("下載成功，\n請根據範例報告格式進行上傳")
  }

  return (
    <div className="upload-form" align="center">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item md={6} />
          <Grid item md={6}>
            <Box component="form" onSubmit={handleSubmit}>
              <Typography component="div" sx={{color: "#5AA7A7", fontWeight: "bold", fontSize: "65px"}}>Health Report</Typography>
              <Typography component="div" sx={{marginRight: "280px", color: "#474747", fontSize: "19px"}} gutterBottom>員工健檢報告上傳</Typography>
              <br/>
              <label htmlFor="upload-file">
                <Input type="file" id="upload-file" onChange={handleChange} style={{ display: 'none' }} required />
                <Fab className="upload-fab" size="large" color="inherit" component="span" aria-label="add" variant="extended" sx={{width: 430}}>
                  <Add sx={{marginBottom: "0.5%"}} /> {selectedFileName}
                </Fab>
              </label>
              <br/><br/>      
              { isLoading ? <Box sx={{ width: "75%", marginLeft: "15px"}}><BorderLinearProgress value={uploadProgress} /></Box>
                : <Button className="upload-button" type="submit" variant="outlined" disabled={selectedFileName === "選擇檔案"}><span style={{fontSize: 15, fontWeight: "bold", marginTop: "3px", marginLeft: "5px"}}>UPLOAD</span><Upload sx={{ fontSize: 22, fontWeight: "bold", marginLeft: "5px" }} /></Button>
              }
              { isLoading ? <div></div>
                : <Button className="back-button" variant="outlined" onClick={handleClick} sx={{marginLeft: "20px"}}><span style={{fontSize: 15, fontWeight: "bold", marginTop: "3px", marginLeft: "5px"}}>BACK</span><ArrowBack sx={{ fontSize: 22, fontWeight: "bold", marginLeft: "5px" }} /></Button>
              }
            </Box>
            <Box sx={{ boxShadow: 1, borderRadius: "10px"}}>
              <Modal open={openModal} onClose={handleModalClose} disableAutoFocus componentsProps={{backdrop: {style: {backgroundColor: "rgba(0, 0, 0, 0.2)"}}}}>
                <Box sx={modalStyle}>
                  <Button className="close-button" sx={{fontSize: "20px", marginLeft: "93%"}} onClick={handleModalClose}>X</Button>
                  <Typography variant="h6" component="div" align="left" sx={{color: "#474747", fontWeight: "bold", marginTop: "-2%", marginLeft: "2%"}}>上傳失敗，請確認以下報告內容是否正確</Typography>
                  <Divider variant="middle" />
                  <br/>
                  <ResultTable title="失敗項目列表" columns={reportUploadColumns} result_list={failedList} />
                  <Typography component="div" sx={{fontWeight: "bold", color: "#474747", marginTop: "2%", marginLeft: "2%"}}>上傳錯誤可能原因</Typography>
                  <Divider variant="middle" />
                  <Typography component="div" sx={{color: "#474747", marginTop: "2%", marginLeft: "2%"}}>1. 該欄位應填入數字，但卻填入文字或符號</Typography>
                  <Typography component="div" sx={{color: "#474747", marginTop: "2%", marginLeft: "2%"}}>2. 若需要使用文字標示為 "標準內的正常值" 或 "拒絕檢查"，請選擇下列其中一個用詞填入</Typography>
                  <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={0.5} sx={{marginTop: "2%", marginLeft: "2%"}}>
                    { normalList.map((item, index) => (
                      <Item key={index}>{item}</Item>
                    ))}
                  </Stack>
                  <Typography component="div" sx={{color: "#474747", marginTop: "2%", marginLeft: "2%"}}>3. 若無法排除狀況，請聯絡客服人員</Typography>
                </Box>
              </Modal>
            </Box>
            <Button className="download-button" variant="outlined" onClick={handleDownload} sx={{marginTop: "5%"}}><Download sx={{ fontSize: 20, fontWeight: "bold"}} /><span style={{fontSize: 12, fontWeight: "bold", marginTop: "3px", marginLeft: "5px"}}>範本下載</span></Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}