import { useNavigate } from 'react-router-dom'
import { Box, Grid, Button, Typography } from '@mui/material'
import { KeyboardArrowLeft, InventoryOutlined } from '@mui/icons-material'
import user_head from '../../images/user_head.png'
import NormalUserTaskAccordion from './NormalUserTaskAccordion'

export default function NormalUserTask(props) {

  const navigate = useNavigate()

  const handleClick = (event) => {
    navigate(`/menu/${props.belong}`, {replace: true})
  }

  return (
    <div className="user-report">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item>
            <div align="center" style={{display: "flex"}}>
              <Box component="img" sx={{width: "100%"}} alt="user_head" src={user_head}/>
              <Button onClick={handleClick} sx={{position: "absolute", marginLeft: "3%", marginTop: "12%"}}><KeyboardArrowLeft sx={{color: "#FFFFFF", fontSize: "35px"}} /></Button>
              <Typography variant="h6" component="div" sx={{position: "absolute", marginLeft: "35%", marginTop: "14%", color: "#FFFFFF", fontWeight: "bold"}}>J-Reminder</Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Typography gutterBottom variant="h7" component="div" sx={{fontWeight: "bold", margin: "2%"}}><InventoryOutlined sx={{marginRight: "2px"}} />任務清單</Typography>
      <NormalUserTaskAccordion belong={props.belong} />
    </div>
  )
}