import { Box } from '@mui/material'
import logo from '../../images/logo.png'

export default function Logo(props) {
  return (
    <div className="logo" style={{display: "flex", justifyContent: "center"}}>
      <span style={{color: "#5AA7A7", fontSize: 10, whiteSpace: "nowrap", marginTop: props.logoTopMargin, marginRight: "5px"}}>Power By</span>
      <Box
        component="img"
        sx={{
          height: "100%",
          width: props.width,
        }}
        alt="logo"
        src={logo}
      />
    </div>
  )
}