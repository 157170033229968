import { useState, createContext } from "react"
import { Container } from '@mui/material'
import Header from "../components/common/Header"
import ReportDashboard from "../components/dashboard/ReportDashboard"

export const DashboardContext = createContext()

const DashboardProvider = ({children}) => {
  let [isAbnormalSwitch, setIsAbnormalSwitch] = useState(false)
  let [isUserAbnormalSwitch, setIsUserAbnormalSwitch] = useState(false)
  let [isIntersectionSwitch, setIsIntersectionSwitch] = useState(false)
  let [year, setYear] = useState()
  let [openModal, setOpenModal] = useState(false)
  let [action, setAction] = useState("")
  let [messageType, setMessageType] = useState("")
  let [messageTitle, setMessageTitle] = useState("")
  let [messageText, setMessageText] = useState("")
  let [userInfo, setUserInfo] = useState({})

  let value = { isAbnormalSwitch, setIsAbnormalSwitch, isUserAbnormalSwitch, setIsUserAbnormalSwitch, 
                isIntersectionSwitch, setIsIntersectionSwitch, year, setYear, openModal, setOpenModal, 
                action, setAction, messageType, setMessageType, messageText, setMessageText, messageTitle, setMessageTitle, 
                userInfo, setUserInfo }

  return (
    <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
  )
}

export default function Reports() {
  return (
    <Container maxWidth="100%">
      <Header />
      <DashboardProvider>
        <ReportDashboard />
      </DashboardProvider>
    </Container>
  )
}