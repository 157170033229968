import { useContext } from "react"
import { useLocation } from 'react-router-dom'
import { Container } from '@mui/material'
import { LiffContext } from './LiffUsage'
import Footer from "../components/common/Footer"
import Flag from "../components/common/Flag"
import NormalUserMailBind from "../components/account/NormalUserMailBind"
import NormalUserMobileBind from "../components/account/NormalUserMobileBind"

export default function UserBind() {

  const liffContext = useContext(LiffContext)

  const location = useLocation()

  let belong = location.state.belong
  let from = location.state.from
  let formProps = location.state.formProps
  let userInfo = location.state.userInfo
  let email = location.state.email || "--"
  let bindWay = location.state.bindWay

  return (
    <Container maxWidth="100%" disableGutters>
      { liffContext.isLogin ? 
        bindWay === "mail" ? 
        <NormalUserMailBind belong={belong} from={from} formProps={formProps} userInfo={userInfo} email={email} /> :
        <NormalUserMobileBind belong={belong} from={from} formProps={formProps} userInfo={userInfo} /> :
        <div align="center"><Flag message="載入失敗，請勿直接參訪此頁面" /></div>
      }
      <Footer width="200%" topMargin="10%" logoTopMargin="10px" />
    </Container>
  )
}