import { useEffect, useContext } from "react"
import { useParams } from 'react-router-dom'
import { Container, CircularProgress } from '@mui/material'
import { LiffContext } from './LiffUsage'
import NormalUserNationalForm from "../components/employee/NormalUserNationalForm"

export default function UserNationalForm() {

  const urlParams = useParams()
  const liffContext = useContext(LiffContext)

  useEffect(() => {

    if (liffContext.isLogin) {
      return
    } else {
      liffContext.initLIFF(process.env[`REACT_APP_${urlParams.belong.toUpperCase()}_LIFF_NATIONAL_FORM`])
    }

  }, [])

  return (
  	<Container maxWidth="100%" disableGutters sx={{backgroundColor: "#F8F8F8", padding: "3%"}}>
      { liffContext.isLogin && liffContext.isFriend ? 
        <NormalUserNationalForm isDashboard={false} belong={urlParams.belong} formProject={urlParams.formProject} formTitleID={urlParams.formTitleID} readOnly={urlParams.readOnly === "true"} /> :
        <div align="center"><br/><CircularProgress className="loading-spinner" /></div>
      }
    </Container>
  )
}