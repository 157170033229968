import { Box } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { styled } from '@mui/material/styles'
import { zHTWLocale } from '../common/RelatedVariables'

const CustomizedDataGrid = styled(DataGridPro)({
  '&.MuiDataGrid-root': {
    border: 'none'
  }, 
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    outline: 'none'
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
    outline: 'none',
    color: '#5AA7A7'
  },
  '.MuiDataGrid-footerContainer': {
    border: 'none'
  }
})

export default function NormalUserTaskRecordTable(props) {

  const columns = [{field: "dispatch_date", headerName: "時間", filterable: false},
                   {field: "question", headerName: "問題", width: 180, filterable: false},
                   {field: "answer", headerName: "回答", width: 120, filterable: false}]

  const records = props.records

  return (
    <Box sx={{ height: "400px", width: "100%"}}>
      <CustomizedDataGrid
        columns={columns}
        rows={records}
        localeText={zHTWLocale}
        disableSelectionOnClick
      />
    </Box>
  )
}