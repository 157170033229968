import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import { AppRoutes } from './AppRoutes'
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
  	<AppRoutes />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
