import './GeneralTable.css'
import { useState, useEffect, useContext } from "react"
import { useNavigate } from 'react-router-dom'
import { Box, Button, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Message, ContentCopy, FileDownload } from '@mui/icons-material'
import { DataGridPro, GridToolbarContainer, GridToolbarExport, GridLinkOperator, useGridApiContext, getGridStringOperators, gridFilteredSortedRowEntriesSelector } from '@mui/x-data-grid-pro'
import { AuthContext } from '../../AppRoutes'
import { DashboardContext } from '../../routes/NationalForms'
import { FormContext } from './FormDashboard'
import { zHTWLocale } from '../common/RelatedVariables'
import * as GeneralDashboardAPI from '../../api/GeneralDashboardAPI'
import * as FormDashboardAPI from '../../api/FormDashboardAPI'

const CustomizedDataGrid = styled(DataGridPro)({
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    outline: 'none'
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
    outline: 'none'
  },
})

function CustomizedToolbar() {

  const apiRef = useGridApiContext()

  const authContext = useContext(AuthContext)
  const dashboardContext = useContext(DashboardContext)

  const navigate = useNavigate()

  const [isExportLoading, setIsExportLoading] = useState(false)

  const rows = gridFilteredSortedRowEntriesSelector(apiRef)

  const handleSendClick = async (event) => {
    let usersInfo = new Set()
    
    apiRef.current.state.selection.forEach(item => {
      rows.forEach(function(element, idx) {
        if (element.id === item) {
          let userInfo = [element.model.name, element.model.id_number, element.model.email, element.model.department, element.model.employee_number]

          if (element.model.is_finish === "未結案" && !usersInfo.has(JSON.stringify(userInfo))) {
            usersInfo.add(JSON.stringify(userInfo))
          }
        }
      })
    })

    usersInfo = [...usersInfo].map(element => JSON.parse(element))

    dashboardContext.setMessageType("multiple")
    dashboardContext.setMessageText("")
    dashboardContext.setUserInfo({})
    dashboardContext.setUserInfo((prevUserInfo) => ({...prevUserInfo, ...{username: "{員工姓名}", usersInfo: usersInfo}}))
    dashboardContext.setAction("message")
    dashboardContext.setOpenModal(true)
  }

  const handleExportClick = async (event) => {

    setIsExportLoading(true)

    let usersInfo = new Set()
    
    apiRef.current.state.selection.forEach(item => {
      rows.forEach(function(element, idx) {
        if (element.id === item) {
          let userInfo = [element.model.name, element.model.id_number, element.model.company, element.model.department, element.model.employee_number]

          if (!usersInfo.has(JSON.stringify(userInfo))) {
            usersInfo.add(JSON.stringify(userInfo))
          }
        }
      })
    })

    usersInfo = [...usersInfo].map(element => JSON.parse(element))

    try {
      await FormDashboardAPI.exportForm({belong: authContext.userName, project: dashboardContext.project, usersInfo: usersInfo})
      setIsExportLoading(false)
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/login", {replace: true})
      } else {
        alert("導出失敗，\n請重新操作一次，或請聯絡客服人員")
        setIsExportLoading(false)
      }
    }

  }

  const handleCopyClick = async (event) => {
    navigator.clipboard
      .writeText(dashboardContext.formLinks)
      .then(() => {
        alert("已複製問卷連結到剪貼簿")
      })
      .catch(() => {
        alert("複製失敗")
      })
  }

  return (
    <div align="right">
      <GridToolbarContainer>
        <GridToolbarExport sx={{color: "#5AA7A7"}} csvOptions={{utf8WithBom: true}} />
        <Button onClick={handleCopyClick} sx={{color: "#5AA7A7", fontSize: "0.82rem", marginLeft: "15px"}}><ContentCopy sx={{color: "#5AA7A7", fontSize: "18px", marginRight: "8px"}} />複製問卷連結</Button>
        <Button onClick={handleSendClick} sx={{color: "#5AA7A7", fontSize: "0.82rem", marginLeft: "15px"}} disabled={apiRef.current.state.selection.length === 0 || authContext.role === "doctor"}><Message sx={(apiRef.current.state.selection.length === 0 || authContext.role === "doctor") ? {fontSize: "18px", marginRight: "8px"} : {color: "#5AA7A7", fontSize: "18px", marginRight: "8px"}} />群發問卷</Button>
        { isExportLoading ? <CircularProgress sx={{width: "25px !important", height: "25px !important", marginLeft: "30px", color: "#5AA7A7"}}/> : 
          <Button onClick={handleExportClick} sx={{color: "#5AA7A7", fontSize: "0.82rem", marginLeft: "15px"}} disabled={apiRef.current.state.selection.length === 0}><FileDownload sx={apiRef.current.state.selection.length === 0 ? {fontSize: "18px", marginRight: "8px"} : {color: "#5AA7A7", fontSize: "18px", marginRight: "8px"}} />導出填寫內容</Button>
        }
      </GridToolbarContainer>
    </div>
  )
}

export default function FormEmployeeTable() {

  const authContext = useContext(AuthContext)
  const dashboardContext = useContext(DashboardContext)
  const formContext = useContext(FormContext)

  const [isLoading, setIsLoading] = useState(false)

  const [dataGridColumns, setDataGridColumns] = useState([])
  const [dataGridColumnGroup, setDataGridColumnGroup] = useState([])

  const [employees, setEmployees] = useState([])
  const [employeesCount, setEmployeesCount] = useState(0)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)

  const [filterItems, setFilterItems] = useState([])

  const [sortItem, setSortItem] = useState(null)
  const [sortType, setSortType] = useState(null)

  const navigate = useNavigate()

  useEffect(() => {

    let initDataGridColumns = [{field: "is_finish", headerName: "案件狀態", filterable: false, width: "100"},
                               {field: "action", headerName: "動作", sortable: false, filterable: false, width: "250", renderCell: (params) => {return <div><Button className="action-button" disabled={params.row.is_finish === "已結案" || authContext.role === "doctor"} onClick={handleActionClick(params, "message")}>發送問卷</Button>|<Button className="action-button" onClick={handleActionClick(params, "record")}>填寫紀錄</Button>|<Button className="action-button" disabled={params.row.is_finish === "已結案" || authContext.role === "doctor"} onClick={handleActionClick(params, "finish")}>結束案件</Button></div>}},
                               {field: "company", headerName: "公司", filterable: false, width: "150"},
                               {field: "department", headerName: "部門", filterable: false, width: "100"},
                               {field: "employee_number", headerName: "工號", filterOperators: getGridStringOperators().filter((operator) => operator.value === 'isAnyOf'), width: "100"},
                               {field: "name", headerName: "姓名", filterOperators: getGridStringOperators().filter((operator) => operator.value === 'isAnyOf'), width: "100"}]

    let initDataGridColumnGroup = [{groupId: "employee-info", headerName: "人員資訊", headerClassName: "column-group", children: [{field: "company"}, {field: "department"}, {field: "employee_number"}, {field: "name"}]}]

    async function getForms() {
      try {
        var formResponse = await FormDashboardAPI.getForm(authContext.userName, dashboardContext.project, null)
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login", {replace: true})
        } else {
          alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
          setIsLoading(false)
        }
      }

      dashboardContext.setFormLinks("")

      let tmp_header_dict

      formResponse.forEach(function(form, idx) {
        tmp_header_dict = {}
        tmp_header_dict["field"] = `${form.form_id}-status`
        tmp_header_dict["headerName"] = "填寫狀態"
        tmp_header_dict["filterable"] = false
        tmp_header_dict["width"] = "150"

        initDataGridColumns.push(tmp_header_dict)

        tmp_header_dict = {}
        tmp_header_dict["field"] = `${form.form_id}-detail`
        tmp_header_dict["headerName"] = "填寫內容"
        tmp_header_dict["filterable"] = false
        tmp_header_dict["sortable"] = false
        tmp_header_dict["width"] = "150"
        tmp_header_dict["renderCell"] = (params) => {return <Button className="action-button" disabled={params.row[`${params.field.replaceAll("-detail", "")}-status`] === "未填寫"} onClick={handleActionClick(params, "detail")}>查看</Button>}

        initDataGridColumns.push(tmp_header_dict)

        tmp_header_dict = {}
        tmp_header_dict["field"] = `${form.form_id}-doctor-reply`
        tmp_header_dict["headerName"] = "醫師回覆"
        tmp_header_dict["filterable"] = false
        tmp_header_dict["sortable"] = false
        tmp_header_dict["width"] = "150"
        tmp_header_dict["renderCell"] = (params) => {return <Button className="action-button" disabled={params.field.replaceAll(authContext.role !== "doctor" || "-doctor-reply", "").replaceAll("-", "") !== "21131f306f8d11ed89300a6fe67abc63" || params.row[`${params.field.replaceAll("-doctor-reply", "")}-status`] === "未填寫" || params.row.is_finish === "已結案"} onClick={handleActionClick(params, "doctor-reply")}>回覆</Button>}

        initDataGridColumns.push(tmp_header_dict)

        let tmp_group_dict = {}
        tmp_group_dict["groupId"] = form.form_id
        tmp_group_dict["headerName"] = form.form_title
        tmp_group_dict["headerClassName"] = "column-group"
        tmp_group_dict["children"] = [{field: `${form.form_id}-status`}, {field: `${form.form_id}-detail`}, {field: `${form.form_id}-doctor-reply`}]

        initDataGridColumnGroup.push(tmp_group_dict)

        dashboardContext.setFormLinks((preFormLinks) => (preFormLinks + "\n" + `${form.form_title}: \n${process.env.REACT_APP_HOST}/form/${authContext.userName}/${dashboardContext.project}/${form.form_id.replaceAll("-", "")}/false`))

      })

      setDataGridColumns(initDataGridColumns)
      setDataGridColumnGroup(initDataGridColumnGroup)

    }

    setDataGridColumns([])
    setDataGridColumnGroup([])

    getForms()

  }, [dashboardContext.project])

  useEffect(() => {

    async function getEmployees() {

      setIsLoading(true)
      formContext.setIsFilterReadOnly(true)

      setSortItem(null)
      setSortType(null)

      if (formContext.selectedCompanies.length === 0 || formContext.selectedDepartments.length === 0) {
        setEmployees([])
        setIsLoading(false)
        formContext.setIsFilterReadOnly(false)
      } else {
        try {
          var rawPaginatedResponse = await FormDashboardAPI.getEmployee(authContext.userName, dashboardContext.project, formContext.selectedCompanies, formContext.selectedDepartments, filterItems, sortItem, sortType, pageSize, page*pageSize)
        } catch (error) {
          if (error.response.status === 401) {
            navigate("/login", { replace: true})
          } else {
            alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
            setIsLoading(false)
          }
        }

        setEmployees([])
        setEmployees((prevEmployees) => [...prevEmployees, ...rawPaginatedResponse.results])
        setEmployeesCount(rawPaginatedResponse.count)

        setIsLoading(false)
        formContext.setIsFilterReadOnly(false)
      }
    }

    dashboardContext.setIsFinish(false)
    getEmployees()

  }, [formContext.selectedCompanies, formContext.selectedDepartments, dashboardContext.isReply, dashboardContext.isFinish])

  const handleFilterChange = async (filterModel) => {
    setFilterItems(filterModel.items)
    setIsLoading(true)

    try {
      var rawPaginatedResponse = await FormDashboardAPI.getEmployee(authContext.userName, dashboardContext.project, formContext.selectedCompanies, formContext.selectedDepartments, filterModel.items, sortItem, sortType, pageSize, page*pageSize)
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/login", { replace: true})
      } else {
        alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
        setIsLoading(false)
      }
    }

    setEmployees([])
    setEmployees((prevEmployees) => [...prevEmployees, ...rawPaginatedResponse.results])
    setEmployeesCount(rawPaginatedResponse.count)

    setIsLoading(false)
  }

  const handleSortChange = async (sortModel) => {
    setSortItem(sortModel[0].field)
    setSortType(sortModel[0].sort)
    setIsLoading(true)

    try {
      var rawPaginatedResponse = await FormDashboardAPI.getEmployee(authContext.userName, dashboardContext.project, formContext.selectedCompanies, formContext.selectedDepartments, filterItems, sortModel[0].field, sortModel[0].sort, pageSize, page*pageSize)
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/login", { replace: true})
      } else {
        alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
        setIsLoading(false)
      }
    }

    setEmployees([])
    setEmployees((prevEmployees) => [...prevEmployees, ...rawPaginatedResponse.results])

    setIsLoading(false)
  }

  const handlePageChange = async (newPage) => {
    setPage(newPage)
    setIsLoading(true)

    try {
      var rawPaginatedResponse = await FormDashboardAPI.getEmployee(authContext.userName, dashboardContext.project, formContext.selectedCompanies, formContext.selectedDepartments, filterItems, sortItem, sortType, pageSize, newPage*pageSize)
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/login", { replace: true})
      } else {
        alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
        setIsLoading(false)
      }
    }

    setEmployees([])
    setEmployees((prevEmployees) => [...prevEmployees, ...rawPaginatedResponse.results])

    setIsLoading(false)
  }

  const handlePageSizeChange = async (newPageSize) => {
    setPageSize(newPageSize)
    setPage(0)
    setIsLoading(true)

    try {
      var rawPaginatedResponse = await FormDashboardAPI.getEmployee(authContext.userName, dashboardContext.project, formContext.selectedCompanies, formContext.selectedDepartments, filterItems, sortItem, sortType, newPageSize, page*newPageSize)
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/login", { replace: true})
      } else {
        alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
        setIsLoading(false)
      }
    }

    setEmployees([])
    setEmployees((prevEmployees) => [...prevEmployees, ...rawPaginatedResponse.results])

    setIsLoading(false)
  }

  const handleActionClick = (params, action) => async (event) => {
    try {

      dashboardContext.setAction(action)

      let response = await GeneralDashboardAPI.getUserInfo(authContext.userName, "form", action, btoa(params.row.id_number), params.row.email)
      
      if (response.external_id) {
        sessionStorage.setItem('external_id', atob(response.external_id))
      } else {
        sessionStorage.setItem('external_id', null)
      }

      if (action === "message") {

        dashboardContext.setMessageType("independent")
        dashboardContext.setMessageText("")
        dashboardContext.setUserInfo({})
        dashboardContext.setUserInfo((prevUserInfo) => ({...prevUserInfo, ...{username: params.row.name, email: params.row.email}}))
        dashboardContext.setOpenModal(true)

      } else if (action === "record") {

        dashboardContext.setUserInfo({})
        dashboardContext.setUserInfo((prevUserInfo) => ({...prevUserInfo, ...{name: params.row.name, employeeNumber: params.row.employee_number, idNumber: params.row.id_number, company: params.row.company, department: params.row.department}}))
        dashboardContext.setOpenModal(true)

      } else if (action === "finish") {

        if (window.confirm(`是否確認結束此人員案件 : ${params.row.name} ?`)) {
          const formIDs = Object.keys(params.row).filter(key => key.endsWith("status")).map(key => key.replaceAll("-status", "").replaceAll("-", ""))
          await FormDashboardAPI.finishProject({belong: authContext.userName, action: action, project: dashboardContext.project, formIDs: formIDs, idNumber: btoa(params.row.id_number)})
          dashboardContext.setIsFinish(true)
        }

      } else if (action === "detail") {

        dashboardContext.setFormID(params.field.replaceAll("-detail", "").replaceAll("-", ""))
        dashboardContext.setOpenModal(true)

      } else if (action === "doctor-reply") {

        dashboardContext.setFormID(params.field.replaceAll("-doctor-reply", "").replaceAll("-", ""))
        dashboardContext.setOpenModal(true)

      }
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/login", { replace: true})
      } else if (error.response.status === 400) {
        alert("此人員無提供身分證字號，\n請重新操作一次，或請聯絡客服人員")
      } else if (error.response.status === 404) {
        if (action === "message") {
          alert("此人員尚未綁定LINE OA，且無提供E-Mail")
        } else if (action === "record") {
          alert("此人員尚未無填寫紀錄")
        }
      } else {
        alert("執行此動作失敗，\n請重新操作一次，或請聯絡客服人員")
      }
    }
  }

  return (
    <div align="center">
      <Box sx={{ height: "500px", width: "100%" }}>
        <CustomizedDataGrid
          experimentalFeatures={{columnGrouping: true}}
          columns={dataGridColumns}
          columnGroupingModel={dataGridColumnGroup}
          rows={employees}
          components={{
            Toolbar: CustomizedToolbar,
          }}
          componentsProps={{filterPanel: {linkOperators: [GridLinkOperator.And]}}}
          pagination
          page={page}
          pageSize={pageSize}
          rowCount={employeesCount}
          filterMode="server"
          sortingMode="server"
          paginationMode="server"
          rowsPerPageOptions={[25, 50, 100, employeesCount]}
          onFilterModelChange={handleFilterChange}
          onSortModelChange={handleSortChange}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          localeText={zHTWLocale}
          loading = {isLoading}
          sx={{ width: "90%" }}
          checkboxSelection
          disableSelectionOnClick
        />
      </Box>
    </div>
  )
}