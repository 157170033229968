import { useEffect, useContext } from "react"
import { useParams } from 'react-router-dom'
import { Container, CircularProgress } from '@mui/material'
import { LiffContext } from './LiffUsage'
import Footer from "../components/common/Footer"
import NormalUserVerification from "../components/account/NormalUserVerification"

export default function UserVerification() {

  const urlParams = useParams()
  const liffContext = useContext(LiffContext)

  useEffect(() => {

    if (liffContext.isLogin) {
      return
    } else {
      liffContext.initLIFF(process.env[`REACT_APP_${urlParams.belong.toUpperCase()}_LIFF_VERIFICATION`])
    }

  }, [])

  return (
    <Container maxWidth="100%" disableGutters>
      { liffContext.isLogin  && liffContext.isFriend ? 
        <NormalUserVerification belong={urlParams.belong} /> :
        <div align="center"><br/><CircularProgress className="loading-spinner" /></div>
      }
      <Footer width="200%" topMargin="10%" logoTopMargin="10px" />
    </Container>
  )
}