import { useState, useContext } from 'react'
import { Link, Outlet } from 'react-router-dom';
import { Drawer, List, ListItem, Box, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles'
import { Logout, AccountCircle, AssignmentOutlined, ListAlt, ShoppingCartOutlined, TurnedIn, PollOutlined, FactCheckOutlined } from '@mui/icons-material'
import dashboard_bar from "../../images/dashboard_bar.png"
import dashboard_heart from "../../images/dashboard_heart.png"
import { AuthContext } from '../../AppRoutes'
import { logout } from '../../api/AccountAPI'

const CustomizedLink = styled(Link)({
  textDecoration: "none", 
  color: "#5AA7A7",
  '&:hover': {
    color: "#A8DEE0",
  }
})

const CustomizedListItem = styled(ListItem)({
  justifyContent: "center"
})

const CustomizedButton = styled(Button)({
  width: "100%",
  fontSize: "13px",
  backgroundColor: "#FFFFFF",
  color: "#5AA7A7",
  '&:hover': {
    backgroundColor: "#FFFFFF",
    color: "#5AA7A7",
    opacity: "50%"
  },
})

export default function Header() {

  let authContext = useContext(AuthContext)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenu = (event) => {
      setAnchorEl(event.currentTarget)
    }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Box>
        <Drawer
          sx={{
            width: "15%",
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: "15%",
              background: `url(${dashboard_heart}), url(${dashboard_bar})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionY: "bottom, center",
              backgroundSize: "contain, cover",
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          // anchor="left"
        >
          <div align="center">
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                sx={{backgroundColor: "#5AA7A7", marginTop: "10%", marginBottom: "3%", borderRadius: "10rem", width: "120px", height: "40px"}}
              >
              <AccountCircle sx={{color: "#FFFFFF"}} /><span style={{ fontSize: "10px", color: "#FFFFFF"}}>管理員 {authContext.userName}</span>
            </IconButton>
            <List>
              <CustomizedListItem>
                <CustomizedButton variant="contained" component={Link} to="/" state={{target: "reports"}}><AssignmentOutlined sx={{fontSize: "20px", marginRight: "11px"}}/>健檢報告總覽</CustomizedButton>
              </CustomizedListItem>
              <CustomizedListItem>
                <CustomizedButton variant="contained" component={Link} to="/" state={{target: "forms"}}><ListAlt sx={{fontSize: "20px", marginRight: "11px"}}/>勞工四大計畫</CustomizedButton>
              </CustomizedListItem>
              <CustomizedListItem>
                <CustomizedButton disabled variant="contained"><ShoppingCartOutlined sx={{fontSize: "20px", marginRight: "11px"}}/>商品服務上架</CustomizedButton>
              </CustomizedListItem>
              <CustomizedListItem>
                <CustomizedButton disabled variant="contained"><TurnedIn sx={{fontSize: "20px", marginRight: "11px"}}/>使用足跡追蹤</CustomizedButton>
              </CustomizedListItem>
              <CustomizedListItem>
                <CustomizedButton disabled variant="contained"><PollOutlined sx={{fontSize: "20px", marginRight: "11px"}}/>報告統計分析</CustomizedButton>
              </CustomizedListItem>
              <CustomizedListItem>
                <CustomizedButton disabled variant="contained"><FactCheckOutlined sx={{fontSize: "20px", marginRight: "11px"}}/>健康行動建議</CustomizedButton>
              </CustomizedListItem>
            </List>
          </div>
        </Drawer>
      </Box>
      <Box sx={{ textAlign: "right", marginTop: "1%"}}>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem><CustomizedLink to="/login" onClick={logout} state={{from: {pathname: "/"}}}><Logout /> 登出</CustomizedLink></MenuItem>
        </Menu>
      </Box>  
      <Outlet />
    </div>
  )
}