import { useState, useEffect, useContext, createContext } from "react"
import { useNavigate } from 'react-router-dom'
import { TextField, Autocomplete, Typography, Dialog, Box, FormGroup, FormControlLabel, Switch, Button, CircularProgress} from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import { Apartment, PersonAdd, People } from '@mui/icons-material'
import { AuthContext } from '../../AppRoutes'
import { DashboardContext } from '../../routes/NationalForms'
import logo from '../../images/logo.png'
import FormFilter from './FormFilter'
import FormEmployeeTable from './FormEmployeeTable'
import FormRecordTable from './FormRecordTable'
import NormalUserNationalForm from "../employee/NormalUserNationalForm"
import MessageEditorModal from './MessageEditorModal'
import * as FormDashboardAPI from '../../api/FormDashboardAPI'

export const FormContext = createContext()

const FormProvider = ({children}) => {
  let [selectedCompanies, setSelectedCompanies] = useState([])
  let [selectedDepartments, setSelectedDepartments] = useState([])
  let [departmentOptions, setDepartmentOptions] = useState([])
  let [isFilterReadOnly, setIsFilterReadOnly] = useState(false)

  let value = { selectedCompanies, setSelectedCompanies, selectedDepartments, setSelectedDepartments, 
                departmentOptions, setDepartmentOptions, isFilterReadOnly, setIsFilterReadOnly }

  return (
    <FormContext.Provider value={value}>{children}</FormContext.Provider>
  )
}

const NotifyButton = styled(Button)({
  width: "100px",
  color: '#474747',
  backgroundColor: "#C0E29D",
  border: '1px #C0E29D',
  alignSelf: "baseline",
  boxShadow: "5px 5px 5px rgb(0 0 0 / 10%)",
  '&:hover': {
    backgroundColor: "#B3DC89",
    border: '1px #B3DC89'
  },
})

const FinishButton = styled(Button)({
  width: "100px",
  color: '#474747',
  backgroundColor: "#C0E29D",
  border: '1px #C0E29D',
  marginLeft: "5%",
  alignSelf: "baseline",
  boxShadow: "5px 5px 5px rgb(0 0 0 / 10%)",
  '&:hover': {
    backgroundColor: "#B3DC89",
    border: '1px #B3DC89'
  },
})

const DeleteButton = styled(Button)({
  width: "100px",
  color: '#474747',
  backgroundColor: "#C0E29D",
  border: '1px #C0E29D',
  marginLeft: "5%",
  alignSelf: "baseline",
  boxShadow: "5px 5px 5px rgb(0 0 0 / 10%)",
  '&:hover': {
    backgroundColor: "#B3DC89",
    border: '1px #B3DC89'
  },
})

const SaveButton = styled(Button)({
  color: '#474747',
  backgroundColor: "#C0E29D",
  border: '1px #C0E29D',
  marginTop: "5%",
  '&:hover': {
    backgroundColor: "#B3DC89",
    border: '1px #B3DC89'
  },
})

const CustomizedTextField = styled(TextField)({
  '& label': {
    color: '#5AA7A7',
  },
  '& label.Mui-focused': {
    color: '#5AA7A7',
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid #5AA7A7',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid #5AA7A7',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #5AA7A7'
    },
    '&:hover fieldset': {
      border: '1px solid #5AA7A7'
    },
    '&.Mui-focused fieldset' : {
      border: '1px solid #5AA7A7'
    }
  }
})

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: "#A7D676",
    '&:hover': {
      backgroundColor: alpha("#A7D676", theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: "#A7D676",
  },
}))

export default function FormDashboard() {
  
  const authContext = useContext(AuthContext)
  const dashboardContext = useContext(DashboardContext)

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const [showTable, setshowTable] = useState(false)
  const [isNotify, setIsNotify] = useState(false)
  const [notifiedFrenq, setNotifiedFrenq] = useState(null)
  const [remind, setRemind] = useState("")
  const [projects, setProjects] = useState([])
  const [project, setProject] = useState("")
  const [companies, setCompanies] = useState([])

  useEffect(() => {

    document.body.style.background = "unset"

    async function getProjects() {
      try {
        var projectResponse = await FormDashboardAPI.getProject(authContext.userName, null, false)
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login", {replace: true})
        } else {
          alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
          setIsLoading(false)
        }
      }

      setProjects([])
      setProjects((prevProjects) => [...prevProjects, ...projectResponse, ...["+新增問卷專案"]])

      if (projectResponse.length > 0) {
        let targetProject = projectResponse[0]
        let projectStatus = await FormDashboardAPI.getProject(authContext.userName, targetProject, true)
        setProject(targetProject)
        setIsFinish(projectStatus.is_finish)
        setIsNotify(projectStatus.is_notify)
        setNotifiedFrenq(projectStatus.dispatch_freq)
      } else {
        setIsLoading(false)
        setRemind("目前尚無資料，請新增問卷專案")
      }
    }

    setIsLoading(true)
    getProjects()

  }, [])

  useEffect(() => {

    async function getCompanies() {

      setshowTable(false)
      setCompanies([])

      try {
        var companyResponse = await FormDashboardAPI.getCompany(authContext.userName, project)
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login", {replace: true})
        } else {
          alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
          setIsLoading(false)
        }
      }

      setCompanies((prevCompanies) => [...prevCompanies, ...companyResponse])
      setIsLoading(false)
      setshowTable(true)
    }

    if (project.length !== 0) {
      setIsLoading(true)
      dashboardContext.setProject(project)
      getCompanies()
    }

  }, [project])

  const handleOnProjectChange = async (event, values) => {
    setIsFinish(false)
    setIsNotify(false)
    setNotifiedFrenq(null)

    if (values === "+新增問卷專案") {
      if (authContext.role === "doctor") {
        alert("您無權限執行此動作")
      } else {
        navigate("/forms", {replace: true})
      }
    } else {
      let projectStatus = await FormDashboardAPI.getProject(authContext.userName, values, true)
      setProject(values)
      setIsFinish(projectStatus.is_finish)
      setIsNotify(projectStatus.is_notify)
      setNotifiedFrenq(projectStatus.dispatch_freq)
    }
  }

  const handleNotify = async (event) => {
    dashboardContext.setAction("notification")
    dashboardContext.setOpenModal(true)
  }

  const handleSave = async (event) => {
    event.preventDefault()

    try {
      await FormDashboardAPI.updateNotification({belong: authContext.userName, project: dashboardContext.project, isNotify: isNotify, dispatchFreq: notifiedFrenq})
      alert("設定提醒成功!")
    } catch (error) {
      if (error.response.status !== 204) {
        alert("設定提醒失敗，\n請重新操作一次，或請聯絡客服人員")
        return
      }
    }
  }

  const handleFinish = async (event) => {
    if (window.confirm(`是否確認結束問卷專案 : ${project} ?`)) {
      try {
        await FormDashboardAPI.finishProject({belong: authContext.userName, action: "finish", project: dashboardContext.project, formIDs: null, idNumber: null})
        setIsFinish(true)
        dashboardContext.setIsFinish(true)

      } catch (error) {
        if (error.response.status !== 204) {
          alert("結束問卷專案失敗，\n請重新操作一次，或請聯絡客服人員")
          return
        }
      }
    }
  }

  const handleDelete = async (event) => {
    if (window.confirm(`是否確認刪除問卷專案 : ${project} ?`)) {
      setIsLoading(true)
      setshowTable(false)
      try {

        await FormDashboardAPI.deleteProject(authContext.userName, project)
        let projectResponse = await FormDashboardAPI.getProject(authContext.userName, null, false)

        setIsFinish(false)
        setIsNotify(false)
        setNotifiedFrenq(null)
        
        setProjects([])
        setProjects((prevProjects) => [...prevProjects, ...projectResponse, ...["+新增問卷專案"]])

        if (projectResponse.length > 0) {
          let targetProject = projectResponse[0]
          let projectStatus = await FormDashboardAPI.getProject(authContext.userName, targetProject, true)
          setProject(targetProject)
          setIsFinish(projectStatus.is_finish)
          setIsNotify(projectStatus.is_notify)
          setNotifiedFrenq(projectStatus.dispatch_freq)
        } else {
          setProject("")
          setIsLoading(false)
          setRemind("目前尚無資料，請新增問卷專案")
          setshowTable(false)
        }
        alert("刪除問卷專案成功!")
      } catch (error) {
        if (error.response.status !== 204) {
          alert("刪除問卷專案失敗，\n請重新操作一次，或請聯絡客服人員")
          setIsLoading(false)
          setshowTable(true)
          return
        }
      }
      setIsLoading(false)
    }
  }

  return (
    <div className="dashboard" align="left" style={{marginTop: "1.5%", marginRight: "1%"}}>
      <Box component="div" sx={{display: "flex"}}>
        <Box
          component="img"
          sx={{
            height: "40px",
            width: "120px",
            marginRight: "2%",
            marginTop: "-4px",
          }}
          alt="logo"
          src={logo}
        />
        <Typography component="div" sx={{color: "#474747", fontWeight: "bold", fontSize: "35px", marginTop: "-3px"}}>Labor Survey</Typography>
        <Autocomplete
          sx={{ width: "30%", marginLeft: "25px", marginTop: "-12px"}}
          id="form-group-selector"
          options={projects}
          value={project}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <CustomizedTextField
              {...params}
              variant="standard"
              label="請選擇或新增問卷專案"
            />
          )}
          onChange={handleOnProjectChange}
          isOptionEqualToValue={(option, value) => (option.value === value.value) || (value.value === "")}
          disableClearable
        />
        { project.length !== 0 ?
          <div style={{display: "flex", marginLeft: "3%"}}>
            <NotifyButton variant="outlined" onClick={handleNotify} disabled={isFinish || authContext.role === "doctor"}>提醒設定</NotifyButton>
            <FinishButton variant="outlined" onClick={handleFinish} disabled={isFinish || authContext.role === "doctor"}>結束專案</FinishButton>
            <DeleteButton variant="outlined" onClick={handleDelete} disabled={authContext.role === "doctor"}>刪除專案</DeleteButton>
          </div> :
          <div></div>
        }
      </Box>
      { isLoading ? 
        <div align="center">
          <br/>
          <CircularProgress className="loading-spinner"/>
          <br/><span style={{color: "#5AA7A7"}}>載入中...</span>
        </div> : <div></div> 
      }
      { showTable ?
        <div>
          <FormProvider>
            <Box sx={{ boxShadow: 1, borderRadius: "10px"}}>
              <br/>
              <Typography component="div" sx={{marginLeft: "45px", fontWeight: "bold", color: "#474747"}}><Apartment sx={{marginBottom: "5px", marginRight: "5px"}}/>公司 Company</Typography>
              <FormFilter label="公司" items={companies} />
              <br/>
              <Typography component="div" sx={{marginLeft: "45px", fontWeight: "bold", color: "#474747"}}><PersonAdd sx={{marginBottom: "5px", marginRight: "5px"}}/>部門 Department</Typography>
              <FormFilter label="部門" />
              <br/>
            </Box>
            <br/>
            <Box sx={{ boxShadow: 1, borderRadius: "10px"}}>
              { dashboardContext.action === "message" ?
                <MessageEditorModal target="form" /> :
                <Dialog 
                  open={dashboardContext.openModal} 
                  onClose={() => {dashboardContext.setOpenModal(false)}} 
                  fullWidth 
                  maxWidth={dashboardContext.action === "notification" ? "sm" : "lg"}
                  componentsProps={{backdrop: {style: {backgroundColor: "rgba(0, 0, 0, 0.2)"}}}}
                  >
                  { dashboardContext.action === "record" ?
                    <Box>
                      <Button className="close-button" sx={{fontSize: "20px", marginLeft: "90%"}} onClick={() => {dashboardContext.setOpenModal(false)}}>X</Button>
                      <FormRecordTable/>
                    </Box> : dashboardContext.action === "detail" ?
                    <Box>
                      <Button className="close-button" sx={{fontSize: "20px", marginLeft: "90%"}} onClick={() => {dashboardContext.setOpenModal(false)}}>X</Button>
                      <NormalUserNationalForm isDashboard={true} belong={authContext.userName} formProject={dashboardContext.project} formTitleID={dashboardContext.formID} readOnly={true} />
                    </Box> : dashboardContext.action === "doctor-reply" ?
                    <Box>
                      <Button className="close-button" sx={{fontSize: "20px", marginLeft: "90%"}} onClick={() => {dashboardContext.setOpenModal(false)}}>X</Button>
                      <NormalUserNationalForm isDashboard={true} belong={authContext.userName} formProject={dashboardContext.project} formTitleID={dashboardContext.formID} readOnly={false} />
                    </Box> :
                    <Box component="form" onSubmit={handleSave}>
                      <Button className="close-button" sx={{fontSize: "20px", marginLeft: "90%"}} onClick={() => {dashboardContext.setOpenModal(false)}}>X</Button>
                      <FormGroup sx={{alignItems: "center", margin: "5%"}}>
                        <FormControlLabel control={<GreenSwitch />} label="開啟提醒通知" checked={isNotify} onChange={(event) => setIsNotify(!isNotify)} />
                        <CustomizedTextField 
                          type="number" 
                          label="提醒頻率(天)" 
                          variant="standard" 
                          margin="normal"
                          inputProps={{min: 1}}
                          value={notifiedFrenq}
                          required={isNotify ? true : false}
                          onChange={(event) => setNotifiedFrenq(event.target.value)}
                        />
                        { isNotify ?
                          <Typography variant="h7" sx={{color: "#878787", fontWeight: "bold", marginTop: "5%"}}>每 {notifiedFrenq} 天於上午 10 點進行提醒</Typography> :
                          <div></div>
                        }
                        <SaveButton type="submit" variant="outlined">SAVE</SaveButton>
                      </FormGroup>
                    </Box>
                  }
                </Dialog>
              }
              <Box sx={{ flexGrow: 1, marginTop: "10px"}}>
                <Typography component="div" sx={{marginLeft: "45px", fontWeight: "bold", color: "#474747"}}><People sx={{marginBottom: "5px", marginRight: "5px"}}/>人員 Employee</Typography>
                <FormEmployeeTable />
              </Box>
            </Box>
          </FormProvider>
        </div> : 
        <div align="center">
          <Typography variant="h5" sx={{color: "#474747", fontWeight: "bold", marginTop: "20%"}}>{remind}</Typography>
        </div> 
      }
    </div>
  )
}