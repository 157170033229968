import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../../AppRoutes';

const AuthValidation = ({redirectPath='/login'}) => {
  let authContext = useContext(AuthContext)
  const location = useLocation()

  if (!authContext.isLogin) {
    return <Navigate to={redirectPath} state={{from: location}} replace />
  } else {
  	return <Outlet />
  }
}

export default AuthValidation