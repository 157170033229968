import { useState, createContext } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from './App'
import Login from './routes/Login'
import UploadReports from './routes/UploadReports'
import UploadFormProject from './routes/UploadFormProject'
import LiffUsage from './routes/LiffUsage'
import UserVerification from './routes/UserVerification'
import UserBind from './routes/UserBind'
import UserSuccessEntry from './routes/UserSuccessEntry'
import UserMenu from './routes/UserMenu'
import UserReport from './routes/UserReport'
import UserTask from './routes/UserTask'
import UserNationalForm from './routes/UserNationalForm'
import AuthValidation from './components/account/AuthValidation'
import * as AccountAPI from './api/AccountAPI'

export const AuthContext = createContext()

const AuthProvider = ({children}) => {
  let [isLogin, setIsLogin] = useState(false)
  let [userName, setUserName] = useState(null)
  let [role, setRole] = useState(null)

  const login = (userInfo) => {
    return AccountAPI.login(userInfo).then(res => {
      sessionStorage.setItem('refresh_token', res.refresh_token)
      sessionStorage.setItem('access_token', res.access_token)
      setIsLogin(true)
      setUserName(userInfo.username)

      if (userInfo.username.endsWith("doctor")) {
        setRole("doctor")
      } else {
        setRole("admin")
      }
      
    })
  }

  const logout = () => {
    return AccountAPI.logout(() => {
      setIsLogin(false)
      sessionStorage.removeItem('refresh_token')
      sessionStorage.removeItem('access_token')
    })
  }

  let value = { userName, role, isLogin, login, logout }

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  )
}

export const AppRoutes = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<AuthValidation />}>
            <Route path="/" element={<App />} />
            <Route path="reports" element={<UploadReports />} />
            <Route path="forms" element={<UploadFormProject />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route element={<LiffUsage />}>
            <Route path="verification/:belong" element={<UserVerification />} />
            <Route path="bind" element={<UserBind />} />
            <Route path="success/:belong" element={<UserSuccessEntry />} />
            <Route path="menu/:belong" element={<UserMenu />} />
            <Route path="report/:belong" element={<UserReport />} />
            <Route path="task/:belong" element={<UserTask />} />
            <Route path="form/:belong" element={<UserNationalForm />} />
            <Route path="form/:belong/:formProject/:formTitleID/:readOnly" element={<UserNationalForm />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}
