export const categoryEnDict = {patient: '個人資料', general: '一般檢查', physical: '理學檢查', eyeent: '眼科及耳鼻喉科檢查', 
                               urology: '尿液檢查', kidney: '腎機能檢查', hematology: '血液系統檢查', digestion: '消化系統檢查', 
                               cardiovascular: '心血管及代謝系統檢查', tumor: '腫瘤檢查', sona: '超音波檢查', xray: 'X光檢查', other: '其他檢查'}

export const categoryCnDict = {個人資料: 'patient', 一般檢查: 'general', 理學檢查: 'physical', 眼科及耳鼻喉科檢查: 'eyeent', 
                               尿液檢查: 'urology', 腎機能檢查: 'kidney', 血液系統檢查: 'hematology', 消化系統檢查: 'digestion', 
                               心血管及代謝系統檢查: 'cardiovascular', 腫瘤檢查: 'tumor', 超音波檢查: 'sona', X光檢查: 'xray', 其他檢查: 'other'}


export const inspectGroupItemDict = {
                              "general": ["bmi", "sbp", "dbp", "pulse", "waistline"],
                              "eyeent": ["right_eye", "left_eye", "right_eye_correction", "left_eye_correction", "right_eye_pressure", "left_eye_pressure", "right_ear", "left_ear", "color_vision"],
                              "physical": ["head_neck", "lung", "heart", "digestion", "nerve", "skeletal_muscle", "skin", "sleep_profile", "sleep_time", "medical_history", "chief_complaint", "social_habit", "work_history"],
                              "urology": ["u_leukocyte", "u_nitrite", "u_urobilinogen", "u_protein", "u_ph", "u_ob", "u_spgr", "u_ketone", "u_bilirubin", "u_glucose", "fobt", "other"],
                              "hematology": ["wbc", "rbc", "hb", "hct", "mcv", "mch", "mchc", "platelet", "rdw_cv", "pdw", "mpv", "p_lcr", "neut", "lym", "mono", "eosin", "baso", "neut_count", "lym_count", "mono_count", "eosin_count", "baso_count", "myelo", "pro", "blast", "band", "metamyelo", "a_lym", "nrbc"],
                              "digestion": ["got", "gpt", "r_gtp", "alk_p", "t_p", "albumin", "globulin", "a_g"],
                              "kidney": ["bun", "creatinine", "egfr", "uric_acid"],
                              "cardiovascular": ["tg", "t_cholesterol", "hdl", "ldl", "risk_factor", "sugar_ac", "sugar_random", "ldh", "cpk", "free_t4", "tsh"],
                              "tumor": ["afp", "cea", "ca_153", "psa"],
                              "sona": ["abd_sona", "breast_sona", "gyn_sona", "prostate_sona", "thyroid_sona", "carotid_a_sona"],
                              "xray": ["bmd", "chest_x_ray"],
                              "other": ["ekg"]
                            }

export const inspectItemGroupDict = {
                                "username": "個人資料", "employee_number": "個人資料", "gender": "個人資料", "company": "個人資料", "department": "個人資料", "unit": "個人資料", "position": "個人資料",
                                "age": "一般檢查", "height": "一般檢查", "weight": "一般檢查", "bmi": "一般檢查", "sbp": "一般檢查", "dbp": "一般檢查", "pulse": "一般檢查", "waistline": "一般檢查",
                                "right_eye": "眼科及耳鼻喉科檢查", "left_eye": "眼科及耳鼻喉科檢查", "right_eye_correction": "眼科及耳鼻喉科檢查", "left_eye_correction": "眼科及耳鼻喉科檢查",
                                "right_eye_pressure": "眼科及耳鼻喉科檢查", "left_eye_pressure": "眼科及耳鼻喉科檢查", "right_ear": "眼科及耳鼻喉科檢查", "left_ear": "眼科及耳鼻喉科檢查", "color_vision": "眼科及耳鼻喉科檢查",
                                "head_neck": "理學檢查", "lung": "理學檢查", "heart": "理學檢查", "digestion": "理學檢查", "nerve": "理學檢查", "skeletal_muscle": "理學檢查", "skin": "理學檢查", "sleep_profile": "理學檢查",
                                "sleep_time": "理學檢查", "medical_history": "理學檢查", "chief_complaint": "理學檢查", "social_habit": "理學檢查", "work_history": "理學檢查",
                                "u_leukocyte": "尿液檢查", "u_nitrite": "尿液檢查", "u_urobilinogen": "尿液檢查", "u_protein": "尿液檢查", "u_ph": "尿液檢查", "u_ob": "尿液檢查",
                                "u_spgr": "尿液檢查", "u_ketone": "尿液檢查", "u_bilirubin": "尿液檢查", "u_glucose": "尿液檢查", "fobt": "尿液檢查", "other": "尿液檢查",
                                "wbc": "血液系統檢查", "rbc": "血液系統檢查", "hb": "血液系統檢查", "hct": "血液系統檢查", "mcv": "血液系統檢查", "mch": "血液系統檢查",
                                "mchc": "血液系統檢查", "platelet": "血液系統檢查", "rdw_cv": "血液系統檢查", "pdw": "血液系統檢查", "mpv": "血液系統檢查", "p_lcr": "血液系統檢查",
                                "neut": "血液系統檢查", "lym": "血液系統檢查", "mono": "血液系統檢查", "eosin": "血液系統檢查", "baso": "血液系統檢查", "neut_count": "血液系統檢查",
                                "lym_count": "血液系統檢查", "mono_count": "血液系統檢查", "eosin_count": "血液系統檢查", "baso_count": "血液系統檢查", "myelo": "血液系統檢查", "pro": "血液系統檢查",
                                "blast": "血液系統檢查", "band": "血液系統檢查", "metamyelo": "血液系統檢查", "a_lym": "血液系統檢查", "nrbc": "血液系統檢查",
                                "got": "消化系統檢查", "gpt": "消化系統檢查", "r_gtp": "消化系統檢查", "alk_p": "消化系統檢查",
                                "t_p": "消化系統檢查", "albumin": "消化系統檢查", "globulin": "消化系統檢查", "a_g": "消化系統檢查",
                                "bun": "腎機能檢查", "creatinine": "腎機能檢查", "egfr": "腎機能檢查", "uric_acid": "腎機能檢查",
                                "tg": "心血管及代謝系統檢查", "t_cholesterol": "心血管及代謝系統檢查", "hdl": "心血管及代謝系統檢查", "ldl": "心血管及代謝系統檢查", "risk_factor": "心血管及代謝系統檢查",
                                "sugar_ac": "心血管及代謝系統檢查", "sugar_random": "心血管及代謝系統檢查", "ldh": "心血管及代謝系統檢查", "cpk": "心血管及代謝系統檢查", "free_t4": "心血管及代謝系統檢查", "tsh": "心血管及代謝系統檢查",
                                "afp": "腫瘤檢查", "cea": "腫瘤檢查", "ca_153": "腫瘤檢查", "psa": "腫瘤檢查", "abd_sona": "超音波檢查",
                                "breast_sona": "超音波檢查", "gyn_sona": "超音波檢查", "prostate_sona": "超音波檢查", "thyroid_sona": "超音波檢查", "carotid_a_sona": "超音波檢查",
                                "bmd": "X光檢查", "chest_x_ray": "X光檢查", "ekg": "其他檢查"
                               }


export const inspectItemsDict = {
                          "age": "年齡", "height": "身高", "weight": "體重", "bmi": "BMI", "sbp": "收縮壓", "dbp": "舒張壓", "pulse": "脈搏", "waistline": "腰圍",
                          "head_neck": "頭頸部", "lung": "呼吸系統", "heart": "心血管系統", "digestion": "消化系統", "nerve": "神經系統",
                          "skeletal_muscle": "肌肉骨骼", "skin": "皮膚", "sleep_profile": "睡眠概況", "sleep_time": "睡眠時間", "medical_history": "既往病史",
                          "chief_complaint": "自覺症狀", "social_habit": "生活習慣", "work_history": "作業經歷",
                          "right_eye": "裸視[右眼]", "left_eye": "裸視[左眼]", "right_eye_correction": "矯正[右眼]",
                          "left_eye_correction": "矯正[左眼]", "right_eye_pressure": "右眼壓", "left_eye_pressure": "左眼壓",
                          "right_ear": "右聽力", "left_ear": "左聽力", "color_vision": "辨色力", "u_leukocyte": "尿白血球", "u_nitrite": "亞硝酸鹽",
                          "u_urobilinogen": "尿膽素原", "u_protein": "尿蛋白", "u_ph": "尿酸鹼值", "u_ob": "尿潛血", "u_spgr": "尿比重", "u_ketone": "尿丙酮",
                          "u_bilirubin": "尿膽紅素", "u_glucose": "尿糖", "fobt": "免疫法糞便潛血反應", "other": "尿液-其他",
                          "bun": "尿素氮", "creatinine": "肌酸酐", "egfr": "腎絲球過濾率", "uric_acid": "尿酸", "wbc": "白血球計數", "rbc": "紅血球計數",
                          "hb": "血色素", "hct": "血球容積比", "mcv": "紅血球平均體積", "mch": "紅血球平均血紅素", "mchc": "平均血色素濃度", "platelet": "血小板計數",
                          "rdw_cv": "紅血球分佈寬度", "pdw": "血小板分佈寬度", "mpv": "平均血小板容積", "p_lcr": "大血小板血球率", "neut": "嗜中性白血球", "lym": "淋巴球",
                          "mono": "單核球", "eosin": "嗜酸性白血球", "baso": "嗜鹼性白血球", "neut_count": "嗜中性白血球計數",
                          "lym_count": "淋巴球計數", "mono_count": "單核球計數", "eosin_count": "嗜酸性白血球計數",
                          "baso_count": "嗜鹼性白血球計數", "myelo": "骨髓細胞", "pro": "前骨髓細胞", "blast": "母細胞",
                          "band": "帶狀型嗜中性白血球", "metamyelo": "後骨髓球", "a_lym": "非典型淋巴球", "nrbc": "有核紅血球",
                          "got": "草酸轉胺基酶", "gpt": "丙酮轉氨基酶", "r_gtp": "麩胺轉酸酶", "alk_p": "鹼性磷酸酶", "t_p": "總蛋白",
                          "albumin": "白蛋白", "globulin": "球蛋白", "a_g": "蛋白比值", "tg": "三酸甘油脂", "t_cholesterol": "總膽固醇", "hdl": "高密度脂蛋白",
                          "ldl": "低密度脂蛋白", "risk_factor": "血管硬化危險因子指數", "sugar_ac": "飯前血糖", "sugar_random": "隨機血糖",
                          "ldh": "乳酸脫氫酵素", "cpk": "肌酸磷酸激酶", "free_t4": "游離四碘甲狀腺素", "tsh": "甲狀腺促進素", "afp": "早期肝癌篩檢", "cea": "大腸癌胚抗原",
                          "ca_153": "乳房癌篩檢標記", "psa": "攝護腺特異抗原", "abd_sona": "腹部超音波", "breast_sona": "乳房超音波", "gyn_sona": "婦科超音波",
                          "prostate_sona": "攝護腺超音波", "thyroid_sona": "甲狀腺超音波", "carotid_a_sona": "頸動脈超音波", "bmd": "骨質密度", "chest_x_ray": "胸部正面X光", "ekg": "電腦心電圖"
                          }

export const inspectItemsFullDict = {
                                    "age": "年齡", "height": "身高", "weight": "體重", "bmi": "身體質量指數-BMI", "sbp": "收縮壓", "dbp": "舒張壓", "pulse": "脈搏", "waistline": "腰圍",
                                    "head_neck": "頭頸部", "lung": "呼吸系統", "heart": "心臟血管系統", "digestion": "消化系統", "nerve": "神經系統",
                                    "skeletal_muscle": "肌肉骨骼", "skin": "皮膚", "sleep_profile": "睡眠概況", "sleep_time": "睡眠時間", "medical_history": "既往病史",
                                    "chief_complaint": "自覺症狀", "social_habit": "生活習慣", "work_history": "作業經歷", "right_eye": "裸視-[右眼]", "left_eye": "裸視-[左眼]",
                                    "right_eye_correction": "矯正-[右眼]", "left_eye_correction": "矯正-[左眼]", "right_eye_pressure": "右眼壓", "left_eye_pressure": "左眼壓",
                                    "right_ear": "右聽力", "left_ear": "左聽力", "color_vision": "辨色力", "u_leukocyte": "尿白血球-U-Leukocyte", "u_nitrite": "亞硝酸鹽-U-Nitrite",
                                    "u_urobilinogen": "尿膽素原-U-Urobilinogen", "u_protein": "尿蛋白-U-Protein", "u_ph": "尿酸鹼值-U-pH", "u_ob": "尿潛血-U-OB", "u_spgr": "尿比重-U-Sp.gr",
                                    "u_ketone": "尿丙酮-U-Ketone", "u_bilirubin": "尿膽紅素-U-Bilirubin", "u_glucose": "尿糖-U-Glucose", "fobt": "免疫法糞便潛血反應-FOBT",
                                    "other": "尿液-其他-OTHER", "bun": "尿素氮-BUN", "creatinine": "肌酸酐-Creatinine", "egfr": "腎絲球過濾率-eGFR", "uric_acid": "尿酸-Uric-Acid",
                                    "wbc": "白血球計數-W.B.C", "rbc": "紅血球計數-R.B.C", "hb": "血色素-Hb", "hct": "血球容積比-Hct", "mcv": "紅血球平均體積-MCV",
                                    "mch": "紅血球平均血紅素-MCH", "mchc": "平均血色素濃度-MCHC", "platelet": "血小板計數-Platelet", "rdw_cv": "紅血球分佈寬度-RDW-CV", "pdw": "血小板分佈寬度-PDW",
                                    "mpv": "平均血小板容積-MPV", "p_lcr": "大血小板血球率-P-LCR", "neut": "嗜中性白血球-Neut%", "lym": "淋巴球-Lym%", "mono": "單核球-Mono%",
                                    "eosin": "嗜酸性白血球-Eosin%", "baso": "嗜鹼性白血球-Baso%", "neut_count": "嗜中性白血球計數-Neut#", "lym_count": "淋巴球計數-Lym#",
                                    "mono_count": "單核球計數-Mono#", "eosin_count": "嗜酸性白血球計數-Eosin#", "baso_count": "嗜鹼性白血球計數-Baso#", "myelo": "骨髓細胞-Myelo%",
                                    "pro": "前骨髓細胞-Pro%", "blast": "母細胞-Blast%", "band": "帶狀型嗜中性白血球-Band%", "metamyelo": "後骨髓球-Metamyelo%", "a_lym": "非典型淋巴球-A-Lym%",
                                    "nrbc": "有核紅血球-NRBC%", "got": "草酸轉胺基酶-GOT", "gpt": "丙酮轉氨基酶-GPT", "r_gtp": "麩胺轉酸酶-r-GTP", "alk_p": "鹼性磷酸酶-ALK-P",
                                    "t_p": "總蛋白-T-P", "albumin": "白蛋白-Albumin", "globulin": "球蛋白-Globulin", "a_g": "蛋白比值-A/G", "tg": "三酸甘油脂-TG",
                                    "t_cholesterol": "總膽固醇-T-Cholesterol", "hdl": "高密度脂蛋白-HDL", "ldl": "低密度脂蛋白-LDL", "risk_factor": "血管硬化危險因子指數-Risk-Factor",
                                    "sugar_ac": "飯前血糖-Sugar-AC", "sugar_random": "隨機血糖-Sugar-Random", "ldh": "乳酸脫氫酵素-LDH", "cpk": "肌酸磷酸激酶-CPK",
                                    "free_t4": "游離四碘甲狀腺素-Free-T4", "tsh": "甲狀腺促進素-TSH", "afp": "早期肝癌篩檢-AFP", "cea": "大腸癌胚抗原-CEA", "ca_153": "乳房癌篩檢標記-CA-153",
                                    "psa": "攝護腺特異抗原-PSA", "abd_sona": "腹部超音波-Abd-sona", "breast_sona": "乳房超音波-Breast-sona", "gyn_sona": "婦科超音波-Gyn-sona",
                                    "prostate_sona": "攝護腺超音波-Prostate-sona", "thyroid_sona": "甲狀腺超音波-Thyroid-sona", "carotid_a_sona": "頸動脈超音波-Carotid-A.sona",
                                    "bmd": "骨質密度", "chest_x_ray": "胸部正面X光-Chest-X-ray", "ekg": "電腦心電圖-EKG"
                                  }

export const inspectItemsReverseDict = {
                        "身體質量指數-BMI": "bmi", "收縮壓": "sbp", "舒張壓": "dbp", "脈搏": "pulse", "腰圍": "waistline", "頭頸部": "head_neck", "呼吸系統": "lung", 
                        "心臟血管系統": "heart", "消化系統": "digestion", "神經系統": "nerve", "肌肉骨骼": "skeletal_muscle", "皮膚": "skin", "睡眠概況": "sleep_profile", 
                        "睡眠時間": "sleep_time", "既往病史": "medical_history", "自覺症狀": "chief_complaint", "生活習慣": "social_habit", "作業經歷": "work_history", 
                        "裸視-[右眼]": "right_eye", "裸視-[左眼]": "left_eye", "矯正-[右眼]": "right_eye_correction", "矯正-[左眼]": "left_eye_correction", "右眼壓": "right_eye_pressure", 
                        "左眼壓": "left_eye_pressure", "右聽力": "right_ear", "左聽力": "left_ear", "辨色力": "color_vision", "尿白血球-U-Leukocyte": "u_leukocyte", "亞硝酸鹽-U-Nitrite": "u_nitrite", 
                        "尿膽素原-U-Urobilinogen": "u_urobilinogen", "尿蛋白-U-Protein": "u_protein", "尿酸鹼值-U-pH": "u_ph", "尿潛血-U-OB": "u_ob", "尿比重-U-Sp.gr": "u_spgr", 
                        "尿丙酮-U-Ketone": "u_ketone", "尿膽紅素-U-Bilirubin": "u_bilirubin", "尿糖-U-Glucose": "u_glucose", "免疫法糞便潛血反應-FOBT": "fobt", "尿液-其他-OTHER": "other", 
                        "尿素氮-BUN": "bun", "肌酸酐-Creatinine": "creatinine", "腎絲球過濾率-eGFR": "egfr", "尿酸-Uric-Acid": "uric_acid", "白血球計數-W.B.C": "wbc", "紅血球計數-R.B.C": "rbc", 
                        "血色素-Hb": "hb", "血球容積比-Hct": "hct", "紅血球平均體積-MCV": "mcv", "紅血球平均血紅素-MCH": "mch", "平均血色素濃度-MCHC": "mchc", "血小板計數-Platelet": "platelet", 
                        "紅血球分佈寬度-RDW-CV": "rdw_cv", "血小板分佈寬度-PDW": "pdw", "平均血小板容積-MPV": "mpv", "大血小板血球率-P-LCR": "p_lcr", "嗜中性白血球-Neut%": "neut", "淋巴球-Lym%": "lym", 
                        "單核球-Mono%": "mono", "嗜酸性白血球-Eosin%": "eosin", "嗜鹼性白血球-Baso%": "baso", "嗜中性白血球計數-Neut#": "neut_count", "淋巴球計數-Lym#": "lym_count", 
                        "單核球計數-Mono#": "mono_count", "嗜酸性白血球計數-Eosin#": "eosin_count", "嗜鹼性白血球計數-Baso#": "baso_count", "骨髓細胞-Myelo%": "myelo", "前骨髓細胞-Pro%": "pro", 
                        "母細胞-Blast%": "blast", "帶狀型嗜中性白血球-Band%": "band", "後骨髓球-Metamyelo%": "metamyelo", "非典型淋巴球-A-Lym%": "a_lym", "有核紅血球-NRBC%": "nrbc", 
                        "草酸轉胺基酶-GOT": "got", "丙酮轉氨基酶-GPT": "gpt", "麩胺轉酸酶-r-GTP": "r_gtp", "鹼性磷酸酶-ALK-P": "alk_p", "總蛋白-T-P": "t_p", "白蛋白-Albumin": "albumin", 
                        "球蛋白-Globulin": "globulin", "蛋白比值-A/G": "a_g", "三酸甘油脂-TG": "tg", "總膽固醇-T-Cholesterol": "t_cholesterol", "高密度脂蛋白-HDL": "hdl", "低密度脂蛋白-LDL": "ldl", 
                        "血管硬化危險因子指數-Risk-Factor": "risk_factor", "飯前血糖-Sugar-AC": "sugar_ac", "隨機血糖-Sugar-Random": "sugar_random", "乳酸脫氫酵素-LDH": "ldh", "肌酸磷酸激酶-CPK": "cpk", 
                        "游離四碘甲狀腺素-Free-T4": "free_t4", "甲狀腺促進素-TSH": "tsh", "早期肝癌篩檢-AFP": "afp", "大腸癌胚抗原-CEA": "cea", "乳房癌篩檢標記-CA-153": "ca_153", "攝護腺特異抗原-PSA": "psa", 
                        "腹部超音波-Abd-sona": "abd_sona", "乳房超音波-Breast-sona": "breast_sona", "婦科超音波-Gyn-sona": "gyn_sona", "攝護腺超音波-Prostate-sona": "prostate_sona", 
                        "甲狀腺超音波-Thyroid-sona": "thyroid_sona", "頸動脈超音波-Carotid-A.sona": "carotid_a_sona", "骨質密度": "bmd", "胸部正面X光-Chest-X-ray": "chest_x_ray", "電腦心電圖-EKG": "ekg"
                     }


export const columnsDictLatest = {patient: {健檢日期: 'inspect_date', '工號': 'employee_number', 姓名: 'username', 性別: 'gender', 
                                      廠別: 'company', 部門: 'department', 單位: 'unit', '職稱/座號': 'position'}, 
                                  general: {年齡: 'age', 身高: 'height', 體重: 'weight', 'BMI\n(18.5~23.9)': 'bmi', '收縮壓\n(<130)': 'sbp', '舒張壓\n(<85)': 'dbp', 
                                            '脈搏\n(60~100)': 'pulse', '腰圍\n(男:<90;女:<80)': 'waistline'},
                                  physical: {'頭頸部\n(無明顯異狀)': 'head_neck', '呼吸系統\n(無明顯異狀)': 'lung', '心血管系統\n(無明顯異狀)': 'heart', '消化系統\n(無明顯異狀)': 'digestion', '神經系統\n(無明顯異狀)': 'nerve', 
                                             '肌肉骨骼\n(無明顯異狀)': 'skeletal_muscle', '皮膚\n(無明顯異狀)': 'skin', '睡眠概況\n(正常)': 'sleep_profile', 睡眠時間: 'sleep_time', 
                                             既往病史: 'medical_history', 自覺症狀: 'chief_complaint', 生活習慣:'social_habit', 作業經歷: 'work_history'},
                                  eyeent: {'裸視[右眼]\n(0.8~2.0)': 'right_eye', '裸視[左眼]\n(0.8~2.0)': 'left_eye', '矯正[右眼]\n(0.9~2.0)': 'right_eye_correction', 
                                           '矯正[左眼]\n(0.9~2.0)': 'left_eye_correction', '右眼壓\n(10~21)': 'right_eye_pressure', '左眼壓\n(10~21)': 'left_eye_pressure', '右聽力\n(無異狀)': 'right_ear', '左聽力\n(無異狀)': 'left_ear', '辨色力\n(無異狀)': 'color_vision'},
                                  urology: {'尿白血球\n(-);(+/-)': 'u_leukocyte', '亞硝酸鹽\n(-);(+/-)': 'u_nitrite', '尿膽素原\n(<1.5 mg/dL)': 'u_urobilinogen', '尿蛋白\n(-);(+/-)': 'u_protein', 
                                            '尿酸鹼值\n(5~8)': 'u_ph', '尿潛血\n(-);(+/-)': 'u_ob', '尿比重\n(1.003~1.035)': 'u_spgr', '尿丙酮\n(-);(+/-)': 'u_ketone', '尿膽紅素\n(-);(+/-)': 'u_bilirubin', 
                                            '尿糖\n(-);(+/-)': 'u_glucose', '免疫法糞便潛血反應\n(0~99)': 'fobt', '尿液-其他\n(Not Found)': 'other'},
                                  kidney: {'尿素氮\n(7~25)': 'bun', '肌酸酐\n(男:0.7~1.3;女:0.6~1.2)': 'creatinine', '腎絲球過濾率\n(>60)': 'egfr', '尿酸\n(男:4.4~7.6;女:2.3~6.6)': 'uric_acid'},
                                  hematology: {'白血球計數\n(4~10)': 'wbc', '紅血球計數\n(男:4.5~6;女:4~5.5)': 'rbc', '血色素\n(男:13.5~17.5;女:12~16)': 'hb', '血球容積比\n(男:41~53;女:36~46)': 'hct', '紅血球平均體積\n(80~100)': 'mcv', 
                                               '紅血球平均血紅素\n(26~34)': 'mch', '平均血色素濃度\n(31~37)': 'mchc', '血小板計數\n(150~450)': 'platelet', '紅血球分佈寬度\n(11.5~14.5)': 'rdw_cv', 
                                               '血小板分佈寬度\n(9.8~16.2)': 'pdw', '平均血小板容積\n(9~13)': 'mpv', '大血小板血球率\n(男:19.2~47;女:19.1~46.6)': 'p_lcr', '嗜中性白血球\n(40~70)': 'neut', '淋巴球\n(20~56)': 'lym', 
                                               '單核球\n(0~12)': 'mono', '嗜酸性白血球\n(0~5)': 'eosin', '嗜鹼性白血球\n(0~2)': 'baso', '嗜中性白血球計數\n(1.5~8)': 'neut_count', '淋巴球計數\n(0.5~4)': 'lym_count', 
                                               '單核球計數\n(0~0.8)': 'mono_count', '嗜酸性白血球計數\n(0~0.55)': 'eosin_count', '嗜鹼性白血球計數\n(0~0.2)': 'baso_count', '骨髓細胞\n(<0)': 'myelo', 
                                               '前骨髓細胞\n(<0)': 'pro', '母細胞\n(<0)': 'blast', '帶狀型嗜中性白血球\n(0~5)': 'band', '後骨髓球\n(<0)': 'metamyelo', '非典型淋巴球\n(<0)': 'a_lym', 
                                               '有核紅血球\n(<0)': 'nrbc'},
                                  digestion: {'草酸轉胺基酶\n(13~39)': 'got', '丙酮轉氨基酶\n(7~52)': 'gpt', '麩胺轉酸酶\n(9~64)': 'r_gtp', '鹼性磷酸酶\n(34~104)': 'alk_p', '總蛋白\n(6.4~8.9)': 't_p', 
                                              '白蛋白\n(3.5~5.7)': 'albumin', '球蛋白\n(1.8~4.0)': 'globulin', '蛋白比值\n(1~2.8)': 'a_g'},
                                  cardiovascular: {'三酸甘油脂\n(<150 mg/dL)': 'tg', '總膽固醇\n(<200 mg/dL)': 't_cholesterol', '高密度脂蛋白\n(男:≧40;女≧50 mg/dL)': 'hdl', '低密度脂蛋白\n(<130 mg/dL)': 'ldl', 
                                                   '血管硬化危險因子指數\n(<4.0)': 'risk_factor', '飯前血糖\n(70~99)': 'sugar_ac', '隨機血糖\n(mg/dL)': 'sugar_random', 
                                                   '乳酸脫氫酵素\n(140~271)': 'ldh', '肌酸磷酸激酶\n(30~223)': 'cpk', '游離四碘甲狀腺素\n(0.59~1.43)': 'free_t4', '甲狀腺促進素\n(0.38~5.33)': 'tsh'},
                                  tumor: {'早期肝癌篩檢\n(≦7 ng/mL)': 'afp', '大腸癌胚抗原\n(0~5)': 'cea', '乳房癌篩檢標記\n(女:<26.4)': 'ca_153', '攝護腺特異抗原\n(≦4 ng/mL)': 'psa'},
                                  sona: {'腹部超音波\n(無明顯異狀)': 'abd_sona', '乳房超音波\n(無明顯異狀)': 'breast_sona', '婦科超音波\n(無明顯異狀)': 'gyn_sona', '攝護腺超音波\n(無明顯異狀)': 'prostate_sona', 
                                         '甲狀腺超音波\n(無明顯異狀)': 'thyroid_sona', '頸動脈超音波\n(無明顯異狀)': 'carotid_a_sona'},
                                  xray: {'骨質密度\n(>-1)': 'bmd', '胸部正面X光\n(無明顯異狀)': 'chest_x_ray'},
                                  other: {'電腦心電圖\n(正常)': 'ekg'}}

export const columnsDict2019 = {patient: {健檢日期: 'inspect_date', '工號': 'employee_number', 姓名: 'username', 性別: 'gender', 
                                      廠別: 'company', 部門: 'department', 單位: 'unit', '職稱/座號': 'position'}, 
                                general: {年齡: 'age', 身高: 'height', 體重: 'weight', 'BMI\n(18.5~23.9)': 'bmi', '收縮壓\n(<130)': 'sbp', '舒張壓\n(<85)': 'dbp', 
                                          '脈搏\n(60~100)': 'pulse', '腰圍\n(男:<90;女:<80)': 'waistline'},
                                physical: {'頭頸部\n(無明顯異狀)': 'head_neck', '呼吸系統\n(無明顯異狀)': 'lung', '心血管系統\n(無明顯異狀)': 'heart', '消化系統\n(無明顯異狀)': 'digestion', '神經系統\n(無明顯異狀)': 'nerve', 
                                           '肌肉骨骼\n(無明顯異狀)': 'skeletal_muscle', '皮膚\n(無明顯異狀)': 'skin', '睡眠概況\n(正常)': 'sleep_profile', 睡眠時間: 'sleep_time', 
                                           既往病史: 'medical_history', 自覺症狀: 'chief_complaint', 生活習慣:'social_habit', 作業經歷: 'work_history'},
                                eyeent: {'裸視[右眼]\n(0.8~1.5)': 'right_eye', '裸視[左眼]\n(0.8~1.5)': 'left_eye', '矯正[右眼]\n(0.9~1.5)': 'right_eye_correction', 
                                         '矯正[左眼]\n(0.9~1.5)': 'left_eye_correction', '右眼壓\n(10~21)': 'right_eye_pressure', '左眼壓\n(10~21)': 'left_eye_pressure', '右聽力\n(無異狀)': 'right_ear', '左聽力\n(無異狀)': 'left_ear', '辨色力\n(無異狀)': 'color_vision'},
                                urology: {'尿白血球\n(-);(+/-)': 'u_leukocyte', '亞硝酸鹽\n(-);(+/-)': 'u_nitrite', '尿膽素原\n(<1.5 mg/dL)': 'u_urobilinogen', '尿蛋白\n(-);(+/-)': 'u_protein', 
                                          '尿酸鹼值\n(5~8)': 'u_ph', '尿潛血\n(-);(+/-)': 'u_ob', '尿比重\n(1.003~1.035)': 'u_spgr', '尿丙酮\n(-);(+/-)': 'u_ketone', '尿膽紅素\n(-);(+/-)': 'u_bilirubin', 
                                          '尿糖\n(-);(+/-)': 'u_glucose', '免疫法糞便潛血反應\n(0~99)': 'fobt', '尿液-其他\n(Not Found)': 'other'},
                                kidney: {'尿素氮\n(9~23)': 'bun', '肌酸酐\n(男:0.7~1.3;女:0.5~1.1)': 'creatinine', '腎絲球過濾率\n(>60)': 'egfr', '尿酸\n(男:3.7~9.2;女:3.1~7.8)': 'uric_acid'},
                                hematology: {'白血球計數\n(4~10)': 'wbc', '紅血球計數\n(男:4.5~6;女:4~5.5)': 'rbc', '血色素\n(男:13.5~17.5;女:12~16)': 'hb', '血球容積比\n(男:41~53;女:36~46)': 'hct', '紅血球平均體積\n(80~100)': 'mcv', 
                                             '紅血球平均血紅素\n(26~34)': 'mch', '平均血色素濃度\n(31~37)': 'mchc', '血小板計數\n(150~450)': 'platelet', '紅血球分佈寬度\n(11.5~14.5)': 'rdw_cv', 
                                             '血小板分佈寬度\n(9.8~16.2)': 'pdw', '平均血小板容積\n(9~13)': 'mpv', '大血小板血球率\n(男:19.2~47;女:19.1~46.6)': 'p_lcr', '嗜中性白血球\n(40~70)': 'neut', '淋巴球\n(20~56)': 'lym', 
                                             '單核球\n(0~12)': 'mono', '嗜酸性白血球\n(0~5)': 'eosin', '嗜鹼性白血球\n(0~2)': 'baso', '嗜中性白血球計數\n(1.5~8)': 'neut_count', '淋巴球計數\n(0.5~4)': 'lym_count', 
                                             '單核球計數\n(0~0.8)': 'mono_count', '嗜酸性白血球計數\n(0~0.55)': 'eosin_count', '嗜鹼性白血球計數\n(0~0.2)': 'baso_count', '骨髓細胞\n(<0)': 'myelo', 
                                             '前骨髓細胞\n(<0)': 'pro', '母細胞\n(<0)': 'blast', '帶狀型嗜中性白血球\n(0~5)': 'band', '後骨髓球\n(<0)': 'metamyelo', '非典型淋巴球\n(<0)': 'a_lym', 
                                             '有核紅血球\n(<0)': 'nrbc'},
                                digestion: {'草酸轉胺基酶\n(0~34)': 'got', '丙酮轉氨基酶\n(10~49)': 'gpt', '麩胺轉酸酶\n(7~50)': 'r_gtp', '鹼性磷酸酶\n(46~116)': 'alk_p', '總蛋白\n(5.7~8.2)': 't_p', 
                                            '白蛋白\n(3.2~4.8)': 'albumin', '球蛋白\n(2.3~3.6)': 'globulin', '蛋白比值\n(1.1~2.5)': 'a_g'},
                                cardiovascular: {'三酸甘油脂\n(<150 mg/dL)': 'tg', '總膽固醇\n(<200 mg/dL)': 't_cholesterol', '高密度脂蛋白\n(>40 mg/dL)': 'hdl', '低密度脂蛋白\n(<100 mg/dL)': 'ldl', 
                                                 '血管硬化危險因子指數\n(1.5～5.5)': 'risk_factor', '飯前血糖\n(74~99)': 'sugar_ac', '隨機血糖\n(mg/dL)': 'sugar_random', 
                                                 '乳酸脫氫酵素\n(140~271)': 'ldh', '肌酸磷酸激酶\n(30~223)': 'cpk', '游離四碘甲狀腺素\n(0.59~1.43)': 'free_t4', '甲狀腺促進素\n(0.55~4.78)': 'tsh'},
                                tumor: {'早期肝癌篩檢\n(0～8.1)': 'afp', '大腸癌胚抗原\n(0~5)': 'cea', '乳房癌篩檢標記\n(女:<24.4)': 'ca_153', '攝護腺特異抗原\n(男:0～4)': 'psa'},
                                sona: {'腹部超音波\n(無明顯異狀)': 'abd_sona', '乳房超音波\n(無明顯異狀)': 'breast_sona', '婦科超音波\n(無明顯異狀)': 'gyn_sona', '攝護腺超音波\n(無明顯異狀)': 'prostate_sona', 
                                       '甲狀腺超音波\n(無明顯異狀)': 'thyroid_sona', '頸動脈超音波\n(無明顯異狀)': 'carotid_a_sona'},
                                xray: {'骨質密度\n(>-1)': 'bmd', '胸部正面X光\n(無明顯異狀)': 'chest_x_ray'},
                                other: {'電腦心電圖\n(正常)': 'ekg'}}

export const abnormalColumns = [{field: "employeenumber", headerName: "工號"}, {field: "department", headerName: "部門"},
                                {field: "inspectdate", headerName: "檢查日期"}, {field: "categorygroup", headerName: "檢查項目"},
                                {field: "categoryitem", headerName: "檢查細項"}, {field: "itemdata", headerName: "檢查數據", cellClassName: "abnormal-cell"},
                                {field: "itemlimit", headerName: "數據標準"}]

export const messageColumns = [{field: "name", headerName: "姓名", width: 80, filterable: false},
                               {field: "department", headerName: "部門", filterable: false},
                               {field: "employeenumber", headerName: "工號", filterable: false},
                               {field: "email", headerName: "E-Mail", width: 250, filterable: false},
                               {field: "line_result", headerName: "LINE發送結果", width: 150, filterable: false},
                               {field: "email_result", headerName: "E-MAIL發送結果", width: 150, filterable: false}]

export const reportUploadColumns = [{field: "name", headerName: "姓名", width: 100, filterable: false},
                                    {field: "date", headerName: "檢查日期", width: 100, filterable: false},
                                    {field: "column", headerName: "欄位", width: 225, filterable: false},
                                    {field: "value", headerName: "數值", width: 225, filterable: false},
                                    {field: "limit", headerName: "正常值", width: 200, filterable: false}]

export const formUploadColumns = [{field: "name", headerName: "姓名", width: 200, filterable: false},
                                  {field: "column", headerName: "欄位", width: 200, filterable: false},
                                  {field: "value", headerName: "數值", width: 200, filterable: false}]

export const zHTWLocale = {
                            noRowsLabel: "未找到數據。",
                            noResultsOverlayLabel: "未找到數據。",
                            errorOverlayDefaultLabel: "發生錯誤。",

                            toolbarExport: '導出報告',
                            toolbarExportLabel: '導出報告',
                            toolbarExportCSV: '導出至CSV',
                            toolbarExportPrint: '列印',

                            footerRowSelected: (count) => `共選了${count.toLocaleString()}筆資料`,
                            footerTotalRows: "總筆數 : ",

                            filterPanelAddFilter: '增加篩選器',
                            filterPanelDeleteIconLabel: '刪除',
                            filterPanelOperators: '篩選邏輯',
                            filterPanelOperatorAnd: '與',
                            filterPanelOperatorOr: '或',
                            filterPanelColumns: '項目名稱',
                            filterPanelInputLabel: '項目值',
                            filterPanelInputPlaceholder: '篩選值',
                            filterOperatorContains: '包含',
                            filterOperatorEquals: '等於',
                            filterOperatorStartsWith: '開始於',
                            filterOperatorEndsWith: '结束於',
                            filterOperatorIs: '是',
                            filterOperatorNot: '不是',
                            filterOperatorAfter: '在之後',
                            filterOperatorOnOrAfter: '正在後面',
                            filterOperatorBefore: '在之前',
                            filterOperatorOnOrBefore: '正在前面',
                            filterOperatorIsEmpty: '為空',
                            filterOperatorIsNotEmpty: '不為空',
                            filterOperatorIsAnyOf: '在其中',

                            columnsPanelTextFieldLabel: '搜尋',
                            columnsPanelTextFieldPlaceholder: '項目名稱',
                            columnsPanelDragIconLabel: '重排序列',
                            columnsPanelShowAllButton: '顯示所有',
                            columnsPanelHideAllButton: '隱藏所有',
                            columnMenuShowColumns: "顯示",
                            columnMenuFilter: '篩選器',
                            columnMenuHideColumn: '隱藏',
                            columnMenuUnsort: '恢復預設',
                            columnMenuSortAsc: '升序排列',
                            columnMenuSortDesc: '降序排列',

                            pinToLeft: '釘選至左側',
                            pinToRight: '釘選至右側',
                            unpin: '取消釘選',

                            MuiTablePagination: {
                              labelRowsPerPage: "每頁筆數",
                              labelDisplayedRows: ({ from, to, count }) => `第 ${from} 筆 ~ 第 ${to} 筆，共 ${count} 筆`,
                            },
                          }





