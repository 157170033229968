import { Container } from '@mui/material'
import AccountLogin from "../components/account/AccountLogin"
import Footer from "../components/common/Footer"

export default function Login() {
  return (
  	<Container maxWidth="100%" disableGutters sx={{marginTop: "10%"}}>
      <AccountLogin />
      <Footer width="60%" topMargin="5%" rightMargin="-63%" logoTopMargin="10px" />
    </Container>
  )
}