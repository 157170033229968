import axios from 'axios'
import * as AccountAPI from './AccountAPI'

const axiosInstance = axios.create({
  baseURL: 'https://askin-corp-api.dermai.com.tw/api/reports/',
  headers: {
    Accept: 'application/json'
  }
})

axiosInstance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${AccountAPI.getExternalID()}`
  return config
})

export const getDate = (belong) => {
  return axiosInstance.get(`dates/?belong=${belong}`).then(res => res.data)
}

export const getPatientInfo = (belong) => {
  return axiosInstance.get(`patient/?belong=${belong}`).then(res => res.data)
}

export const getReport = (belong, id_number, inspect_group, inspect_date, isDashboard) => {
  if (isDashboard) {
    return axiosInstance.get(`report/${inspect_group}/${inspect_date}/?belong=${belong}&id_number=${id_number}`).then(res => res.data)
  } else {
    return axiosInstance.get(`report/${inspect_group}/${inspect_date}/?belong=${belong}`).then(res => res.data)
  }
}

export const getAbnormal = (belong, idNumber, inspect_date, isDashboard) => {
  if (isDashboard) {
    return axiosInstance.get(`abnormal/?belong=${belong}&idNumber=${idNumber}&inspect_date=${inspect_date}`).then(res => res.data)
  } else {
    return axiosInstance.get(`abnormal/?belong=${belong}&inspect_date=${inspect_date}`).then(res => res.data)
  }
}