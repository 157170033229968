import "./NormalUserGeneral.css"
import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import { Box, Grid, Card, CardContent, CardMedia, CardActionArea, Typography, CircularProgress } from '@mui/material'
import user_head from '../../images/user_head.png'
import user_footer from '../../images/user_footer.png'
import reportCard from '../../images/menu_cards/report_card.png'
import vital_signs_card from '../../images/menu_cards/vital_signs_card.png'
import doctor_appointment_card from '../../images/menu_cards/doctor_appointment_card.png'
import appointment_record_card from '../../images/menu_cards/appointment_record_card.png'
import health_log_lock_card from '../../images/menu_cards/health_log_lock_card.png'
import health_smart_lock_card from '../../images/menu_cards/health_smart_lock_card.png'
import * as AccountAPI from '../../api/AccountAPI'
import * as ReportAPI from '../../api/ReportAPI'

export default function NormalUserMenu(props) {

  const navigate = useNavigate()

  const [showMenu, setShowMenu] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {

    async function checkBinded(belong) {
      setShowMenu(false)

      try {
        await AccountAPI.checkUserBinded(belong, "menu")
      } catch (error) {
        if (error.response.status === 401) {
          navigate(`/verification/${belong}`, {state: {from: "menu"}, replace: true})
        } else if (error.response.status === 404) {
          alert("用戶不存在，\n請重新操作一次，或請聯絡客服人員")
          return
        } else {
          alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
          return
        }
      }
      setShowMenu(true)
    }

    checkBinded(props.belong)

  }, [])

  const handleClick = async (event) => {

    setIsLoading(true)

    let target = event.target.accessKey

    if (target === "report") {

      try {
        var dateResponse = await ReportAPI.getDate(props.belong)

        if (dateResponse.length === 0) {
          setIsLoading(false)
          alert("目前尚無您的健檢報告")
          return
        }

        var patientResponse = await ReportAPI.getPatientInfo(props.belong)

      } catch (error) {
        alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
      }

      navigate(`/report/${props.belong}`, {state: {dates: dateResponse, patient: patientResponse}, replace: true})
    } else if (target === "measurement") {
      window.location.replace(`https://askin-itelemed.dermai.com.tw/measurement/?belong=${props.belong}&external_id=${btoa(sessionStorage.getItem('external_id'))}`)
    } else if (target === "appointment") {
      window.location.replace(`https://askin-itelemed.dermai.com.tw/openhour/?belong=${props.belong}&external_id=${btoa(sessionStorage.getItem('external_id'))}`)
    } else if (target === "record") {
      window.location.replace(`https://askin-itelemed.dermai.com.tw/history/?belong=${props.belong}&external_id=${btoa(sessionStorage.getItem('external_id'))}`)
    } else if (target === "task") {
      navigate(`/task/${props.belong}`, {replace: true})
    }
  }

  return (
    <div className="menu-cards" align="center">
      { !showMenu ? <div align="center"><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7"}}>載入中...</span></div>
        :
        <Box sx={{ flexGrow: 1 }}>
          <Grid container direction="column" rowSpacing="5%" wrap="nowrap">
            <Grid item>
              <div align="center" style={{display: "flex"}}>
                <Box component="img" sx={{width: "100%"}} alt="user_head" src={user_head}/>
                <Typography variant="h4" component="div" sx={{position: "absolute", marginLeft: "5%", marginTop: "12%", color: "#FFFFFF", fontWeight: "bold"}}>Menu</Typography>
              </div>
            </Grid>
            <Grid item>
              <div align="center" style={{display: "flex", justifyContent: "center", marginLeft: "1%", marginRight: "1%"}}>
                <Card className="menu-card">
                  <CardActionArea  accessKey="report" onClick={handleClick}>
                    <CardMedia component="img" accessKey="report" src={reportCard} sx={{marginTop: "-10px", marginLeft: "-15%"}} />
                    <CardContent sx={{padding: "0px", paddingLeft: "10px", paddingTop: "20px", marginTop:"-45%", color: "#474747"}}>
                      <Typography component="div" accessKey="report" align="left" sx={{fontWeight: "bold", fontSize: "18px"}}>Health Report</Typography>
                      <Typography component="div" accessKey="report" align="left" sx={{fontSize: "12px"}}>查看健檢報告</Typography>
                    </CardContent>
                   </CardActionArea>
                </Card>
                <Card className="menu-card">
                  <CardActionArea accessKey="measurement" onClick={handleClick}>
                    <CardMedia component="img" accessKey="measurement" src={vital_signs_card} sx={{marginTop: "-10px", marginLeft: "-15%"}} />
                    <CardContent sx={{padding: "0px", paddingLeft: "10px", paddingTop: "20px", marginTop:"-45%", color: "#474747"}}>
                      <Typography component="div" accessKey="measurement" align="left" sx={{fontWeight: "bold", fontSize: "18px"}}>Vital Signs</Typography>
                      <Typography component="div" accessKey="measurement" align="left" sx={{fontSize: "12px"}}>生理量測</Typography>
                    </CardContent>
                   </CardActionArea>
                </Card>
                <br/>
              </div>
              <div align="center" style={{display: "flex", justifyContent: "center", marginLeft: "1%", marginRight: "1%"}}>
                <Card className="menu-card">
                  <CardActionArea accessKey="appointment" onClick={handleClick}>
                    <CardMedia component="img" accessKey="appointment" src={doctor_appointment_card} sx={{marginTop: "-10px", marginLeft: "-15%"}} />
                    <CardContent sx={{padding: "0px", paddingLeft: "10px", paddingTop: "20px", marginTop:"-45%", color: "#474747"}}>
                      <Typography component="div" accessKey="appointment" align="left" sx={{fontWeight: "bold", fontSize: "18px"}}>Make Appointment</Typography>
                      <Typography component="div" accessKey="appointment" align="left" sx={{fontSize: "12px"}}>預約看診</Typography>
                    </CardContent>
                   </CardActionArea>
                </Card>
                { isLoading ? <div align="center" style={{position: "absolute", marginTop: "15%"}}><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7"}}>載入中...</span></div>
                  : <div></div>
                }
                <Card className="menu-card">
                  <CardActionArea accessKey="record" onClick={handleClick}>
                    <CardMedia component="img" accessKey="record" src={appointment_record_card} sx={{marginTop: "-10px", marginLeft: "-15%"}} />
                    <CardContent sx={{padding: "0px", paddingLeft: "10px", paddingTop: "20px", marginTop:"-45%", color: "#474747"}}>
                      <Typography component="div" accessKey="record" align="left" sx={{fontWeight: "bold", fontSize: "18px"}}>Appointment List</Typography>
                      <Typography component="div" accessKey="record" align="left" sx={{fontSize: "12px"}}>預約記錄</Typography>
                    </CardContent>
                   </CardActionArea>
                </Card>
                <br/>
              </div>
              <div align="center" style={{display: "flex", justifyContent: "center", marginLeft: "1%", marginRight: "1%"}}>
                <Card className="menu-card">
                  <CardActionArea disabled>
                    <CardMedia component="img" alt="reminder" src={health_log_lock_card} sx={{marginTop: "-10px", marginLeft: "-15%"}} />
                    <CardContent sx={{padding: "0px", paddingLeft: "10px", paddingTop: "20px", marginTop:"-45%", color: "#474747"}}>
                      <Typography component="div" align="left" sx={{fontWeight: "bold", fontSize: "18px", color: "#A9A9A9"}}>J-Reminder</Typography>
                      <Typography component="div" align="left" sx={{fontSize: "12px", color: "#A9A9A9"}}>健康追蹤計畫</Typography>
                    </CardContent>
                   </CardActionArea>
                </Card>
                <Card className="menu-card">
                  <CardActionArea disabled>
                    <CardMedia component="img" alt="smart" src={health_smart_lock_card} sx={{marginTop: "-10px", marginLeft: "-15%"}} />
                    <CardContent sx={{padding: "0px", paddingLeft: "10px", paddingTop: "20px", marginTop:"-45%", color: "#474747"}}>
                      <Typography component="div" align="left"sx={{fontWeight: "bold", fontSize: "18px", color: "#A9A9A9"}}>Health Smart</Typography>
                      <Typography component="div" align="left" sx={{fontSize: "12px", color: "#A9A9A9"}}>健康狀況分析</Typography>
                    </CardContent>
                   </CardActionArea>
                </Card>
              </div>
            </Grid>
            <Grid item>
              <div align="center" style={{display: "flex"}}>
                <Box component="img" sx={{width: "100%"}} alt="logo" src={user_footer}/>
                <Box sx={{marginLeft: "-44%", marginTop: "10%"}}>
                  <div align="left" style={{width: "100%"}}>
                    <Typography component="div" align="left">Hi {props.userName}!</Typography>
                    <Typography component="div" align="left">歡迎使用健康助理</Typography>
                    <Typography component="div" align="left">需要什麼服務呢 ?</Typography>
                  </div>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Box>
      }
    </div>
  )
}
