import { Box, Typography, Divider} from '@mui/material'
import { styled } from '@mui/material/styles'
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro'
import { zHTWLocale } from '../common/RelatedVariables'

const CustomizedDataGrid = styled(DataGridPro)({
  '&.MuiDataGrid-root': {
    border: 'none'
  }, 
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    outline: 'none'
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
    outline: 'none'
  },
  '.MuiDataGrid-footerContainer': {
    border: 'none'
  }
})

function ExportToolbar() {
  return (
    <div align="right">
      <GridToolbarContainer>
        <GridToolbarExport sx={{color: "#5AA7A7"}} csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    </div>
  )
}

export default function ResultTable(props) {
  const columns = props.columns
  const result_list = props.result_list

  return (
    <Box>
      <Typography component="div" sx={{fontWeight: "bold", color: "#474747", marginTop: "2%", marginLeft: "2%"}}>{props.title}</Typography>
      <Divider variant="middle" />
      { result_list.length === 0 ? 
        <Typography component="div" align="center" sx={{fontWeight: "bold", color: "#474747"}}>無</Typography>
        :
        <div align="center">
          <Box sx={{ height: result_list.length * 220 + "px", width: "95%", marginTop: "3%", maxHeight: "500px", boxShadow: 1, borderRadius: "10px"}}>
            <CustomizedDataGrid
              columns={columns}
              rows={result_list}
              components={{
                Toolbar: ExportToolbar,
              }}
              localeText={zHTWLocale}
              disableSelectionOnClick
            />
          </Box>
        </div>
      }
    </Box>
  )
}