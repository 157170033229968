import { useContext, useState } from "react"
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Stack, Grid, Chip, Autocomplete, TextField, Button, Typography } from '@mui/material'
import { KeyboardArrowLeft, Search, ContentPaste } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { categoryCnDict, categoryEnDict } from '../common/RelatedVariables'
import { UserReportContext } from '../../routes/UserReport'
import { DashboardContext } from '../../routes/Reports'
import user_head from '../../images/user_head.png'
import anonymous_male from '../../images/anonymous_male.png'
import anonymous_female from '../../images/anonymous_female.png'
import SwitchWithLabel from '../common/SwitchWithLabel'
import NormalUserReportAccordion from './NormalUserReportAccordion'

const CustomizedTextField = styled(TextField)({
  '& label': {
    color: '#5AA7A7',
  },
  '& label.Mui-focused': {
    color: '#5AA7A7',
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid #5AA7A7',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid #5AA7A7',
  }
})

export default function NormalUserReport(props) {

  const dashboardContext = useContext(DashboardContext)
  const userReportContext = useContext(UserReportContext)

  const location = useLocation()
  const navigate = useNavigate()

  const inspectGroupsCN = Object.keys(categoryCnDict)
  inspectGroupsCN.shift()

  const inspectGroupsEN = Object.keys(categoryEnDict)
  inspectGroupsEN.shift()

  const userName = props.isDashboard ? props.patient["username"] : location.state.patient["username"]
  const employeeNumber = props.isDashboard ? props.patient["employee_number"] : location.state.patient["employee_number"]
  const department = props.isDashboard ? props.patient["department"] : location.state.patient["department"]
  const gender = props.isDashboard ? props.patient["gender"] : location.state.patient["gender"]
  const age = props.isDashboard ? props.patient["age"] : location.state.patient["age"]

  const [date, setDate] = useState(props.isDashboard ? props.date : location.state.dates[0])

  const [selectedInspectGroups, setSelectedInspectGroups] = useState(inspectGroupsEN)

  const handleClick = (event) => {
    navigate(`/menu/${props.belong}`, {replace: true})
  }

  const handleDateChange = (event, values) => {
    setDate(values)
  }

  const handleInspectGroupsChange = (event, values) => {

    let inspect_groups = []
    values.forEach(value => inspect_groups.push(categoryCnDict[value]))

    setSelectedInspectGroups([])
    setSelectedInspectGroups((prevSelectedInspectGroups) => [...prevSelectedInspectGroups, ...inspect_groups])

  }

  return (

    <div className="user-report">
      { !props.isDashboard ?
        <Box sx={{ flexGrow: 1 }}>
          <Grid container direction="column" wrap="nowrap">
            <Grid item>
              <div align="center" style={{display: "flex"}}>
                <Box component="img" sx={{width: "100%"}} alt="user_head" src={user_head}/>
                <Button onClick={handleClick} sx={{position: "absolute", marginLeft: "3%", marginTop: "12%"}}><KeyboardArrowLeft sx={{color: "#FFFFFF", fontSize: "35px"}} /></Button>
                <Typography variant="h6" component="div" sx={{position: "absolute", marginLeft: "35%", marginTop: "14%", color: "#FFFFFF", fontWeight: "bold"}}>查看健檢報告</Typography>
              </div>
            </Grid>
          </Grid>
        </Box>
        : <div></div>
      }

      <Box sx={{ flexGrow: 1, margin: "10px", marginTop: "20px"}}>
        <Grid container spacing={3} columns={16}>
          <Grid item xs={7}>
            <Box
              component="img"
              src={props.isDashboard ? props.patient.picture ? props.patient.picture : props.patient.gender === "男" ? anonymous_male : anonymous_female : location.state.patient["picture"]}
              alt="user_picture"
              sx={props.isDashboard ? props.patient.picture ? {height: "100%", width: "100%"} : {height: "85%", width: "85%"} : {height: "100%", width: "100%"}}
            />
          </Grid>
          <Grid item xs={9} sx={{alignSelf: "center"}}>
            <Box sx={{display: "flex", justifyContent: "center"}}>
              <Stack spacing={0} sx={{fontSize: props.isDashboard ? "20px" : "16px"}}>
                { !props.isDashboard ?
                  <Autocomplete
                    sx={{ width: "125%" }}
                    id="date-selector"
                    options={location.state.dates}
                    defaultValue={date}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <CustomizedTextField
                        {...params}
                        variant="standard"
                        label="請選擇健檢日期"
                      />
                    )}
                    onChange={handleDateChange}
                    disableClearable
                  />
                  : <div></div>
                }
                <span>姓名 : {userName}</span>
                <span>工號 : {employeeNumber}</span>
                <span>部門 : {department}</span>
                <span>性別 : {gender}</span>
                <span>年齡 : {age}</span>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, margin: "10px"}}>
        <Grid container>
          <Grid item xs={12}>
            <div align="left">
              <Typography gutterBottom variant="h7" component="div" sx={{fontWeight: "bold", marginTop: "0.3rem"}}><Search sx={{marginRight: "2px"}} />健檢項目</Typography>
            </div>
          </Grid>
           <Grid item xs={12}>
             <Autocomplete
              sx={{ width: "100%", marginBottom: "5px"}}
              multiple
              limitTags={3}
              id="inspect-groups-filter"
              options={inspectGroupsCN}
              getOptionLabel={(option) => option}
              defaultValue={inspectGroupsCN}
              renderInput={(params) => (
                <CustomizedTextField
                  {...params}
                  variant="standard"
                  label="請選擇檢查項目"
                  placeholder="選擇檢查項目"
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    sx={{backgroundColor: "#A7D676"}}
                  />
                ))
              }
              onChange={handleInspectGroupsChange}
            />
          </Grid>
          <Grid item xs={4}>
            <div align="left"><Typography gutterBottom variant="h7" component="div" sx={{fontWeight: "bold", marginTop: "0.3rem"}}><ContentPaste sx={{marginRight: "2px"}} />數據報告</Typography></div>
          </Grid>
          <Grid item xs={8}>
            <SwitchWithLabel isDashboard={props.isDashboard} from="user" label="僅顯示異常資料" />
          </Grid>
        </Grid>
      </Box>
      <NormalUserReportAccordion belong={props.belong} selectedInspectGroups={selectedInspectGroups} date={date} isAbnormalSwitch={props.isDashboard ? dashboardContext.isUserAbnormalSwitch : userReportContext.isAbnormalSwitch} patient={props.patient} isDashboard={props.isDashboard}/>
    </div>
  )
}