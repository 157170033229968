import { useState, useEffect, useContext } from 'react'
import { Box, Typography, Button, Divider, TextField, Checkbox, MenuItem, FormGroup, FormControl, FormLabel, FormControlLabel, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DashboardContext } from '../../routes/NationalForms'
import * as FormAPI from '../../api/FormAPI'

const CustomizedTextField = styled(TextField)({
  '& label': {
    color: '#5AA7A7',
  },
  '& label.Mui-focused': {
    color: '#5AA7A7',
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid #101010',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid #101010',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #101010'
    },
    '&:hover fieldset': {
      border: '1px solid #101010'
    },
    '&.Mui-focused fieldset' : {
      border: '1px solid #101010'
    }
  }
})

const CustomizedFormLabel = styled(FormLabel)({
  color: '#5AA7A7',
  '&.Mui-focused': {
    color: '#5AA7A7',
  }
})

const CustomizedFormControlLabel = styled(FormControlLabel)({
  color: '#000000'
})

const CustomizedButton = styled(Button)({
  backgroundColor: '#5AA7A7',
  '&:hover': {
    backgroundColor: '#A8DEE0'
  },
})

export default function Form(props) {

  const dashboardContext = useContext(DashboardContext)

  const [form, setForm] = useState([])
  const [formTitle, setFormTitle] = useState("")
  const [answers, setAnswers] = useState(props.readOnly ? [] : {})
  const [checked, setChecked] = useState({})
  const [checkboxAnswers, setCheckboxAnswers] = useState({})
  const [checkboxRestriction, setCheckboxRestriction] = useState({})
  const [calculations, setCalculations] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadFailed, setIsLoadFailed] = useState(false)
  const [loadingText, setLoadingText] = useState("")
  const [remind, setRemind] = useState("")

  useEffect(() => {

    async function getForm(isDashboard, belong, project, titleID, readOnly) {

      setLoadingText("載入中...")
      setIsLoading(true)

      try {
        let titleResponse = await FormAPI.getFormTitle(belong, titleID)
        let formResponse = await FormAPI.getForm(isDashboard, belong, project, titleID, readOnly)

        setFormTitle(titleResponse.title)

        setForm([])
        setForm((prevForm) => [...prevForm, ...formResponse])

        setChecked({})
        setCheckboxRestriction({})

        if (!readOnly) {
          // setAnswers({})
          formResponse.forEach(formElement => {
            if (formElement.question.question_type === "checkbox" || formElement.question.extra === "checkbox") {
              formElement.question.choices.forEach(choice => {
                setChecked((checked) => ({...checked, [formElement.question.name]: {...checked[formElement.question.name], [choice.name]: false}}))
                setCheckboxRestriction((checkboxRestriction) => ({...checkboxRestriction, [formElement.question.name]: {...checkboxRestriction[formElement.question.name], [choice.name]: false}}))
                // setAnswers((answers) => ({...answers, [formElement.order]: {}}))
              })
            }

            if (formElement.question.name.endsWith("calculate")) {
              setCalculations((preCalculations) => ({...preCalculations, [formElement.question.name]: 0}))
            }

          })

        } else {
          try {
            let answersResponse = await FormAPI.getAnswer(belong, project, titleID)

            setAnswers([])
            setAnswers((prevAnswers) => [...prevAnswers, ...answersResponse])

          } catch (error) {
            alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
            return
          }
        }

        setIsLoading(false)

      } catch (error) {
        setIsLoadFailed(true)
        if (error.response.status === 404) {
          alert("表單不存在")
          setRemind("表單不存在")
        } else if (error.response.status === 403) {
          alert("您沒有權限填寫此表單")
          setRemind("您沒有權限填寫此表單")
        } else if (error.response.status === 400) {
          alert("此問卷專案已結束")
          window.location.href = `/form/${props.belong}/${props.formProject}/${props.formTitleID}/${true}`
        } else {
          alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
          return
        }
      }

      setIsLoading(false)

    }

    setIsLoadFailed(false)
    getForm(props.isDashboard, props.belong, props.formProject, props.formTitleID, props.readOnly)

  }, [props])

  const retrieveAnswer = (targetOrder, params, choice, isCheckbox) => {
    for(var i = 0; i < answers.length; i++) {
      let answer = answers[i]
      if (answer["order"] === targetOrder) {
        if (params.question_type === "input"|| params.question_type === "textarea" || params.question_type === "select") {
          return answer["answer"]
        } else if (params.question_type === "checkbox" || params.extra === "checkbox") {
          let answer_dict = JSON.parse(answer["answer"].replaceAll("True", "true").replaceAll("'", '"'))
          for (const [k, v] of Object.entries(answer_dict)) {
            if (k === choice.name) {
              if (isCheckbox) {
                return true
              } else {
                return v
              }
            }
          }
        }
      }
    }
    
    if (params.question_type === "input" || params.question_type === "textarea" || params.question_type === "select") {
      return ""
    } else if (params.question_type === "checkbox" || params.extra === "checkbox") {
      if (isCheckbox) {
        return false
      } else {
        return ""
      }
    }
  }

  const restrictCheckBox = (params, targetChoice, boolenFlag) => {
    params.choices.forEach(choice => {
      if (choice.name !== targetChoice) {
        setCheckboxRestriction((checkboxRestriction) => ({...checkboxRestriction, [params.name]: {...checkboxRestriction[params.name], [choice.name]: boolenFlag}}))
      }
    })
  }

  const calculatePoint = (choice, isChecked) => {
    let correspondPoints
    let choiceNo
    let calculationKey

    if (props.formTitleID === "20fe7c9c6f8d11ed89300a6fe67abc63") {
      correspondPoints = {"c1": 100, "c2": 75, "c3": 50, "c4": 25, "c5": 0}
      choiceNo = choice.name.slice(-2)
      calculationKey = choice.name.slice(0, -4) + "_point_calculate"

      if (!isChecked) {
        setCalculations((preCalculations) => ({...preCalculations, [calculationKey]: (preCalculations[calculationKey]-correspondPoints[choiceNo])}))
      } else {
        setCalculations((preCalculations) => ({...preCalculations, [calculationKey]: (preCalculations[calculationKey]+correspondPoints[choiceNo])}))
      }
    }
  }

  const substituteValue = (target, extra) => {
    let calculateResult = calculations[target]

    if (props.formTitleID === "20fe7c9c6f8d11ed89300a6fe67abc63") {
      if (target === "personal_point_calculate") {
        calculateResult = Math.round(calculations[target]/6)
      } else if (target === "work_point_calculate") {
        calculateResult = Math.round(calculations[target]/7)
      }
    } else if (props.formTitleID === "21131f306f8d11ed89300a6fe67abc63") {
      if (extra) {
        return extra
      }
    }
    return calculateResult
  }

  const processBeforeSubmit = () => {

    let processAnswers = {}

    for(var i = 0; i < form.length; i++) {
      let question = form[i].question
      let order = form[i].order
      let calculateResult = calculations[question.name]

      if (question.question_type === "input" && question.name.endsWith("calculate")) {
        if (question.name === "personal_point_calculate") {
          calculateResult = Math.round(calculations[question.name]/6)
        } else if (question.name === "work_point_calculate") {
          calculateResult = Math.round(calculations[question.name]/7)
        }
        processAnswers[order] = calculateResult
      } else if (question.question_type === "input" && question.extra) {
        processAnswers[order] = question.extra
      }
    }
    return processAnswers
  }

  const validateSubmit = () => {
    for(var i = 0; i < form.length; i++) {
      let question = form[i].question

      if (question.question_type === "checkbox" || question.extra === "checkbox") {
        if (question.is_required && Object.values(checked[question.name]).filter((v) => v).length === 0) {
          alert(`請填寫 "${question.question_label}"`)
          return false
        }
      }
    }
    return true
  }

  const handleCheckBoxChange = (order, params, choice, isCheckbox) => (event) => {
    if (isCheckbox) {

      setChecked((checked) => ({...checked, [params.name]: {...checked[params.name], [choice.name]: event.target.checked}}))

      calculatePoint(choice, event.target.checked)

      if (params.accept_format === "single") {
        restrictCheckBox(params, event.target.name, event.target.checked)
      }

      if (!event.target.checked) {
        delete answers[order][choice.name]
      } else {
        if (choice.extra === "text") {
          if (checkboxAnswers[params.name]) {
            setAnswers({...answers, [order]: {...answers[order], [choice.name]: checkboxAnswers[params.name][choice.name]}})
          } else {
            setAnswers({...answers, [order]: {...answers[order], [choice.name]: true}})
          }
        } else {
          setAnswers({...answers, [order]: {...answers[order], [choice.name]: true}})
        }
      }
    } else {
      setAnswers({...answers, [order]: {...answers[order], [choice.name]: event.target.value}})
      setCheckboxAnswers({...checkboxAnswers, [params.name]: {...checkboxAnswers[params.name], [choice.name]: event.target.value}})
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    let processAnswers = processBeforeSubmit()

    if (validateSubmit()) {

      setLoadingText("提交中...")
      setIsLoading(true)

      try {
        await FormAPI.submitAnswers({isDashboard: props.isDashboard, belong: props.belong, project: props.formProject, titleID: props.formTitleID, answers: {...answers, ...processAnswers}})
        alert("提交成功")
        if (!props.isDashboard) {
          window.location.href = `/form/${props.belong}/${props.formProject}/${props.formTitleID}/${true}`
        } else {
          setIsLoading(false)
          dashboardContext.setOpenModal(false)
          dashboardContext.setIsReply(true)
        }
      } catch (error) {
        alert("提交失敗，\n請重新操作一次，或請聯絡客服人員")
        setIsLoading(false)
        return
      }
    }
  }

  function createTitle(index, order, params) {
    return (
      <div key={index} align="left">
        <br/>
        { params.input_type === "main" ?
          <div>
            <Typography variant="h5" sx={{color: "#5AA7A7", fontWeight: "bold"}}>{params.question_label}</Typography>
            <Divider variant="fullWidth" />
          </div> : 
          <Typography variant="h7" sx={{color: "#5AA7A7", fontWeight: "bold"}}>{params.question_label}</Typography> 
        }
        { params.extra === "checkbox" ?
          createCheckboxInput(index, order, params) : 
          <div></div>
        }
      </div>
    )
  }

  function createTextInput(index, order, params) {
    return (
      <div key={index} align="left">
        {props.readOnly ?
          <CustomizedTextField 
            name={params.name} 
            label={params.question_label} 
            variant="standard" 
            margin="normal" 
            value={retrieveAnswer(order, params, null, false)}
            disabled={true}
            InputLabelProps={params.input_type === "date" ? {style: {top: "-50%"}} : {}}
            sx={{width: "350px", marginTop: params.input_type === "date" ? "3%" : "1%"}}
          /> : 
          <CustomizedTextField 
            type={params.input_type} 
            name={params.name} 
            label={params.question_label} 
            variant="standard" 
            margin="normal"
            value={substituteValue(params.name, params.extra)}
            required={params.is_required}
            placeholder={params.input_type === "date" ? "西元年/月/日" : ""}
            InputLabelProps={params.input_type === "date" ? {style: {top: "-50%"}} : {}}
            inputProps={params.input_type === "number" ? {min: 0}: {}}
            onChange={(event) => setAnswers({...answers, [order]: event.target.value})} 
            sx={{width: "350px", marginTop: params.input_type === "date" ? "3%" : "1%"}}
          />
        }
      </div>
    )
  }

  function createTextAreaInput(index, order, params) {
    return (
      <div key={index} align="left">
        {props.readOnly ?
          <CustomizedTextField 
            name={params.name} 
            label={params.question_label} 
            variant="outlined" 
            margin="normal" 
            rows={5} 
            multiline 
            value={retrieveAnswer(order, params, null, false)}
            disabled={true}
            sx={{width: "350px"}}
          /> : 
          <CustomizedTextField 
          name={params.name} 
          label={params.question_label} 
          variant="outlined" 
          margin="normal" 
          required={params.is_required} 
          rows={5} 
          multiline 
          onChange={(event) => setAnswers({...answers, [order]: event.target.value})} 
          sx={{width: "350px"}}
        />
      }
      </div>
    )
  }

  function createSelectInput(index, order, params) {
    return (
      <div key={index} align="left">
        {props.readOnly ?
          <CustomizedTextField 
            name={params.name} 
            label={params.question_label} 
            margin="normal" 
            variant="outlined" 
            value={retrieveAnswer(order, params, null, false)}
            disabled={true}
            sx={{width: "350px"}}
          /> :
          <CustomizedTextField 
          select 
          name={params.name} 
          label={params.question_label} 
          margin="normal" 
          variant="outlined" 
          required={params.is_required} 
          defaultValue=""
          onChange={(event) => setAnswers({...answers, [order]: event.target.value})} 
          sx={{width: "350px"}}
        >
          { params.choices.map((choice, index) => (
              <MenuItem key={index} value={choice.value}>{choice.text}</MenuItem>
            ))
          }
        </CustomizedTextField>
      }
      </div>
    )
  }

  function createCheckboxInput(index, order, params) {

    return (
      <div key={index} align="left">
        <FormControl variant="outlined" margin="normal" required={params.is_required}>
          { params.question_type !== "title" ?
            <CustomizedFormLabel>{params.question_label}</CustomizedFormLabel> :
            <div></div>
          }
          <FormGroup>
            { params.choices.map((choice, index) => (
                choice.extra === "text" ?
                  <div key={index}>
                    {props.readOnly ?
                      <div>
                        <CustomizedFormControlLabel 
                          name={choice.name} 
                          control={<Checkbox style={{color: "#5AA7A7"}} />} 
                          label={choice.text} 
                          checked={retrieveAnswer(order, params, choice, true)}
                          disabled={true}
                          sx={{alignSelf: "start"}}
                        />
                        <CustomizedTextField 
                          name={choice.name} 
                          variant="standard" 
                          margin="normal"
                          value={retrieveAnswer(order, params, choice, false)}
                          disabled={true}
                          sx={{marginTop: "1px", width: "350px"}}
                        /> 
                      </div> : 
                      <div>
                        <CustomizedFormControlLabel 
                          name={choice.name} 
                          control={<Checkbox style={{color: "#5AA7A7"}} />} 
                          label={choice.text} 
                          disabled={checkboxRestriction[params.name][choice.name]}
                          onChange={handleCheckBoxChange(order, params, choice, true)}
                          sx={{alignSelf: "start"}}
                        />
                        <CustomizedTextField 
                          name={choice.name} 
                          variant="standard" 
                          margin="normal"
                          disabled={!checked[params.name][choice.name]}
                          onChange={handleCheckBoxChange(order, params, choice, false)} 
                          sx={{marginTop: "1px", width: "350px"}}
                        />
                      </div>
                    }
                  </div> :
                  <div key={index}>
                    {props.readOnly ?
                      <CustomizedFormControlLabel
                        name={choice.name} 
                        control={<Checkbox style={{color: "#5AA7A7"}} />} 
                        label={choice.text}
                        checked={retrieveAnswer(order, params, choice, true)}
                        disabled={true}
                        sx={{alignSelf: "start"}}
                      /> :
                      <CustomizedFormControlLabel
                        name={choice.name} 
                        control={<Checkbox style={{color: "#5AA7A7"}} />} 
                        label={choice.text}
                        disabled={checkboxRestriction[params.name][choice.name]}
                        onChange={handleCheckBoxChange(order, params, choice, true)} 
                        sx={{alignSelf: "start"}}
                      />
                    }
                  </div>
              ))
            }
          </FormGroup>
        </FormControl>
      </div>
    )
  }

  function createImage(index, params) {
    return (
      <div key={index} align="left">
        <Box component="img" sx={{width: (params.name === "musculoskeletal_form1" || params.name === "musculoskeletal_form2") ? "40%" : "60%"}} alt={params.name} src={require(`../../images/form_related/${params.name}.png`)} />
      </div>
    )
  }

  function createNote(index, params) {
    return (
      <div key={index} align="left">
        <Typography variant="h7" sx={{color: "#5AA7A7", fontWeight: "bold"}}>{params.question_label}</Typography>
      </div>
    )
  }

  function createForm() {

    return (
      <Box component="form" onSubmit={handleSubmit} sx={{backgroundColor: "#F5F5F5", border: "1px solid lightgrey", padding: "1%"}}>
        { form.map((formElement, index) => (
            formElement.question.question_type === "title" ? 
            createTitle(index, formElement.order, formElement.question) :
            formElement.question.question_type === "input" ? 
            createTextInput(index, formElement.order, formElement.question) :
            formElement.question.question_type === "textarea" ? 
            createTextAreaInput(index, formElement.order, formElement.question) : 
            formElement.question.question_type === "checkbox" ? 
            createCheckboxInput(index, formElement.order, formElement.question) : 
            formElement.question.question_type === "select" ? 
            createSelectInput(index, formElement.order, formElement.question) :
            formElement.question.question_type === "image" ? 
            createImage(index, formElement.question) : 
            formElement.question.question_type === "note" ? 
            createNote(index, formElement.question) : 
            <div></div>
          ))
        }
        <br/>
        { !isLoadFailed ?
            !props.readOnly ? 
              isLoading ? 
                <div align="center"><CircularProgress sx={{color: "#5AA7A7"}} /><br/><span style={{color: "#5AA7A7"}}>{loadingText}</span></div> :
                <CustomizedButton type="submit" variant="contained" sx={{backgroundColor: "#5AA7A7"}}>提交</CustomizedButton> :
              isLoading ?
                <div align="center"><CircularProgress sx={{color: "#5AA7A7"}} /><br/><span style={{color: "#5AA7A7"}}>{loadingText}</span></div> :
            <Typography variant="h5" sx={{color: "#5AA7A7", fontWeight: "bold"}}>{answers.length > 0 ? "已提交" : "未提交"}</Typography> :
          <div></div>
        }
        { remind.length !== 0 ?
            <Typography variant="h5" sx={{color: "#5AA7A7", fontWeight: "bold"}}>{remind}</Typography> :
            <div></div>
        }
      </Box>
    )
  }

  return (
    <div align="center">
      <Typography variant="h4" sx={{color: "#5AA7A7", fontWeight: "bold"}}>{formTitle}</Typography>
      <br/>
      {createForm()}
    </div>
  )
}