import { useState, useEffect, useRef } from "react"
import { Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress } from '@mui/material'
import { ExpandMore } from '@mui/icons-material/'
import NormalUserReportRawTable from './NormalUserReportRawTable'
import NormalUserReportAbnTable from './NormalUserReportAbnTable'
import * as ReportAPI from '../../api/ReportAPI'

export default function NormalUserReportAccordion(props) {

  const selectedInspectGroups = props.selectedInspectGroups
  const date = props.date
  const isAbnormalSwitch = props.isAbnormalSwitch

  const isMounted = useRef(false)

  const [expendedPanels, setExpendedPanels] = useState([])

  const [isGeneralLoading, setIsGeneralLoading] = useState(false)
  const [isPhysicalLoading, setIsPhysicalLoading] = useState(false)
  const [isEyeentLoading, setIsEyeentLoading] = useState(false)
  const [isUrologyLoading, setIsUrologyLoading] = useState(false)
  const [isKidneyLoading, setIsKidneyLoading] = useState(false)
  const [isHematologyLoading, setIsHematologyLoading] = useState(false)
  const [isDigestionLoading, setIsDigestionLoading] = useState(false)
  const [isCardiovascularLoading, setIsCardiovascularLoading] = useState(false)
  const [isTumorLoading, setIsTumorLoading] = useState(false)
  const [isSonaLoading, setIsSonaLoading] = useState(false)
  const [isXrayLoading, setIsXrayLoading] = useState(false)
  const [isOtherLoading, setIsOtherLoading] = useState(false)

  const [generalReport, setGeneralReport] = useState({})
  const [physicalReport, setPhysicalReport] = useState({})
  const [eyeentReport, setEyeentReport] = useState({})
  const [urologyReport, setUrologyReport] = useState({})
  const [kidneyReport, setKidneyReport] = useState({})
  const [hematologyReport, setHematologyReport] = useState({})
  const [digestionReport, setDigestionReport] = useState({})
  const [cardiovascularReport, setCardiovascularReport] = useState({})
  const [tumorReport, setTumorReport] = useState({})
  const [sonaReport, setSonaReport] = useState({})
  const [xrayReport, setXrayReport] = useState({})
  const [otherReport, setOtherReport] = useState({})

  const [generalAbnormal, setGeneralAbnormal] = useState({})
  const [physicalAbnormal, setPhysicalAbnormal] = useState({})
  const [eyeentAbnormal, setEyeentAbnormal] = useState({})
  const [urologyAbnormal, setUrologyAbnormal] = useState({})
  const [kidneyAbnormal, setKidneyAbnormal] = useState({})
  const [hematologyAbnormal, setHematologyAbnormal] = useState({})
  const [digestionAbnormal, setDigestionAbnormal] = useState({})
  const [cardiovascularAbnormal, setCardiovascularAbnormal] = useState({})
  const [tumorAbnormal, setTumorAbnormal] = useState({})
  const [sonaAbnormal, setSonaAbnormal] = useState({})
  const [xrayAbnormal, setXrayAbnormal] = useState({})
  const [otherAbnormal, setOtherAbnormal] = useState({})

  useEffect(() => {
    async function collectAbnormal() {

      try {
        var abnromalResponse = await ReportAPI.getAbnormal(props.belong, props.patient ? btoa(props.patient["id_number"]) : null, date, props.isDashboard)
      } catch (error) {
        alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
      }

      setGeneralAbnormal({})
      setGeneralAbnormal((prevGeneralAbnormal) => ({...prevGeneralAbnormal, ...abnromalResponse["general"]}))

      setPhysicalAbnormal({})
      setPhysicalAbnormal((prevPhysicalAbnormal) => ({...prevPhysicalAbnormal, ...abnromalResponse["physical"]}))

      setEyeentAbnormal({})
      setEyeentAbnormal((prevEyeentAbnormal) => ({...prevEyeentAbnormal, ...abnromalResponse["eyeent"]}))

      setUrologyAbnormal({})
      setUrologyAbnormal((prevUrologyAbnormal) => ({...prevUrologyAbnormal, ...abnromalResponse["urology"]}))

      setKidneyAbnormal({})
      setKidneyAbnormal((prevKidneyAbnormal) => ({...prevKidneyAbnormal, ...abnromalResponse["kidney"]}))

      setHematologyAbnormal({})
      setHematologyAbnormal((prevHematologyAbnormal) => ({...prevHematologyAbnormal, ...abnromalResponse["hematology"]}))

      setDigestionAbnormal({})
      setDigestionAbnormal((prevDigestionAbnormal) => ({...prevDigestionAbnormal, ...abnromalResponse["digestion"]}))

      setCardiovascularAbnormal({})
      setCardiovascularAbnormal((prevCardiovascularAbnormal) => ({...prevCardiovascularAbnormal, ...abnromalResponse["cardiovascular"]}))

      setTumorAbnormal({})
      setTumorAbnormal((prevTumorAbnormal) => ({...prevTumorAbnormal, ...abnromalResponse["tumor"]}))

      setSonaAbnormal({})
      setSonaAbnormal((prevSonaAbnormal) => ({...prevSonaAbnormal, ...abnromalResponse["sona"]}))

      setXrayAbnormal({})
      setXrayAbnormal((prevXrayAbnormal) => ({...prevXrayAbnormal, ...abnromalResponse["xray"]}))

      setOtherAbnormal({})
      setOtherAbnormal((prevOtherAbnormal) => ({...prevOtherAbnormal, ...abnromalResponse["other"]}))
    }

    collectAbnormal()

  }, [date])

  useEffect(() => {

    async function getExpendedReport() {

      if(isMounted.current){

        try {
          for (var i = 0; i < expendedPanels.length; i++) {

            let panel = expendedPanels[i]

            if (!isAbnormalSwitch) {
              switch (expendedPanels[i]) {
                case "general" :

                  setIsGeneralLoading(true)

                  try {
                    var generalReponse = await ReportAPI.getReport(props.belong, props.patient ? btoa(props.patient["id_number"]) : null, panel, date, props.isDashboard)
                  } catch (error) {
                    alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
                  }

                  setGeneralReport({})
                  setGeneralReport((prevGeneralReport) => ({...prevGeneralReport, ...generalReponse}))

                  setIsGeneralLoading(false)
                  break

                case "physical" :

                  setIsPhysicalLoading(true)

                  try {
                    var physicalReponse = await ReportAPI.getReport(props.belong, props.patient ? btoa(props.patient["id_number"]) : null, panel, date, props.isDashboard)
                  } catch (error) {
                    alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
                  }

                  setPhysicalReport({})
                  setPhysicalReport((prevPhysicalReport) => ({...prevPhysicalReport, ...physicalReponse}))

                  setIsPhysicalLoading(false)
                  break

                case "eyeent" :

                  setIsEyeentLoading(true)

                  try {
                    var EyeentReponse = await ReportAPI.getReport(props.belong, props.patient ? btoa(props.patient["id_number"]) : null, panel, date, props.isDashboard)
                  } catch (error) {
                    alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
                  }

                  setEyeentReport({})
                  setEyeentReport((prevEyeentReport) => ({...prevEyeentReport, ...EyeentReponse}))

                  setIsEyeentLoading(false)
                  break

                case "urology" :

                  setIsUrologyLoading(true)

                  try {
                    var urologyReponse = await ReportAPI.getReport(props.belong, props.patient ? btoa(props.patient["id_number"]) : null, panel, date, props.isDashboard)
                  } catch (error) {
                    alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
                  }

                  setUrologyReport({})
                  setUrologyReport((prevUrologyReport) => ({...prevUrologyReport, ...urologyReponse}))

                  setIsUrologyLoading(false)
                  break

                case "kidney" :

                  setIsKidneyLoading(true)

                  try {
                    var KidneyReponse = await ReportAPI.getReport(props.belong, props.patient ? btoa(props.patient["id_number"]) : null, panel, date, props.isDashboard)
                  } catch (error) {
                    alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
                  }

                  setKidneyReport({})
                  setKidneyReport((prevKidneyReport) => ({...prevKidneyReport, ...KidneyReponse}))

                  setIsKidneyLoading(false)
                  break

                case "hematology" :

                  setIsHematologyLoading(true)

                  try {
                    var HematologyReponse = await ReportAPI.getReport(props.belong, props.patient ? btoa(props.patient["id_number"]) : null, panel, date, props.isDashboard)
                  } catch (error) {
                    alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
                  }

                  setHematologyReport({})
                  setHematologyReport((prevHematologyReport) => ({...prevHematologyReport, ...HematologyReponse}))
              
                  setIsHematologyLoading(false)
                  break

                case "digestion" :

                  setIsDigestionLoading(true)

                  try {
                    var DigestionReponse = await ReportAPI.getReport(props.belong, props.patient ? btoa(props.patient["id_number"]) : null, panel, date, props.isDashboard)
                  } catch (error) {
                    alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
                  }

                  setDigestionReport({})
                  setDigestionReport((prevDigestionReport) => ({...prevDigestionReport, ...DigestionReponse}))

                  setIsDigestionLoading(false)
                  break

                case "cardiovascular" :

                  setIsCardiovascularLoading(true)

                  try {
                    var CardiovascularReponse = await ReportAPI.getReport(props.belong, props.patient ? btoa(props.patient["id_number"]) : null, panel, date, props.isDashboard)
                  } catch (error) {
                    alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
                  }

                  setCardiovascularReport({})
                  setCardiovascularReport((prevCardiovascularReport) => ({...prevCardiovascularReport, ...CardiovascularReponse}))

                  setIsCardiovascularLoading(false)
                  break

                case "tumor" :

                  setIsTumorLoading(true)

                  try {
                    var TumorReponse = await ReportAPI.getReport(props.belong, props.patient ? btoa(props.patient["id_number"]) : null, panel, date, props.isDashboard)
                  } catch (error) {
                    alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
                  }

                  setTumorReport({})
                  setTumorReport((prevTumorReport) => ({...prevTumorReport, ...TumorReponse}))

                  setIsTumorLoading(false)
                  break

                case "sona" :

                  setIsSonaLoading(true)

                  try {
                    var SonaReponse = await ReportAPI.getReport(props.belong, props.patient ? btoa(props.patient["id_number"]) : null, panel, date, props.isDashboard)
                  } catch (error) {
                    alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
                  }

                  setSonaReport({})
                  setSonaReport((prevSonaReport) => ({...prevSonaReport, ...SonaReponse}))

                  setIsSonaLoading(false)
                  break

                case "xray" :

                  setIsXrayLoading(true)

                  try {
                    var XrayReponse = await ReportAPI.getReport(props.belong, props.patient ? btoa(props.patient["id_number"]) : null, panel, date, props.isDashboard)
                  } catch (error) {
                    alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
                  }

                  setXrayReport({})
                  setXrayReport((prevXrayReport) => ({...prevXrayReport, ...XrayReponse}))

                  setIsXrayLoading(false)
                  break

                case "other" :

                  setIsOtherLoading(true)

                  try {
                    var otherReponse = await ReportAPI.getReport(props.belong, props.patient ? btoa(props.patient["id_number"]) : null, panel, date, props.isDashboard)
                  } catch (error) {
                    alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
                  }
                  
                  setOtherReport({})
                  setOtherReport((prevOtherReport) => ({...prevOtherReport, ...otherReponse}))

                  setIsOtherLoading(false)

                  break
              }
            }
          }
        } catch (error) {
          alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
        }
      }
    }

    getExpendedReport()

  }, [isAbnormalSwitch, date])

  const handleChange = (panel) => async (event, isExpanded) => {

    isMounted.current = true
    setExpendedPanels((prevExpendedPanels) => [...prevExpendedPanels, panel])


    if (isExpanded) {
      if (!isAbnormalSwitch) {
        switch (panel) {
          case "general" :
            setIsGeneralLoading(true)
            break
          case "physical" :
            setIsPhysicalLoading(true)
            break
          case "eyeent" :
            setIsEyeentLoading(true)
            break
          case "urology" :
            setIsUrologyLoading(true)
            break
          case "kidney" :
            setIsKidneyLoading(true)
            break
          case "hematology" :
            setIsHematologyLoading(true)
            break
          case "digestion" :
            setIsDigestionLoading(true)
            break
          case "cardiovascular" :
            setIsCardiovascularLoading(true)
            break
          case "tumor" :
            setIsTumorLoading(true)
            break
          case "sona" :
            setIsSonaLoading(true)
            break
          case "xray" :
            setIsXrayLoading(true)
            break
          case "other" :
            setIsOtherLoading(true)
            break
        }

        try {
          var reportResponse = await ReportAPI.getReport(props.belong, props.patient ? btoa(props.patient["id_number"]) : null, panel, date, props.isDashboard)
        } catch (error) {
          alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
        }

        switch (panel) {
          case "general" :
            setGeneralReport({})
            setGeneralReport((prevGeneralReport) => ({...prevGeneralReport, ...reportResponse}))
            setIsGeneralLoading(false)
            break
          case "physical" :
            setPhysicalReport({})
            setPhysicalReport((prevPhysicalReport) => ({...prevPhysicalReport, ...reportResponse}))
            setIsPhysicalLoading(false)
            break
          case "eyeent" :
            setEyeentReport({})
            setEyeentReport((prevEyeentReport) => ({...prevEyeentReport, ...reportResponse}))
            setIsEyeentLoading(false)
            break
          case "urology" :
            setUrologyReport({})
            setUrologyReport((prevUrologyReport) => ({...prevUrologyReport, ...reportResponse}))
            setIsUrologyLoading(false)
            break
          case "kidney" :
            setKidneyReport({})
            setKidneyReport((prevKidneyReport) => ({...prevKidneyReport, ...reportResponse}))
            setIsKidneyLoading(false)
            break
          case "hematology" :
            setHematologyReport({})
            setHematologyReport((prevHematologyReport) => ({...prevHematologyReport, ...reportResponse}))
            setIsHematologyLoading(false)
            break
          case "digestion" :
            setDigestionReport({})
            setDigestionReport((prevDigestionReport) => ({...prevDigestionReport, ...reportResponse}))
            setIsDigestionLoading(false)
            break
          case "cardiovascular" :
            setCardiovascularReport({})
            setCardiovascularReport((prevCardiovascularReport) => ({...prevCardiovascularReport, ...reportResponse}))
            setIsCardiovascularLoading(false)
            break
          case "tumor" :
            setTumorReport({})
            setTumorReport((prevTumorReport) => ({...prevTumorReport, ...reportResponse}))
            setIsTumorLoading(false)
            break
          case "sona" :
            setSonaReport({})
            setSonaReport((prevSonaReport) => ({...prevSonaReport, ...reportResponse}))
            setIsSonaLoading(false)
            break
          case "xray" :
            setXrayReport({})
            setXrayReport((prevXrayReport) => ({...prevXrayReport, ...reportResponse}))
            setIsXrayLoading(false)
            break
          case "other" :
            setOtherReport({})
            setOtherReport((prevOtherReport) => ({...prevOtherReport, ...reportResponse}))
            setIsOtherLoading(false)
            break
        }
      }
    } else {
      setExpendedPanels(prevExpendedPanels => prevExpendedPanels.filter(prevExpendedPanel => prevExpendedPanel !== panel))
    }
  }

  return (
    <div className="inspect-items-accordion" align="center" style={{margin: "10px"}}>
      {selectedInspectGroups.includes("general") ?
        <Accordion onChange={handleChange("general")} sx={{marginBottom: "10px"}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="general-panel"
            id="general-panel-header"
            sx={{backgroundColor: "#A8DEE0"}}
          >
            { isAbnormalSwitch ?
              <Typography>一般檢查 ({Object.keys(generalAbnormal).length})</Typography>
              : <Typography>一般檢查</Typography>
            }
          </AccordionSummary>
          <AccordionDetails>
            { isGeneralLoading ? 
              <div>
                <CircularProgress color="inherit" />
              </div>
              : !isAbnormalSwitch ? <NormalUserReportRawTable report={generalReport} />
              : Object.keys(generalAbnormal).length === 0 ?<div><span>此項目無異常，請繼續保持!</span></div>
              : <NormalUserReportAbnTable report={generalAbnormal} />
            }
          </AccordionDetails>
        </Accordion>
        : <div></div>
      }
      {selectedInspectGroups.includes("physical") ?
        <Accordion onChange={handleChange("physical")} sx={{marginBottom: "10px"}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="physical-panel"
            id="physical-panel-header"
            sx={{backgroundColor: "#A8DEE0"}}
          >
            { isAbnormalSwitch ?
              <Typography>理學檢查 ({Object.keys(physicalAbnormal).length})</Typography>
              : <Typography>理學檢查</Typography>
            }
          </AccordionSummary>
          <AccordionDetails>
            { isPhysicalLoading ? 
              <div>
                <CircularProgress color="inherit" />
              </div>
              : !isAbnormalSwitch ? <NormalUserReportRawTable report={physicalReport} />
              : Object.keys(physicalAbnormal).length === 0 ?<div><span>此項目無異常，請繼續保持!</span></div>
              : <NormalUserReportAbnTable report={physicalAbnormal} />
            }
          </AccordionDetails>
        </Accordion>
        : <div></div>
      }
      {selectedInspectGroups.includes("eyeent") ?
        <Accordion onChange={handleChange("eyeent")} sx={{marginBottom: "10px"}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="eyeent-panel"
            id="eyeent-panel-header"
            sx={{backgroundColor: "#A8DEE0"}}
          >
            { isAbnormalSwitch ?
              <Typography>眼科及耳鼻喉科檢查 ({Object.keys(eyeentAbnormal).length})</Typography>
              : <Typography>眼科及耳鼻喉科檢查</Typography>
            }
          </AccordionSummary>
          <AccordionDetails>
            { isEyeentLoading ? 
              <div>
                <CircularProgress color="inherit" />
              </div>
              : !isAbnormalSwitch ? <NormalUserReportRawTable report={eyeentReport} />
              : Object.keys(eyeentAbnormal).length === 0 ?<div><span>此項目無異常，請繼續保持!</span></div>
              : <NormalUserReportAbnTable report={eyeentAbnormal} />
            }
          </AccordionDetails>
        </Accordion>
        : <div></div>
      }
      {selectedInspectGroups.includes("urology") ?
        <Accordion onChange={handleChange("urology")} sx={{marginBottom: "10px"}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="urology-panel"
            id="urology-panel-header"
            sx={{backgroundColor: "#A8DEE0"}}
          >
            { isAbnormalSwitch ?
              <Typography>尿液檢查 ({Object.keys(urologyAbnormal).length})</Typography>
              : <Typography>尿液檢查</Typography>
            }
          </AccordionSummary>
          <AccordionDetails>
            { isUrologyLoading ? 
              <div>
                <CircularProgress color="inherit" />
              </div>
              : !isAbnormalSwitch ? <NormalUserReportRawTable report={urologyReport} />
              : Object.keys(urologyAbnormal).length === 0 ?<div><span>此項目無異常，請繼續保持!</span></div>
              : <NormalUserReportAbnTable report={urologyAbnormal} />
            }
          </AccordionDetails>
        </Accordion>
        : <div></div>
      }
      {selectedInspectGroups.includes("kidney") ?
        <Accordion onChange={handleChange("kidney")} sx={{marginBottom: "10px"}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="kidney-panel"
            id="kidney-panel-header"
            sx={{backgroundColor: "#A8DEE0"}}
          >
            { isAbnormalSwitch ?
              <Typography>腎機能檢查 ({Object.keys(kidneyAbnormal).length})</Typography>
              : <Typography>腎機能檢查</Typography>
            }
          </AccordionSummary>
          <AccordionDetails>
            { isKidneyLoading ? 
              <div>
                <CircularProgress color="inherit" />
              </div>
              : !isAbnormalSwitch ? <NormalUserReportRawTable report={kidneyReport} />
              : Object.keys(kidneyAbnormal).length === 0 ?<div><span>此項目無異常，請繼續保持!</span></div>
              : <NormalUserReportAbnTable report={kidneyAbnormal} />
            }
          </AccordionDetails>
        </Accordion>
        : <div></div>
      }
      {selectedInspectGroups.includes("hematology") ?
        <Accordion onChange={handleChange("hematology")} sx={{marginBottom: "10px"}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="hematology-panel"
            id="hematology-panel-header"
            sx={{backgroundColor: "#A8DEE0"}}
          >
            { isAbnormalSwitch ?
              <Typography>血液系統檢查 ({Object.keys(hematologyAbnormal).length})</Typography>
              : <Typography>血液系統檢查</Typography>
            }
          </AccordionSummary>
          <AccordionDetails>
            { isHematologyLoading ? 
              <div>
                <CircularProgress color="inherit" />
              </div>
              : !isAbnormalSwitch ? <NormalUserReportRawTable report={hematologyReport} />
              : Object.keys(hematologyAbnormal).length === 0 ?<div><span>此項目無異常，請繼續保持!</span></div>
              : <NormalUserReportAbnTable report={hematologyAbnormal} />
            }
          </AccordionDetails>
        </Accordion>
        : <div></div>
      }
      {selectedInspectGroups.includes("digestion") ?
        <Accordion onChange={handleChange("digestion")} sx={{marginBottom: "10px"}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="digestion-panel"
            id="digestion-panel-header"
            sx={{backgroundColor: "#A8DEE0"}}
          >
            { isAbnormalSwitch ?
              <Typography>消化系統檢查 ({Object.keys(digestionAbnormal).length})</Typography>
              : <Typography>消化系統檢查</Typography>
            }
          </AccordionSummary>
          <AccordionDetails>
            { isDigestionLoading ? 
              <div>
                <CircularProgress color="inherit" />
              </div>
              : !isAbnormalSwitch ? <NormalUserReportRawTable report={digestionReport} />
              : Object.keys(digestionAbnormal).length === 0 ?<div><span>此項目無異常，請繼續保持!</span></div>
              : <NormalUserReportAbnTable report={digestionAbnormal} />
            }
          </AccordionDetails>
        </Accordion>
        : <div></div>
      }
      {selectedInspectGroups.includes("cardiovascular") ?
        <Accordion onChange={handleChange("cardiovascular")} sx={{marginBottom: "10px"}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="cardiovascular-panel"
            id="cardiovascular-panel-header"
            sx={{backgroundColor: "#A8DEE0"}}
          >
            { isAbnormalSwitch ?
              <Typography>心血管及代謝系統檢查 ({Object.keys(cardiovascularAbnormal).length})</Typography>
              : <Typography>心血管及代謝系統檢查</Typography>
            }
          </AccordionSummary>
          <AccordionDetails>
            { isCardiovascularLoading ? 
              <div>
                <CircularProgress color="inherit" />
              </div>
              : !isAbnormalSwitch ? <NormalUserReportRawTable report={cardiovascularReport} />
              : Object.keys(cardiovascularAbnormal).length === 0 ?<div><span>此項目無異常，請繼續保持!</span></div>
              : <NormalUserReportAbnTable report={cardiovascularAbnormal} />
            }
          </AccordionDetails>
        </Accordion>
        : <div></div>
      }
      {selectedInspectGroups.includes("tumor") ?
        <Accordion onChange={handleChange("tumor")} sx={{marginBottom: "10px"}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="tumor-panel"
            id="tumor-panel-header"
            sx={{backgroundColor: "#A8DEE0"}}
          >
            { isAbnormalSwitch ?
              <Typography>腫瘤檢查 ({Object.keys(tumorAbnormal).length})</Typography>
              : <Typography>腫瘤檢查</Typography>
            }
          </AccordionSummary>
          <AccordionDetails>
            { isTumorLoading ? 
              <div>
                <CircularProgress color="inherit" />
              </div>
              : !isAbnormalSwitch ? <NormalUserReportRawTable report={tumorReport} />
              : Object.keys(tumorAbnormal).length === 0 ?<div><span>此項目無異常，請繼續保持!</span></div>
              : <NormalUserReportAbnTable report={tumorAbnormal} />
            }
          </AccordionDetails>
        </Accordion>
        : <div></div>
      }
      {selectedInspectGroups.includes("sona") ?
        <Accordion onChange={handleChange("sona")} sx={{marginBottom: "10px"}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="sona-panel"
            id="sona-panel-header"
            sx={{backgroundColor: "#A8DEE0"}}
          >
            { isAbnormalSwitch ?
              <Typography>超音波檢查 ({Object.keys(sonaAbnormal).length})</Typography>
              : <Typography>超音波檢查</Typography>
            }
          </AccordionSummary>
          <AccordionDetails>
            { isSonaLoading ? 
              <div>
                <CircularProgress color="inherit" />
              </div>
              : !isAbnormalSwitch ? <NormalUserReportRawTable report={sonaReport} />
              : Object.keys(sonaAbnormal).length === 0 ?<div><span>此項目無異常，請繼續保持!</span></div>
              : <NormalUserReportAbnTable report={sonaAbnormal} />
            }
          </AccordionDetails>
        </Accordion>
        : <div></div>
      }
      {selectedInspectGroups.includes("xray") ?
        <Accordion onChange={handleChange("xray")} sx={{marginBottom: "10px"}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="xray-panel"
            id="xray-panel-header"
            sx={{backgroundColor: "#A8DEE0"}}
          >
            { isAbnormalSwitch ?
              <Typography>X光檢查 ({Object.keys(xrayAbnormal).length})</Typography>
              : <Typography>X光檢查</Typography>
            }
          </AccordionSummary>
          <AccordionDetails>
            { isXrayLoading ? 
              <div>
                <CircularProgress color="inherit" />
              </div>
              : !isAbnormalSwitch ? <NormalUserReportRawTable report={xrayReport} />
              : Object.keys(xrayAbnormal).length === 0 ?<div><span>此項目無異常，請繼續保持!</span></div>
              : <NormalUserReportAbnTable report={xrayAbnormal} />
            }
          </AccordionDetails>
        </Accordion>
        : <div></div>
      }
      {selectedInspectGroups.includes("other") ?
        <Accordion onChange={handleChange("other")} sx={{marginBottom: "10px"}}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="other-panel"
            id="other-panel-header"
            sx={{backgroundColor: "#A8DEE0"}}
          >
            { isAbnormalSwitch ?
              <Typography>其他檢查 ({Object.keys(otherAbnormal).length})</Typography>
              : <Typography>其他檢查</Typography>
            }
          </AccordionSummary>
          <AccordionDetails>
            { isOtherLoading ? 
              <div>
                <CircularProgress color="inherit" />
              </div>
              : !isAbnormalSwitch ? <NormalUserReportRawTable report={otherReport} />
              : Object.keys(otherAbnormal).length === 0 ?<div><span>此項目無異常，請繼續保持!</span></div>
              : <NormalUserReportAbnTable report={otherAbnormal} />
            }
          </AccordionDetails>
        </Accordion>
        : <div></div>
      }

    </div>
  )
}