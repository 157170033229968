import { useState, createContext } from "react"
import { Container } from '@mui/material'
import Header from "../components/common/Header"
import FormDashboard from "../components/dashboard/FormDashboard"

export const DashboardContext = createContext()

const DashboardProvider = ({children}) => {
  let [project, setProject] = useState()
  let [formID, setFormID] = useState("")
  let [formLinks, setFormLinks] = useState("")
  let [openModal, setOpenModal] = useState(false)
  let [action, setAction] = useState("")
  let [messageType, setMessageType] = useState("")
  let [messageTitle, setMessageTitle] = useState("")
  let [messageText, setMessageText] = useState("")
  let [userInfo, setUserInfo] = useState({})
  let [isReply, setIsReply] = useState(false)
  let [isFinish, setIsFinish] = useState(false)

  let value = { project, setProject, formID, setFormID, formLinks, setFormLinks, openModal, setOpenModal, action, setAction, 
                messageType, setMessageType, messageText, setMessageText, messageTitle, setMessageTitle, 
                userInfo, setUserInfo, isReply, setIsReply, isFinish, setIsFinish }

  return (
    <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
  )
}

export default function NationalForms() {
  return (
    <Container maxWidth="100%">
      <Header />
      <DashboardProvider>
        <FormDashboard />
      </DashboardProvider>
    </Container>
  )
}