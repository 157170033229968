import './GeneralTable.css'
import { useState, useEffect, useContext } from "react"
import { useNavigate } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Message } from '@mui/icons-material'
import { DataGridPro, GridToolbarContainer, GridToolbarExport, GridLinkOperator, useGridApiContext, gridFilteredSortedRowEntriesSelector } from '@mui/x-data-grid-pro'
import { AuthContext } from '../../AppRoutes'
import { DashboardContext } from '../../routes/Reports'
import { ReportContext } from './ReportDashboard'
import { zHTWLocale } from '../common/RelatedVariables'
import * as ReportDashboardAPI from '../../api/ReportDashboardAPI'

const CustomizedDataGrid = styled(DataGridPro)({
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    outline: 'none'
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
    outline: 'none'
  },
})

function CustomizedToolbar() {

  const apiRef = useGridApiContext()

  const authContext = useContext(AuthContext)
  const dashboardContext = useContext(DashboardContext)

  const rows = gridFilteredSortedRowEntriesSelector(apiRef)

  const handleClick = async (event) => {
    let usersInfo = new Set()
    
    apiRef.current.state.selection.forEach(item => {
      rows.forEach(function(element, idx) {
        if (element.id === item) {
          let userInfo = [element.model.username, element.model.id_number, element.model.email, element.model.department, element.model.employee_number]

          if (!usersInfo.has(JSON.stringify(userInfo))) {
            usersInfo.add(JSON.stringify(userInfo))
          }
        }
      })
    })

    usersInfo = [...usersInfo].map(element => JSON.parse(element))

    dashboardContext.setMessageType("multiple")
    dashboardContext.setMessageText("")
    dashboardContext.setUserInfo({})
    dashboardContext.setUserInfo((prevUserInfo) => ({...prevUserInfo, ...{username: "{員工姓名}", usersInfo: usersInfo}}))
    dashboardContext.setAction("message")
    dashboardContext.setOpenModal(true)
  }

  return (
    <div align="right">
      <GridToolbarContainer>
        <GridToolbarExport sx={{color: "#5AA7A7"}} csvOptions={{utf8WithBom: true}} />
        <Button onClick={handleClick} sx={{color: "#5AA7A7", fontSize: "0.82rem", marginLeft: "15px"}} disabled={apiRef.current.state.selection.length === 0 || authContext.role === "doctor"}><Message sx={(apiRef.current.state.selection.length === 0 || authContext.role === "doctor") ? {fontSize: "18px", marginRight: "8px"} : {color: "#5AA7A7", fontSize: "18px", marginRight: "8px"}} />群發訊息</Button>
      </GridToolbarContainer>
    </div>
  )
}

export default function ReportRawDataTable() {

  const authContext = useContext(AuthContext)
  const dashboardContext = useContext(DashboardContext)
  const reportContext = useContext(ReportContext)

  const [isLoading, setIsLoading] = useState(false)

  const [reports, setReports] = useState([])
  const [reportsCount, setReportsCount] = useState(0)
  const [limitCompete, setLimitCompete] = useState({})

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)

  const [filterItems, setFilterItems] = useState([])

  const [sortItem, setSortItem] = useState(null)
  const [sortType, setSortType] = useState(null)

  const navigate = useNavigate()

  useEffect(() => {

    async function getReports() {

      setIsLoading(true)
      reportContext.setIsFilterReadOnly(true)

      setSortItem(null)
      setSortType(null)

      if (reportContext.selectedDepartments.length === 0 || reportContext.selectedInspectGroups.length === 0) {
        setReports([])
        setIsLoading(false)
        reportContext.setIsFilterReadOnly(false)
      } else {
        try {
          var rawPaginatedResponse = await ReportDashboardAPI.getReport(authContext.userName, dashboardContext.year, reportContext.selectedDepartments, reportContext.selectedInspectGroups, reportContext.selectedAbnormalItems, dashboardContext.isIntersectionSwitch, filterItems, sortItem, sortType, pageSize, page*pageSize)
        } catch (error) {
          if (error.response.status === 401) {
            navigate("/login", { replace: true})
          } else {
            alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
            setIsLoading(false)
          }
        }

        let reportsList = []
        let limitCompeteDict = {}

        rawPaginatedResponse.results.forEach(report => {
          reportsList.push(report[0])
          limitCompeteDict[report[0]["id"]] = report[2][report[0]["id"]]
        })

        setReports([])
        setReports((prevReports) => [...prevReports, ...reportsList])
        setReportsCount(rawPaginatedResponse.count)

        setLimitCompete({})
        setLimitCompete((prevLimitCompete) => ({...prevLimitCompete, ...limitCompeteDict}))

        setIsLoading(false)
        reportContext.setIsFilterReadOnly(false)

      }
    }

    getReports()

  }, [reportContext.selectedDepartments, reportContext.selectedInspectGroups, reportContext.selectedAbnormalItems, dashboardContext.isIntersectionSwitch])

  const handleFilterChange = async (filterModel) => {
    setFilterItems(filterModel.items)
    setIsLoading(true)

    try {
      var rawPaginatedResponse = await ReportDashboardAPI.getReport(authContext.userName, dashboardContext.year, reportContext.selectedDepartments, reportContext.selectedInspectGroups, reportContext.selectedAbnormalItems, dashboardContext.isIntersectionSwitch, filterModel.items, sortItem, sortType, pageSize, page*pageSize)
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/login", { replace: true})
      } else {
        alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
        setIsLoading(false)
      }
    }

    let reportsList = []
    let limitCompeteDict = {}

    rawPaginatedResponse.results.forEach(report => {
      reportsList.push(report[0])
      limitCompeteDict[report[0]["id"]] = report[2][report[0]["id"]]
    })

    setReports([])
    setReports((prevReports) => [...prevReports, ...reportsList])
    setReportsCount(rawPaginatedResponse.count)

    setLimitCompete({})
    setLimitCompete((prevLimitCompete) => ({...prevLimitCompete, ...limitCompeteDict}))

    setIsLoading(false)
  }

  const handleSortChange = async (sortModel) => {
    setSortItem(sortModel[0].field)
    setSortType(sortModel[0].sort)
    setIsLoading(true)

    try {
      var rawPaginatedResponse = await ReportDashboardAPI.getReport(authContext.userName, dashboardContext.year, reportContext.selectedDepartments, reportContext.selectedInspectGroups, reportContext.selectedAbnormalItems, dashboardContext.isIntersectionSwitch, filterItems, sortModel[0].field, sortModel[0].sort, pageSize, page*pageSize)
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/login", { replace: true})
      } else {
        alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
        setIsLoading(false)
      }
    }

    let reportsList = []
    let limitCompeteDict = {}

    rawPaginatedResponse.results.forEach(report => {
      reportsList.push(report[0])
      limitCompeteDict[report[0]["id"]] = report[2][report[0]["id"]]
    })

    setReports([])
    setReports((prevReports) => [...prevReports, ...reportsList])

    setLimitCompete({})
    setLimitCompete((prevLimitCompete) => ({...prevLimitCompete, ...limitCompeteDict}))

    setIsLoading(false)
  }

  const handlePageChange = async (newPage) => {
    setPage(newPage)
    setIsLoading(true)

    try {
      var rawPaginatedResponse = await ReportDashboardAPI.getReport(authContext.userName, dashboardContext.year, reportContext.selectedDepartments, reportContext.selectedInspectGroups, reportContext.selectedAbnormalItems, dashboardContext.isIntersectionSwitch, filterItems, sortItem, sortType, pageSize, newPage*pageSize)
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/login", { replace: true})
      } else {
        alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
        setIsLoading(false)
      }
    }

    let reportsList = []
    let limitCompeteDict = {}

    rawPaginatedResponse.results.forEach(report => {
      reportsList.push(report[0])
      limitCompeteDict[report[0]["id"]] = report[2][report[0]["id"]]
    })

    setReports([])
    setReports((prevReports) => [...prevReports, ...reportsList])

    setLimitCompete({})
    setLimitCompete((prevLimitCompete) => ({...prevLimitCompete, ...limitCompeteDict}))

    setIsLoading(false)
  }

  const handlePageSizeChange = async (newPageSize) => {
    setPageSize(newPageSize)
    setPage(0)
    setIsLoading(true)

    try {
      var rawPaginatedResponse = await ReportDashboardAPI.getReport(authContext.userName, dashboardContext.year, reportContext.selectedDepartments, reportContext.selectedInspectGroups, reportContext.selectedAbnormalItems, dashboardContext.isIntersectionSwitch, filterItems, sortItem, sortType, newPageSize, page*newPageSize)
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/login", { replace: true})
      } else {
        alert("載入失敗，\n請重新操作一次，或請聯絡客服人員")
        setIsLoading(false)
      }
    }

    let reportsList = []
    let limitCompeteDict = {}

    rawPaginatedResponse.results.forEach(report => {
      reportsList.push(report[0])
      limitCompeteDict[report[0]["id"]] = report[2][report[0]["id"]]
    })

    setReports([])
    setReports((prevReports) => [...prevReports, ...reportsList])

    setLimitCompete({})
    setLimitCompete((prevLimitCompete) => ({...prevLimitCompete, ...limitCompeteDict}))

    setIsLoading(false)
  }

  return (
    <div align="center">
      <Box sx={{ height: "500px", width: "100%" }}>
        <CustomizedDataGrid
          experimentalFeatures={{columnGrouping: true}}
          columns={reportContext.dataGridColumns}
          columnGroupingModel={reportContext.dataGridColumnGroup}
          rows={reports}
          getCellClassName={(params) => {
            if (params === undefined || limitCompete === undefined || limitCompete[params.id] === undefined || params.value === null) {
              return ""
            } else if (limitCompete[params.id][params.field] === 1) {
              return "abnormal-cell"
            } else {
              return ""
            }
          }}
          components={{
            Toolbar: CustomizedToolbar,
          }}
          componentsProps={{filterPanel: {linkOperators: [GridLinkOperator.And]}}}
          pagination
          page={page}
          pageSize={pageSize}
          rowCount={reportsCount}
          filterMode="server"
          sortingMode="server"
          paginationMode="server"
          rowsPerPageOptions={[25, 50, 100, reportsCount]}
          onFilterModelChange={handleFilterChange}
          onSortModelChange={handleSortChange}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          localeText={zHTWLocale}
          loading = {isLoading}
          sx={{ width: "90%" }}
          checkboxSelection
          disableSelectionOnClick
        />
      </Box>
    </div>
  )
}