import { Box } from '@mui/material'
import Logo from "./Logo"

export default function Footer(props) {
  return (
    <div align="center">
      <Box sx={{ width: "10%", marginTop: props.topMargin, marginRight: props.rightMargin ? props.rightMargin : 0}}>
        <Logo width={props.width} logoTopMargin={props.logoTopMargin} />
      </Box>
    </div>
  )
}